const roundNumber = (rating, defaultVal) => {
  return (
    (rating && Math.round(Number(parseFloat(rating).toFixed(2)) * 10) / 10) ||
    (defaultVal ?? 0)
  );
};

const roundNumberFromTwoDecimals = (rating, defaultVal) => {
  const reduceRatingToTwoDecimals = parseFloat(rating)
    .toString()
    .match(/^-?\d+(?:\.\d{0,2})?/)?.[0];
  const roundToOneDecimal = Number(reduceRatingToTwoDecimals).toFixed(1);
  return (rating && parseFloat(roundToOneDecimal)) || (defaultVal ?? 0);
};

const defaultPassOptions = [
  {
    id: 0,
    value: 'Weak',
    minDiversity: 1,
    minLength: 12,
  },
  {
    id: 1,
    value: 'Medium',
    minDiversity: 2,
    minLength: 12,
  },
  {
    id: 2,
    value: 'Strong',
    minDiversity: 2,
    minLength: 12,
  },
];

const validatePassword = (passwordComplexity) => {
  const {
    length = 0,
    contains = [],
    value = 'Weak',
  } = passwordComplexity || {};

  if (length < 12) {
    return 'Password must be atleast 12 characters';
  } else if (contains?.length < 2) {
    return 'Password must contain at least two of the following four categories (Upper case, Lower case, Digits, Special Characters).';
  } else if (value == 'Weak') {
    return 'Password strength is weak.';
  }
  return null;
};

const getCurrEnvironment = () => {
  let currEnvironment = 'unknown';
  if (window.location.host.includes('localhost')) currEnvironment = 'local';
  else if (window.location.host.includes('stag')) currEnvironment = 'staging';
  else currEnvironment = 'production';
  return currEnvironment;
};

export {
  roundNumber,
  defaultPassOptions,
  validatePassword,
  roundNumberFromTwoDecimals,
  getCurrEnvironment,
};
