const handleGetNeopirScColor = (value, type) => {
  let color = 'color-gray';
  if (type !== 'neopir') {
    if (value === 'Very Low' || value == 1) {
      color = 'csc-th--vl';
    } else if (value === 'Low' || value == 2) {
      color = 'csc-th--vl';
    } else if (value === 'Average' || value == 3) {
      color = 'csc-th--l';
    } else if (value === 'High' || value == 4) {
      color = 'csc-th--h';
    } else if (value === 'Very High' || value == 5) {
      color = 'csc-th--h';
    } else {
      color = 'color-gray';
    }
  }

  return color;
};

const handleGetGbaTypes = (type) => {
  let finalScore = '';
  if (type >= 0) {
    if (type == 0) {
      finalScore = 'P';
    } else if (type <= 33) {
      finalScore = 'P';
    } else if (type > 33 && type <= 64) {
      finalScore = 'M';
    } else if (type > 64) {
      finalScore = 'G';
    } else {
      finalScore = '';
    }
  } else {
    finalScore = '';
  }
  return finalScore;
};

const handleGetGbaColors = (type) => {
  let finalColor = '';
  if (type >= 0) {
    if (type == 0) {
      finalColor = 'csc-th--vl';
    } else if (type <= 33) {
      finalColor = 'csc-th--vl';
    } else if (type > 33 && type <= 64) {
      finalColor = 'csc-th--l';
    } else if (type > 64) {
      finalColor = 'csc-th--h';
    } else {
      finalColor = 'color-gray';
    }
  } else {
    finalColor = 'color-gray';
  }
  return finalColor;
};

const handleGetCongnitiveColors = (type, taskResult) => {
  let finalColor = '';
  if (taskResult) {
    if (type && type === 'DM') {
      finalColor = 'csc-th--vl';
    } else if (type === 'M') {
      finalColor = 'csc-th--l';
    } else if (type === 'SM') {
      finalColor = 'csc-th--h';
    } else {
      finalColor = 'color-gray';
    }
  } else {
    finalColor = 'color-gray';
  }
  return finalColor;
};

const handleGetCognitiveScores = (taskResult, data) => {
  const { wgiii, wgcta, ranra } = taskResult || {};
  const finalWgiii = wgiii && typeof wgiii !== 'object' && wgiii.toString();
  const finalWgcta = wgcta && typeof wgcta !== 'object' && wgcta.toString();
  const finalRanra =
    (ranra && typeof ranra !== 'object' && ranra.toString()) || '';
  const { wgiiiScoreInfo, wgctaScoreInfo, ranraInfo } = data || {};
  const { omsCode: wg3OmsCode } = wgiiiScoreInfo || {};
  const { omsCode: wgctaOmsCode } = wgctaScoreInfo || {};
  const { omsCode: ranraOmsCode } = ranraInfo || {};
  let wgSubStr = '';
  let wgFinalValue = '';
  if (finalWgiii) {
    if (finalWgiii?.length === 3) {
      wgFinalValue = finalWgiii;
      wgSubStr = finalWgiii?.substring(1);
    } else {
      wgFinalValue = finalWgiii;
      wgSubStr = finalWgiii?.substring(2);
    }
  } else if (finalWgcta) {
    if (finalWgcta?.length === 3) {
      wgFinalValue = finalWgcta;
      wgSubStr = finalWgcta?.substring(1);
    } else {
      wgFinalValue = finalWgcta;
      wgSubStr = finalWgcta?.substring(2);
    }
  }
  const ranraSubStr =
    finalRanra?.toString()?.length === 3
      ? finalRanra?.substring(1)
      : finalRanra?.substring(2);
  const criticalThinking = wgFinalValue?.toString()?.replace(wgSubStr, '');
  let criticalThinkingVal = null;
  if (wg3OmsCode) {
    criticalThinkingVal = wg3OmsCode;
  } else if (wgctaOmsCode) {
    criticalThinkingVal = wgctaOmsCode;
  }
  const numericalReasoning = finalRanra?.toString()?.replace(ranraSubStr, '');
  const criticalThinkingColor = handleGetCongnitiveColors(
    criticalThinkingVal,
    taskResult
  );
  const numericalReasoningColor = handleGetCongnitiveColors(
    ranraOmsCode,
    taskResult
  );
  return {
    criticalThinkingColor,
    numericalReasoningColor,
    criticalThinking: criticalThinking === '' ? undefined : criticalThinking,
    numericalReasoning:
      numericalReasoning === '' ? undefined : numericalReasoning,
  };
};

const handleGetNeopirScore = (value) => {
  let score = 'NA';
  if (value === 'Very Low') {
    score = 1;
  } else if (value === 'Low') {
    score = 2;
  } else if (value === 'Average') {
    score = 3;
  } else if (value === 'High') {
    score = 4;
  } else if (value === 'Very High') {
    score = 5;
  } else {
    score = 'NA';
  }
  return score;
};

const handleGetNeopirScoes = (taskResult) => {
  const { neopi } = taskResult || {};
  const neopirScores = {};
  if (neopi) {
    neopirScores.extraversion = handleGetNeopirScore(neopi.E);
    neopirScores.openness = handleGetNeopirScore(neopi.O);
    neopirScores.agreeableness = handleGetNeopirScore(neopi.A);
    neopirScores.conscientiousness = handleGetNeopirScore(neopi.C);
    neopirScores.neuroticism = handleGetNeopirScore(neopi.N);
    neopirScores.extraversionColor = handleGetNeopirScColor(neopi.E, 'neopir');
    neopirScores.opennessColor = handleGetNeopirScColor(neopi.O, 'neopir');
    neopirScores.agreeablenessColor = handleGetNeopirScColor(neopi.A, 'neopir');
    neopirScores.conscientiousnessColor = handleGetNeopirScColor(
      neopi.C,
      'neopir'
    );
    neopirScores.neuroticismColor = handleGetNeopirScColor(neopi.N, 'neopir');
  }
  return neopirScores;
};

const handleGetPsychologicalScores = (psychologicalReports) => {
  const {
    characterAndValues,
    intellectualQualities,
    interpersonalSkills,
    leadershipQualities,
    resilience,
  } =
    (psychologicalReports &&
      Array.isArray(psychologicalReports) &&
      psychologicalReports.length > 0 &&
      psychologicalReports[0]) ||
    {};
  const psychologicalScores = {
    characterAndValues,
    intellectualQualities,
    interpersonalSkills,
    leadershipQualities,
    resilience,
    characterAndValuesColor: handleGetNeopirScColor(characterAndValues),
    intellectualQualitiesColor: handleGetNeopirScColor(intellectualQualities),
    interpersonalSkillsColor: handleGetNeopirScColor(interpersonalSkills),
    leadershipQualitiesColor: handleGetNeopirScColor(leadershipQualities),
    resilienceColor: handleGetNeopirScColor(resilience),
  };
  return psychologicalScores;
};

const handleGetGBAfitScores = (filteredFitScores) => {
  let fitScores = {};
  if (
    filteredFitScores &&
    Array.isArray(filteredFitScores) &&
    filteredFitScores.length > 0
  ) {
    filteredFitScores.forEach((fitSc) => {
      const { score, name } = fitSc || {};
      if (name.includes('Award')) {
        fitScores.award = score;
      } else if (name.includes('Future Driven Fit Profile')) {
        fitScores.futureDriven = score;
      } else if (name.includes('Performance Fit Profile')) {
        fitScores.performanceBased = score;
      }
    });
  }
  const { award, futureDriven, performanceBased } = fitScores || {};

  if (performanceBased >= 0 && futureDriven >= 0) {
    const average = Math.round((futureDriven + performanceBased) / 2);
    fitScores.average = average;
    fitScores.averageColor = handleGetGbaColors(average);
  } else {
    fitScores.average = 'NA';
    fitScores.averageColor = handleGetGbaColors(undefined);
  }
  fitScores.awardColor = handleGetGbaColors(award);
  fitScores.performanceBasedColor = handleGetGbaColors(performanceBased);
  fitScores.futureDrivenColor = handleGetGbaColors(futureDriven);
  return fitScores;
};

export {
  handleGetNeopirScColor,
  handleGetGbaColors,
  handleGetCognitiveScores,
  handleGetNeopirScoes,
  handleGetPsychologicalScores,
  handleGetGBAfitScores,
  handleGetGbaTypes,
};
