import React from 'react';

import PublicHeader from '../../headers/PublicHeader';
import CscImage from '../../assets/images/x0pa-brown.png';
import ServiceWorkerWrapper from '../../NotificationUpdate.js';

import './Login.css';
import { Button } from 'carbon-components-react';
import SGDSMastfooter from '../../footers/SGDSMastfooter.js';

const Login = (props) => {
  const { history } = props || {};
  const { push } = history || {};
  return (
    <div>
      <PublicHeader push={push} />
      <div
        className="bx--row css-web-height w-100"
        style={{ marginTop: '-4rem' }}>
        <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 left-bg-color d-flex flex-column justify-content-center align-items-center">
          <div>
            <img style={{ width: 200 }} src={CscImage} alt="CSC Image" />
          </div>
        </div>
        <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 d-flex flex-column justify-content-center align-items-center">
          <div className="mt-2">
            <Button
              onClick={() => {
                push && push('/public/csc/login');
              }}>
              Login
            </Button>
          </div>
        </div>
      </div>
      <ServiceWorkerWrapper />
      <SGDSMastfooter />
    </div>
  );
};

export default Login;
