import React from 'react';

const SGDSMasthead = () => {
  const showMastHead = window.location.hostname
    ?.toLowerCase()
    ?.includes('.gov.sg');
  return showMastHead && <sgds-masthead></sgds-masthead>;
};

export default SGDSMasthead;
