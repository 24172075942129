import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//Api Actions
import {
  createCandidateEvalDetails,
  upsertCandidateEvaluationDetails,
  getCandidateEvaluationDetails,
  getPersonalSummary,
} from '../../actions/CandidateInfo';
import { downloadHighLevelReports } from '../../actions/HighLevelReports';
import { upsertCandidateDetailsById } from '../../actions/CandidateInfo';

import SuccErrNotification from '../../components/private/SuccErrNotification';
import NoContentBox from '../../components/common/NoContentBox';
import CandidateEvaluationRatings from '../../components/private/CandidateEvaluationRatings';
import CombinedEvaluationForm from '../../components/private/CombinedEvaluationForm';
import {
  RadioButton,
  TextArea,
  Button,
  InlineNotification,
  Accordion,
  AccordionItem,
  RadioButtonGroup,
} from 'carbon-components-react';
import moment from 'moment';
import './CandidateInfo.css';
import { reactMap } from '../../utils/reactMapUtils';
import GenericTabs from '../../components/common/GenericTabs';
import _ from 'lodash';
import { BsStars } from 'react-icons/bs';

const evalStaticData = [
  {
    evalName: 'Commitment',
    evalDesc:
      'Displays a desire to contribute to the organisation or wider community and has thought through how to do this via his/her chosen career.',
    evalId: 1,
    evalPropName: 'commitment',
  },
  {
    evalName: 'Knowledge (& Intellectual Qualities)',
    evalDesc:
      'Displays good general knowledge (world events, issues and related matters); analyse issues objectively from multiple perspectives and form sound judgement.',
    evalId: 2,
    evalPropName: 'knowledge',
  },
  {
    evalName: 'Innovativeness',
    evalDesc: 'Displays curiosity and develops innovative solutions.',
    evalId: 3,
    evalPropName: 'innovativeness',
  },
  {
    evalName: 'EQ & Empathy',
    evalDesc:
      'Displays an awareness of others’ perspectives and reactions and responds to them empathically.',
    evalId: 4,
    evalPropName: 'eqEmpathy',
  },
  {
    evalName: 'Communication Skills',
    evalDesc:
      ' Organises his/her thoughts and expresses them in a simple and clear manner.',
    evalId: 5,
    evalPropName: 'communicationSkills',
  },
  {
    evalName: 'Integrity (Accountability and Courage)',
    evalDesc:
      'Takes responsibility for his/her own actions and stands up for his/her views.',
    evalId: 6,
    evalPropName: 'integrity',
  },
  {
    evalName: 'Personal Impact',
    evalDesc:
      'Projects leadership presence and confidence - able to persuade or convince others to support an idea, agenda or direction.',
    evalId: 7,
    evalPropName: 'personalImpact',
  },
  {
    evalName: 'Performance Under Pressure',
    evalDesc:
      'Remains calm and composed in the face of difficult questions or when challenged during the interview.',
    evalId: 8,
    evalPropName: 'performanceUnderPressure',
  },
  {
    evalName: 'Determination',
    evalDesc:
      'Remains driven over a long period of time; reflected by a high degree of achievement-striving, self-motivation, persistence and self-efficacy.',
    evalId: 9,
    evalPropName: 'selfBelief',
  },
  {
    evalName: 'Self-monitoring',
    evalDesc:
      'Monitors his/her own words and behaviour and adjusts them appropriately for the situation.',
    evalId: 10,
    evalPropName: 'selfMonitoring',
  },
];

const evalStaticDataPostSelYear2023 = [
  {
    evalName: 'Inspire Confidence and Trust',
    evalDesc:
      'Projects leadership presence, confidence, and trust. Inspires others to support an idea, agenda or direction. Self-assured and knows what he/she stands for.',
    evalId: 1,
    evalPropName: 'inspireConfidenceAndTrust',
  },
  {
    evalName: 'Drive and Determination',
    evalDesc:
      'Remains driven and motivated to achieve set goals. Perseveres and effectively pushes on despite setbacks. Finds ways to overcome barriers.',
    evalId: 2,
    evalPropName: 'driveAndDetermination',
  },
  {
    evalName: 'Critical Thinker',
    evalDesc:
      'Thinks about issues broadly and deeply (has good general knowledge of world events, issues and related matters). Analyses issues from multiple perspectives and forms sound judgement.',
    evalId: 3,
    evalPropName: 'criticalThinker',
  },
  {
    evalName: 'Inquisitive',
    evalDesc:
      'Displays curiosity to learn from others, and shows desire to find out more and asks questions.',
    evalId: 4,
    evalPropName: 'inquisitive',
  },
  {
    evalName: 'Commitment',
    evalDesc:
      'Displays a desire to contribute to the organisation or wider community and has thought through how to do this via his/her chosen career.',
    evalId: 5,
    evalPropName: 'commitment',
  },
  {
    evalName: 'Collaborator',
    evalDesc:
      'Brings people along and enables people to work together. Builds cohesion and connections with empathy and care.',
    evalId: 6,
    evalPropName: 'collaborator',
  },
];

const getEvalStaticDataBySelYear = (selectionYear) => {
  if (selectionYear && selectionYear <= 2023) return evalStaticData;
  return evalStaticDataPostSelYear2023;
};

const EvaluationForm = (props) => {
  const {
    handleRadioBtnChange,
    state,
    handleChange,
    handleCommisionRadioBtnChange,
    isFormSubmitted,
    response,
    evaluationId,
    isEditForm,
    userId,
    selectionYear,
  } = props || {};
  const {
    comments,
    veryUnsuitable,
    unsuitable,
    referMinistryAgenciesRecByPSC,
    referMinistryAgencies,
    referMinistryAgenciesForEmp,
    courtesyCandidate,
    awardPsc,
    considerPsc,
    reservePsc,
    isValidComments,
    isValidCommisionVals,
    isValidAwardPscSch,
    considerShortlistPs,
    reserveShortlistPs,
    shortlistPresidentScholarship,
    recByPscToMinOrAgencies,
    recByPscToMinOrAgenciesForScholarship,
    recByPscToMinOrAgenciesForEmp,
    unsuitableForPscScholarship,
    notToReferToAny,
    unsuitableTrackUnderFriendsOfPsc,
  } = state || {};

  const evalStaticData = getEvalStaticDataBySelYear(selectionYear);

  return (
    <div>
      <div className="d-flex">
        <div className="overflow-scroll w-100">
          <CandidateEvaluationRatings
            evalStaticData={evalStaticData}
            handleRadioBtnChange={handleRadioBtnChange}
            isFormSubmitted={isFormSubmitted}
            userId={userId}
            evaluationId={evaluationId}
            isEditForm={isEditForm}
            state={state}
            response={response}
            selectionYear={selectionYear}
          />
        </div>
      </div>
      <div className="color-blue text-center table-border mt-2 p-3">
        Overall rating on the candidates suitability for a PSC Scholarship
      </div>
      <div className="mt-2">
        <div className="font-weight-bold mb-2">
          Each Commission member should rate this section based on his/her
          independent opinion of the candidate. It may differ from the final
          selection outcome.
        </div>
      </div>
      {isValidCommisionVals === true && (
        <div className="small invalid-color">
          <InlineNotification
            lowContrast
            title="Please select valid options"
            iconDescription="describes the close button"
            kind="error"
            onCloseButtonClick={() => {
              this.setState({ isValidCommisionVals: false });
            }}
          />
        </div>
      )}
      {isValidAwardPscSch === true && (
        <div className="small invalid-color">
          Please select atleast one option for president scholarship
        </div>
      )}
      <div
        className={`mt-2 mb-2 ${
          !isEditForm && isFormSubmitted && 'csc-radio-btn-disabled'
        }`}>
        <div className="mt-2">
          <div className="d-flex justify-content-left">
            <RadioButton
              name={
                !isFormSubmitted || isEditForm === true
                  ? `${userId}-radio-eval`
                  : `${evaluationId}-radio-val`
              }
              id={
                !isFormSubmitted || isEditForm === true
                  ? `awardPsc-${userId}`
                  : `${evaluationId}-radio-val`
              }
              key={
                !isFormSubmitted || isEditForm === true
                  ? `awardPsc-${userId}`
                  : `${evaluationId}-radio-val`
              }
              value={
                !isFormSubmitted || isEditForm === true
                  ? awardPsc
                  : response && response.awardPsc
              }
              checked={
                !isFormSubmitted || isEditForm === true
                  ? awardPsc
                  : response && response.awardPsc
              }
              labelText={`${
                selectionYear > 2023 ? '' : 'To '
              }Offer PSC Scholarship`}
              onChange={() => {
                handleCommisionRadioBtnChange('awardPsc', true);
              }}
              disabled={!isEditForm ? isFormSubmitted : false}
            />
          </div>
          {(awardPsc === true || (response && response.awardPsc === true)) && (
            <Fragment>
              {selectionYear > 2023 ? (
                <div className="d-flex justify-content-left mt-2 ml-5">
                  <RadioButton
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-radio-eval-award`
                        : `${evaluationId}-radio-val-award`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `shortlistPresidentScholarship-${userId}`
                        : `${evaluationId}-radio-val-award`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `shortlistPresidentScholarship-${userId}`
                        : `${evaluationId}-radio-val-award`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? shortlistPresidentScholarship
                        : response && response.shortlistPresidentScholarship
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? shortlistPresidentScholarship
                        : response && response.shortlistPresidentScholarship
                    }
                    labelText="Shortlisted for President’s Scholarship"
                    onChange={() => {
                      handleCommisionRadioBtnChange(
                        'shortlistPresidentScholarship',
                        true
                      );
                    }}
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-left mt-2 ml-5">
                  <RadioButton
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-radio-eval-award`
                        : `${evaluationId}-radio-val-award`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `considerPsc-${userId}`
                        : `${evaluationId}-radio-val-award`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `considerPsc-${userId}`
                        : `${evaluationId}-radio-val-award`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? considerPsc
                        : response && response.considerPsc
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? considerPsc
                        : response && response.considerPsc
                    }
                    labelText="To be considered for President's Scholarship"
                    onChange={() => {
                      handleCommisionRadioBtnChange('considerPsc', true);
                    }}
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </div>
              )}

              <div className="d-flex justify-content-left mt-2 ml-5">
                <RadioButton
                  name={
                    !isFormSubmitted || isEditForm === true
                      ? `${userId}-radio-eval-award`
                      : `${evaluationId}-radio-val-award`
                  }
                  id={
                    !isFormSubmitted || isEditForm === true
                      ? `reservePsc-${userId}`
                      : `${evaluationId}-radio-val-award`
                  }
                  key={
                    !isFormSubmitted || isEditForm === true
                      ? `reservePsc-${userId}`
                      : `${evaluationId}-radio-val-award`
                  }
                  value={
                    !isFormSubmitted || isEditForm === true
                      ? reservePsc
                      : response && response.reservePsc
                  }
                  checked={
                    !isFormSubmitted || isEditForm === true
                      ? reservePsc
                      : response && response.reservePsc
                  }
                  labelText="Reserve Candidate for President's Scholarship"
                  onChange={() => {
                    handleCommisionRadioBtnChange('reservePsc', true);
                  }}
                  disabled={!isEditForm ? isFormSubmitted : false}
                />
              </div>
            </Fragment>
          )}
        </div>
        {selectionYear > 2023 ? (
          <>
            <div className="d-flex justify-content-left mt-2">
              <RadioButton
                name={
                  !isFormSubmitted || isEditForm === true
                    ? `${userId}-radio-eval`
                    : `${evaluationId}-radio-val`
                }
                id={
                  !isFormSubmitted || isEditForm === true
                    ? `recByPscToMinOrAgencies-${userId}`
                    : `${evaluationId}-radio-val`
                }
                key={
                  !isFormSubmitted || isEditForm === true
                    ? `recByPscToMinOrAgencies-${userId}`
                    : `${evaluationId}-radio-val`
                }
                value={
                  !isFormSubmitted || isEditForm === true
                    ? recByPscToMinOrAgencies
                    : response && response.recByPscToMinOrAgencies
                }
                checked={
                  !isFormSubmitted || isEditForm === true
                    ? recByPscToMinOrAgencies
                    : response && response.recByPscToMinOrAgencies
                }
                labelText="Recommended by PSC to Ministries/Agencies for:"
                onChange={() => {
                  handleCommisionRadioBtnChange(
                    'recByPscToMinOrAgencies',
                    true
                  );
                }}
                disabled={!isEditForm ? isFormSubmitted : false}
              />
            </div>
            {(recByPscToMinOrAgencies === true ||
              response?.recByPscToMinOrAgencies === true) && (
              <Fragment>
                <div className="d-flex justify-content-left mt-2 ml-5">
                  <RadioButton
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-radio-eval-unsuitablePscReferToMinistry`
                        : `${evaluationId}-radio-val-unsuitablePscReferToMinistry`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `recByPscToMinOrAgenciesForScholarship-${userId}`
                        : `${evaluationId}-radio-val-unsuitablePscReferToMinistry`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `recByPscToMinOrAgenciesForScholarship-${userId}`
                        : `${evaluationId}-radio-val-unsuitablePscReferToMinistry`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? recByPscToMinOrAgenciesForScholarship
                        : response &&
                          response.recByPscToMinOrAgenciesForScholarship
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? recByPscToMinOrAgenciesForScholarship
                        : response &&
                          response.recByPscToMinOrAgenciesForScholarship
                    }
                    labelText="Scholarship"
                    onChange={() => {
                      handleCommisionRadioBtnChange(
                        'recByPscToMinOrAgenciesForScholarship',
                        true
                      );
                    }}
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </div>
                <div className="d-flex justify-content-left mt-2 ml-5">
                  <RadioButton
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-radio-eval-unsuitablePscReferToMinistry`
                        : `${evaluationId}-radio-val-unsuitablePscReferToMinistry`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `recByPscToMinOrAgenciesForEmp-${userId}`
                        : `${evaluationId}-radio-val-unsuitablePscReferToMinistry`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `recByPscToMinOrAgenciesForEmp-${userId}`
                        : `${evaluationId}-radio-val-unsuitablePscReferToMinistry`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? recByPscToMinOrAgenciesForEmp
                        : response && response.recByPscToMinOrAgenciesForEmp
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? recByPscToMinOrAgenciesForEmp
                        : response && response.recByPscToMinOrAgenciesForEmp
                    }
                    labelText="(For PSC Mid-term and PSC Masters applicants only) Employment"
                    onChange={() => {
                      handleCommisionRadioBtnChange(
                        'recByPscToMinOrAgenciesForEmp',
                        true
                      );
                    }}
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </div>
              </Fragment>
            )}
            <div className="d-flex justify-content-left mt-2">
              <RadioButton
                name={
                  !isFormSubmitted || isEditForm === true
                    ? `${userId}-radio-eval`
                    : `${evaluationId}-radio-val`
                }
                id={
                  !isFormSubmitted || isEditForm === true
                    ? `unsuitableForPscScholarship-${userId}`
                    : `${evaluationId}-radio-val`
                }
                key={
                  !isFormSubmitted || isEditForm === true
                    ? `unsuitableForPscScholarship-${userId}`
                    : `${evaluationId}-radio-val`
                }
                value={
                  !isFormSubmitted || isEditForm === true
                    ? unsuitableForPscScholarship
                    : response && response.unsuitableForPscScholarship
                }
                checked={
                  !isFormSubmitted || isEditForm === true
                    ? unsuitableForPscScholarship
                    : response && response.unsuitableForPscScholarship
                }
                labelText="Unsuitable for a PSC Scholarship"
                onChange={() => {
                  handleCommisionRadioBtnChange(
                    'unsuitableForPscScholarship',
                    true
                  );
                }}
                disabled={!isEditForm ? isFormSubmitted : false}
              />
            </div>
            <div className="d-flex justify-content-left mt-2">
              <RadioButton
                name={
                  !isFormSubmitted || isEditForm === true
                    ? `${userId}-radio-eval`
                    : `${evaluationId}-radio-val`
                }
                id={
                  !isFormSubmitted || isEditForm === true
                    ? `notToReferToAny-${userId}`
                    : `${evaluationId}-radio-val`
                }
                key={
                  !isFormSubmitted || isEditForm === true
                    ? `notToReferToAny-${userId}`
                    : `${evaluationId}-radio-val`
                }
                value={
                  !isFormSubmitted || isEditForm === true
                    ? notToReferToAny
                    : response && response.notToReferToAny
                }
                checked={
                  !isFormSubmitted || isEditForm === true
                    ? notToReferToAny
                    : response && response.notToReferToAny
                }
                labelText="Not to refer to any Ministry/Agency for scholarship (e.g. interview performance is extremely weak, courtesy candidate, attitude problem)"
                onChange={() => {
                  handleCommisionRadioBtnChange('notToReferToAny', true);
                }}
                disabled={!isEditForm ? isFormSubmitted : false}
              />
            </div>

            <div className="mt-3">
              <div className="font-weight-bold">
                (For Candidates who are not Offered) Track under “Friends of
                PSC” Watch List for future scholarship consideration
              </div>
              <div className="d-flex justify-content-left mt-2">
                <RadioButton
                  name={
                    !isFormSubmitted || isEditForm === true
                      ? `${userId}-radio-eval-yes`
                      : `${evaluationId}-radio-val-yes`
                  }
                  id={
                    !isFormSubmitted || isEditForm === true
                      ? `unsuitableTrackUnderFriendsOfPsc-${userId}-yes`
                      : `${evaluationId}-radio-val-yes`
                  }
                  key={
                    !isFormSubmitted || isEditForm === true
                      ? `unsuitableTrackUnderFriendsOfPsc-${userId}-yes`
                      : `${evaluationId}-radio-val-yes`
                  }
                  value={
                    !isFormSubmitted || isEditForm === true
                      ? unsuitableTrackUnderFriendsOfPsc
                      : response && response.unsuitableTrackUnderFriendsOfPsc
                  }
                  checked={
                    !isFormSubmitted || isEditForm === true
                      ? unsuitableTrackUnderFriendsOfPsc === true
                      : response &&
                        response.unsuitableTrackUnderFriendsOfPsc === true
                  }
                  labelText="Yes"
                  onChange={() => {
                    handleRadioBtnChange(
                      'unsuitableTrackUnderFriendsOfPsc',
                      true
                    );
                  }}
                  disabled={!isEditForm ? isFormSubmitted : false}
                />
              </div>
              <div className="d-flex justify-content-left mt-2">
                <RadioButton
                  name={
                    !isFormSubmitted || isEditForm === true
                      ? `${userId}-radio-eval-no`
                      : `${evaluationId}-radio-val-no`
                  }
                  id={
                    !isFormSubmitted || isEditForm === true
                      ? `unsuitableTrackUnderFriendsOfPsc-${userId}-no`
                      : `${evaluationId}-radio-val-no`
                  }
                  key={
                    !isFormSubmitted || isEditForm === true
                      ? `unsuitableTrackUnderFriendsOfPsc-${userId}-no`
                      : `${evaluationId}-radio-val-no`
                  }
                  value={
                    !isFormSubmitted || isEditForm === true
                      ? unsuitableTrackUnderFriendsOfPsc
                      : response && response.unsuitableTrackUnderFriendsOfPsc
                  }
                  checked={
                    !isFormSubmitted || isEditForm === true
                      ? unsuitableTrackUnderFriendsOfPsc === false
                      : response &&
                        response.unsuitableTrackUnderFriendsOfPsc === false
                  }
                  labelText="No"
                  onChange={() => {
                    handleRadioBtnChange(
                      'unsuitableTrackUnderFriendsOfPsc',
                      false
                    );
                  }}
                  disabled={!isEditForm ? isFormSubmitted : false}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {selectionYear > 2022 && (
              <>
                <div className="d-flex justify-content-left mt-2">
                  <RadioButton
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-radio-eval`
                        : `${evaluationId}-radio-val`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `referMinistryAgenciesRecByPSC-${userId}`
                        : `${evaluationId}-radio-val`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `referMinistryAgenciesRecByPSC-${userId}`
                        : `${evaluationId}-radio-val`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? referMinistryAgenciesRecByPSC
                        : response && response.referMinistryAgenciesRecByPSC
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? referMinistryAgenciesRecByPSC
                        : response && response.referMinistryAgenciesRecByPSC
                    }
                    labelText="Refer to Ministry/ Agencies (Recommended by PSC)"
                    onChange={() => {
                      handleCommisionRadioBtnChange(
                        'referMinistryAgenciesRecByPSC',
                        true
                      );
                    }}
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </div>
                <div className="d-flex justify-content-left mt-2">
                  <RadioButton
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-radio-eval`
                        : `${evaluationId}-radio-val`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `referMinistryAgenciesForEmp-${userId}`
                        : `${evaluationId}-radio-val`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `referMinistryAgenciesForEmp-${userId}`
                        : `${evaluationId}-radio-val`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? referMinistryAgenciesForEmp
                        : response && response.referMinistryAgenciesForEmp
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? referMinistryAgenciesForEmp
                        : response && response.referMinistryAgenciesForEmp
                    }
                    labelText="Refer to Ministry/ Agencies for Employment"
                    onChange={() => {
                      handleCommisionRadioBtnChange(
                        'referMinistryAgenciesForEmp',
                        true
                      );
                    }}
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </div>
              </>
            )}
            <div className="d-flex justify-content-left mt-2">
              <RadioButton
                name={
                  !isFormSubmitted || isEditForm === true
                    ? `${userId}-radio-eval`
                    : `${evaluationId}-radio-val`
                }
                id={
                  !isFormSubmitted || isEditForm === true
                    ? `referMinistryAgencies-${userId}`
                    : `${evaluationId}-radio-val`
                }
                key={
                  !isFormSubmitted || isEditForm === true
                    ? `referMinistryAgencies-${userId}`
                    : `${evaluationId}-radio-val`
                }
                value={
                  !isFormSubmitted || isEditForm === true
                    ? referMinistryAgencies
                    : response && response.referMinistryAgencies
                }
                checked={
                  !isFormSubmitted || isEditForm === true
                    ? referMinistryAgencies
                    : response && response.referMinistryAgencies
                }
                labelText="Refer to Ministry/ Agencies"
                onChange={() => {
                  handleCommisionRadioBtnChange('referMinistryAgencies', true);
                }}
                disabled={!isEditForm ? isFormSubmitted : false}
              />
            </div>
            <div className="d-flex justify-content-left mt-2">
              <RadioButton
                name={
                  !isFormSubmitted || isEditForm === true
                    ? `${userId}-radio-eval`
                    : `${evaluationId}-radio-val`
                }
                id={
                  !isFormSubmitted || isEditForm === true
                    ? `unsuitable-${userId}`
                    : `${evaluationId}-radio-val`
                }
                key={
                  !isFormSubmitted || isEditForm === true
                    ? `unsuitable-${userId}`
                    : `${evaluationId}-radio-val`
                }
                value={
                  !isFormSubmitted || isEditForm === true
                    ? unsuitable
                    : response && response.unsuitable
                }
                checked={
                  !isFormSubmitted || isEditForm === true
                    ? unsuitable
                    : response && response.unsuitable
                }
                labelText="Unsuitable"
                onChange={() => {
                  handleCommisionRadioBtnChange('unsuitable', true);
                }}
                disabled={!isEditForm ? isFormSubmitted : false}
              />
            </div>
            {selectionYear > 2022 ? (
              <div className="d-flex justify-content-left mt-2">
                <RadioButton
                  name={
                    !isFormSubmitted || isEditForm === true
                      ? `${userId}-radio-eval`
                      : `${evaluationId}-radio-val`
                  }
                  id={
                    !isFormSubmitted || isEditForm === true
                      ? `courtesyCandidate-${userId}`
                      : `${evaluationId}-radio-val`
                  }
                  key={
                    !isFormSubmitted || isEditForm === true
                      ? `courtesyCandidate-${userId}`
                      : `${evaluationId}-radio-val`
                  }
                  value={
                    !isFormSubmitted || isEditForm === true
                      ? courtesyCandidate
                      : response && response.courtesyCandidate
                  }
                  checked={
                    !isFormSubmitted || isEditForm === true
                      ? courtesyCandidate
                      : response && response.courtesyCandidate
                  }
                  labelText="Courtesy Candidate"
                  onChange={() => {
                    handleCommisionRadioBtnChange('courtesyCandidate', true);
                  }}
                  disabled={!isEditForm ? isFormSubmitted : false}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-left mt-2">
                <RadioButton
                  name={
                    !isFormSubmitted || isEditForm === true
                      ? `${userId}-radio-eval`
                      : `${evaluationId}-radio-val`
                  }
                  id={
                    !isFormSubmitted || isEditForm === true
                      ? `veryUnsuitable-${userId}`
                      : `${evaluationId}-radio-val`
                  }
                  key={
                    !isFormSubmitted || isEditForm === true
                      ? `veryUnsuitable-${userId}`
                      : `${evaluationId}-radio-val`
                  }
                  value={
                    !isFormSubmitted || isEditForm === true
                      ? veryUnsuitable
                      : response && response.veryUnsuitable
                  }
                  checked={
                    !isFormSubmitted || isEditForm === true
                      ? veryUnsuitable
                      : response && response.veryUnsuitable
                  }
                  labelText="Very Unsuitable - Courtesy Candidate"
                  onChange={() => {
                    handleCommisionRadioBtnChange('veryUnsuitable', true);
                  }}
                  disabled={!isEditForm ? isFormSubmitted : false}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="mt-2 mb-2 font-weight-bold">Comments</div>
      <div>
        <TextArea
          name={
            !isFormSubmitted || isEditForm === true
              ? 'comments'
              : `${evaluationId}-comments`
          }
          id={
            !isFormSubmitted || isEditForm === true
              ? `comments-${userId}`
              : `${evaluationId}-comments`
          }
          value={
            !isFormSubmitted || isEditForm === true
              ? comments
              : response && response.comments
          }
          labelText=""
          hideLabel
          disabled={!isEditForm ? isFormSubmitted : false}
          onChange={handleChange}
          placeholder="Please enter your comments"
          invalid={isValidComments}
          invalidText="Please enter valid comments"
        />
      </div>
    </div>
  );
};

const fixedOrder = [
  'Career / Discipline Choice',
  'Passion to Serve / Commitment / Character and Values',
  'Current Affairs / Domain Knowledge / Critical Thinking / Intellectual Qualities',
  'Personal Qualities (Strengths & Weaknesses)',
  'Any Other Areas of Interest to Probe On',
];

class CandidateEvaluationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isEditForm: false,
      openAccr: 0,
      usersList: [],
      combinedEvalForm: {},
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  renderOnMount = () => {
    this.handleGetAiInsights();
    this.handleGetEvalData();
  };

  handleGetEvalData = () => {
    const { appId, user } = this.props;
    const { userId } = user || {};
    this.props
      .getCandidateEvaluationDetails(appId)
      .then((res) => {
        if (res && !res.error) {
          const { evaluationList } = this.props || {};
          let filterEvalList = {};
          if (
            evaluationList &&
            Array.isArray(evaluationList) &&
            evaluationList.length > 0
          ) {
            filterEvalList = evaluationList.find(
              (data) => data && data.userId == userId
            );
          }

          this.setState(
            {
              ...filterEvalList,
              openAccr: userId,
              evaluationList,
            },
            () => {
              this.handleGetApplicantInfoDetails();
              this.handleCombinedEvalForm();
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((err) => console.log('Err'));
  };

  componentDidUpdate(prevProps) {
    const { applicationInfo, evaluationList } = this.props;
    const {
      psStatus0thLevel,
      psStatus1stLevel,
      pscComments,
      psStatus2ndLevel,
      psApplicantCategory,
      unsuitableTrackUnderFriendsOfPsc,
    } = applicationInfo || {};
    const {
      applicationInfo: prevPropApplInfo,
      evaluationList: prevPropsEvalList,
    } = prevProps;
    const {
      psStatus1stLevel: prevPropStatusLevel,
      pscComments: prevPropsPSCComments,
    } = prevPropApplInfo || {};
    if (
      prevPropStatusLevel !== psStatus1stLevel ||
      pscComments !== prevPropsPSCComments
    ) {
      this.setState({
        psStatus0thLevel,
        psStatus1stLevel,
        finalOutcomeAwardPsc: psStatus0thLevel
          ? ''
          : psStatus1stLevel === "President's Scholarship (identified)" ||
            psStatus1stLevel === 'President’s Scholarship (reserve)' ||
            psStatus1stLevel === 'President’s Scholarship (shortlist)' ||
            (psApplicantCategory === 'Applicants offered PSCS' &&
              ((psStatus1stLevel &&
                psStatus1stLevel.toString() &&
                psStatus1stLevel.toString().trim().length === 0) ||
                !psStatus1stLevel ||
                psStatus1stLevel === ''))
          ? 'awardPsc'
          : '',
        finalOutcomeUnsuitablePscReferToMinistry:
          psStatus0thLevel ===
            'Recommended by PSC to Ministries/Agencies for:' ||
          psStatus1stLevel === 'Scholarship' ||
          psStatus1stLevel ===
            '(For PSC Mid-term and PSC Masters applicants only) Employment'
            ? 'unsuitablePscReferToMinistry'
            : '',
        pscComments,
        psStatus2ndLevel,
        unsuitableTrackUnderFriendsOfPscFinalOutcome:
          unsuitableTrackUnderFriendsOfPsc,
      });
    }

    const { appId } =
      (prevPropsEvalList &&
        Array.isArray(prevPropsEvalList) &&
        prevPropsEvalList.length > 0 &&
        prevPropsEvalList[0]) ||
      {};
    const { appId: currAppId } =
      (evaluationList &&
        Array.isArray(evaluationList) &&
        evaluationList.length > 0 &&
        evaluationList[0]) ||
      {};
    if (currAppId != appId) {
      this.handleGetEvalData();
    }
  }

  handleGetApplicantInfoDetails = () => {
    const { applicationInfo } = this.props;
    const {
      psStatus0thLevel,
      psStatus1stLevel,
      pscComments,
      psStatus2ndLevel,
      psApplicantCategory,
      selectionYear,
      unsuitableTrackUnderFriendsOfPsc,
    } = applicationInfo || {};
    this.setState({
      psStatus0thLevel,
      psStatus1stLevel,
      finalOutcomeAwardPsc: psStatus0thLevel
        ? ''
        : psStatus1stLevel === "President's Scholarship (identified)" ||
          psStatus1stLevel === 'President’s Scholarship (reserve)' ||
          psStatus1stLevel === 'President’s Scholarship (shortlist)' ||
          (psApplicantCategory === 'Applicants offered PSCS' &&
            ((psStatus1stLevel &&
              psStatus1stLevel.toString() &&
              psStatus1stLevel.toString().trim().length === 0) ||
              !psStatus1stLevel ||
              psStatus1stLevel === ''))
        ? 'awardPsc'
        : '',
      finalOutcomeUnsuitablePscReferToMinistry:
        psStatus0thLevel === 'Recommended by PSC to Ministries/Agencies for:' ||
        psStatus1stLevel === 'Scholarship' ||
        psStatus1stLevel ===
          '(For PSC Mid-term and PSC Masters applicants only) Employment'
          ? 'unsuitablePscReferToMinistry'
          : '',
      pscComments,
      psStatus2ndLevel,
      unsuitableTrackUnderFriendsOfPscFinalOutcome:
        unsuitableTrackUnderFriendsOfPsc,
      selectionYear: selectionYear && Number(selectionYear),
    });
  };

  handleCombinedEvalFormPost2023 = () => {
    const { evaluationList } = this.state || {};
    let finalOutComeObj = {
      inspireConfidenceAndTrust: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      driveAndDetermination: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      criticalThinker: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      inquisitive: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      commitment: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      collaborator: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
    };

    let usersList = [];
    let awardPscCount = 0;
    let shortlistPresidentScholarshipCount = 0;
    let reservePscCount = 0;
    let recByPscToMinOrAgenciesCount = 0;
    let recByPscToMinOrAgenciesForScholarshipCount = 0;
    let recByPscToMinOrAgenciesForEmpCount = 0;
    let unsuitableForPscScholarshipCount = 0;
    let notToReferToAnyCount = 0;
    let unsuitableTrackUnderFriendsOfPscYesCount = 0;
    let unsuitableTrackUnderFriendsOfPscNoCount = 0;
    let awardPscArr = [];
    let shortlistPresidentScholarshipArr = [];
    let reservePscArr = [];
    let recByPscToMinOrAgenciesArr = [];
    let recByPscToMinOrAgenciesForScholarshipArr = [];
    let recByPscToMinOrAgenciesForEmpArr = [];
    let unsuitableForPscScholarshipArr = [];
    let notToReferToAnyArr = [];
    let unsuitableTrackUnderFriendsOfPscYesArr = [];
    let unsuitableTrackUnderFriendsOfPscNoArr = [];

    if (
      evaluationList &&
      Array.isArray(evaluationList) &&
      evaluationList.length > 0
    ) {
      evaluationList.forEach((res, idx) => {
        const {
          userinfo,
          inspireConfidenceAndTrust,
          driveAndDetermination,
          criticalThinker,
          inquisitive,
          commitment,
          collaborator,
          awardPsc,
          shortlistPresidentScholarship,
          reservePsc,
          recByPscToMinOrAgencies,
          recByPscToMinOrAgenciesForScholarship,
          recByPscToMinOrAgenciesForEmp,
          unsuitableForPscScholarship,
          notToReferToAny,
          unsuitableTrackUnderFriendsOfPsc,
        } = res || {};
        const { firstname, lastname } = userinfo || {};
        const fullName = `${
          firstname || lastname ? `${firstname} ${lastname}` : 'Not Available'
        }`;
        if (usersList && Array.isArray(usersList) && usersList.length > 0) {
          usersList.push(fullName);
        } else {
          usersList = [fullName];
        }

        if (awardPsc === true) {
          awardPscCount += 1;
          awardPscArr.push(idx + 1);
        }
        if (reservePsc === true) {
          reservePscCount += 1;
          reservePscArr.push(idx + 1);
        }
        if (shortlistPresidentScholarship === true) {
          shortlistPresidentScholarshipCount += 1;
          shortlistPresidentScholarshipArr.push(idx + 1);
        }
        if (recByPscToMinOrAgencies === true) {
          recByPscToMinOrAgenciesCount += 1;
          recByPscToMinOrAgenciesArr.push(idx + 1);
        }
        if (recByPscToMinOrAgenciesForScholarship === true) {
          recByPscToMinOrAgenciesForScholarshipCount += 1;
          recByPscToMinOrAgenciesForScholarshipArr.push(idx + 1);
        }

        if (recByPscToMinOrAgenciesForEmp === true) {
          recByPscToMinOrAgenciesForEmpCount += 1;
          recByPscToMinOrAgenciesForEmpArr.push(idx + 1);
        }
        if (unsuitableForPscScholarship === true) {
          unsuitableForPscScholarshipCount += 1;
          unsuitableForPscScholarshipArr.push(idx + 1);
        }
        if (notToReferToAny === true) {
          notToReferToAnyCount += 1;
          notToReferToAnyArr.push(idx + 1);
        }
        if (unsuitableTrackUnderFriendsOfPsc === true) {
          unsuitableTrackUnderFriendsOfPscYesCount += 1;
          unsuitableTrackUnderFriendsOfPscYesArr.push(idx + 1);
        }
        if (unsuitableTrackUnderFriendsOfPsc === false) {
          unsuitableTrackUnderFriendsOfPscNoCount += 1;
          unsuitableTrackUnderFriendsOfPscNoArr.push(idx + 1);
        }

        this.handleEvalFormVals(
          inspireConfidenceAndTrust,
          finalOutComeObj,
          'inspireConfidenceAndTrust',
          idx + 1
        );
        this.handleEvalFormVals(
          driveAndDetermination,
          finalOutComeObj,
          'driveAndDetermination',
          idx + 1
        );
        this.handleEvalFormVals(
          criticalThinker,
          finalOutComeObj,
          'criticalThinker',
          idx + 1
        );
        this.handleEvalFormVals(
          inquisitive,
          finalOutComeObj,
          'inquisitive',
          idx + 1
        );
        this.handleEvalFormVals(
          commitment,
          finalOutComeObj,
          'commitment',
          idx + 1
        );
        this.handleEvalFormVals(
          collaborator,
          finalOutComeObj,
          'collaborator',
          idx + 1
        );
      });
    }

    this.setState({
      combinedEvalForm: finalOutComeObj,
      usersList,
      awardPscCount,
      shortlistPresidentScholarshipCount,
      reservePscCount,
      recByPscToMinOrAgenciesCount,
      recByPscToMinOrAgenciesForScholarshipCount,
      recByPscToMinOrAgenciesForEmpCount,
      unsuitableForPscScholarshipCount,
      notToReferToAnyCount,
      unsuitableTrackUnderFriendsOfPscYesCount,
      unsuitableTrackUnderFriendsOfPscNoCount,
      awardPscArr,
      shortlistPresidentScholarshipArr,
      reservePscArr,
      recByPscToMinOrAgenciesArr,
      recByPscToMinOrAgenciesForScholarshipArr,
      recByPscToMinOrAgenciesForEmpArr,
      unsuitableForPscScholarshipArr,
      notToReferToAnyArr,
      unsuitableTrackUnderFriendsOfPscYesArr,
      unsuitableTrackUnderFriendsOfPscNoArr,
    });
  };

  handleCombinedEvaluationForm = () => {
    const { evaluationList } = this.state || {};
    let finalOutComeObj = {
      commitment: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      knowledge: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      innovativeness: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      eqEmpathy: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      communicationSkills: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      integrity: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      personalImpact: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      performanceUnderPressure: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      selfBelief: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      selfMonitoring: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
    };
    let usersList = [];
    let veryUnsuitableCount = 0;
    let unsuitableCount = 0;
    let referMinistryAgenciesCount = 0;
    let awardPscCount = 0;
    let considerPscCount = 0;
    let reservePscCount = 0;
    let considerShortlistPsCount = 0;
    let reserveShortlistPsCount = 0;
    let shortlistPresidentScholarshipCount = 0;
    let referMinistryAgenciesRecByPSCCount = 0;
    let referMinistryAgenciesForEmpCount = 0;
    let courtesyCandCount = 0;
    let veryUnsuitableArr = [];
    let unsuitableArr = [];
    let referMinistryAgenciesArr = [];
    let awardPscArr = [];
    let considerPscArr = [];
    let reservePscArr = [];
    let considerShortlistPsArr = [];
    let reserveShortlistPsArr = [];
    let shortlistPresidentScholarshipArr = [];
    let referMinistryAgenciesRecByPSCArr = [];
    let referMinistryAgenciesForEmpArr = [];
    let courtesyCandArr = [];
    if (
      evaluationList &&
      Array.isArray(evaluationList) &&
      evaluationList.length > 0
    ) {
      evaluationList.forEach((res, idx) => {
        const {
          userinfo,
          commitment,
          knowledge,
          innovativeness,
          eqEmpathy,
          communicationSkills,
          integrity,
          personalImpact,
          performanceUnderPressure,
          selfBelief,
          selfMonitoring,
          veryUnsuitable,
          unsuitable,
          referMinistryAgencies,
          awardPsc,
          considerPsc,
          reservePsc,
          considerShortlistPs,
          reserveShortlistPs,
          shortlistPresidentScholarship,
          referMinistryAgenciesRecByPSC,
          referMinistryAgenciesForEmp,
          courtesyCandidate,
        } = res || {};
        const { firstname, lastname } = userinfo || {};
        const fullName = `${
          firstname || lastname ? `${firstname} ${lastname}` : 'Not Available'
        }`;
        if (usersList && Array.isArray(usersList) && usersList.length > 0) {
          usersList.push(fullName);
        } else {
          usersList = [fullName];
        }
        if (veryUnsuitable === true) {
          veryUnsuitableCount += 1;
          veryUnsuitableArr.push(idx + 1);
        }
        if (unsuitable === true) {
          unsuitableCount += 1;
          unsuitableArr.push(idx + 1);
        }
        if (referMinistryAgencies === true) {
          referMinistryAgenciesCount += 1;
          referMinistryAgenciesArr.push(idx + 1);
        }
        if (awardPsc === true) {
          awardPscCount += 1;
          awardPscArr.push(idx + 1);
        }
        if (considerPsc === true) {
          considerPscCount += 1;
          considerPscArr.push(idx + 1);
        }
        if (reservePsc === true) {
          reservePscCount += 1;
          reservePscArr.push(idx + 1);
        }
        if (considerShortlistPs === true) {
          considerShortlistPsCount += 1;
          considerShortlistPsArr.push(idx + 1);
        }
        if (reserveShortlistPs === true) {
          reserveShortlistPsCount += 1;
          reserveShortlistPsArr.push(idx + 1);
        }
        if (shortlistPresidentScholarship === true) {
          shortlistPresidentScholarshipCount += 1;
          shortlistPresidentScholarshipArr.push(idx + 1);
        }
        if (referMinistryAgenciesRecByPSC === true) {
          referMinistryAgenciesRecByPSCCount += 1;
          referMinistryAgenciesRecByPSCArr.push(idx + 1);
        }
        if (referMinistryAgenciesForEmp === true) {
          referMinistryAgenciesForEmpCount += 1;
          referMinistryAgenciesForEmpArr.push(idx + 1);
        }
        if (courtesyCandidate === true) {
          courtesyCandCount += 1;
          courtesyCandArr.push(idx + 1);
        }

        this.handleEvalFormVals(
          commitment,
          finalOutComeObj,
          'commitment',
          idx + 1
        );
        this.handleEvalFormVals(
          knowledge,
          finalOutComeObj,
          'knowledge',
          idx + 1
        );
        this.handleEvalFormVals(
          innovativeness,
          finalOutComeObj,
          'innovativeness',
          idx + 1
        );
        this.handleEvalFormVals(
          eqEmpathy,
          finalOutComeObj,
          'eqEmpathy',
          idx + 1
        );
        this.handleEvalFormVals(
          communicationSkills,
          finalOutComeObj,
          'communicationSkills',
          idx + 1
        );
        this.handleEvalFormVals(
          integrity,
          finalOutComeObj,
          'integrity',
          idx + 1
        );
        this.handleEvalFormVals(
          personalImpact,
          finalOutComeObj,
          'personalImpact',
          idx + 1
        );
        this.handleEvalFormVals(
          performanceUnderPressure,
          finalOutComeObj,
          'performanceUnderPressure',
          idx + 1
        );
        this.handleEvalFormVals(
          selfBelief,
          finalOutComeObj,
          'selfBelief',
          idx + 1
        );
        this.handleEvalFormVals(
          selfMonitoring,
          finalOutComeObj,
          'selfMonitoring',
          idx + 1
        );
      });
    }
    this.setState({
      combinedEvalForm: finalOutComeObj,
      usersList,
      veryUnsuitableCount,
      unsuitableCount,
      referMinistryAgenciesCount,
      awardPscCount,
      considerPscCount,
      reservePscCount,
      veryUnsuitableArr,
      unsuitableArr,
      referMinistryAgenciesArr,
      awardPscArr,
      considerPscArr,
      reservePscArr,
      shortlistPresidentScholarshipCount,
      shortlistPresidentScholarshipArr,
      reserveShortlistPsCount,
      reserveShortlistPsArr,
      considerShortlistPsCount,
      considerShortlistPsArr,
      referMinistryAgenciesRecByPSCCount,
      referMinistryAgenciesRecByPSCArr,
      referMinistryAgenciesForEmpCount,
      referMinistryAgenciesForEmpArr,
      courtesyCandCount,
      courtesyCandArr,
    });
  };

  handleCombinedEvalForm = () => {
    const { applicationInfo } = this.props;
    const { selectionYear } = applicationInfo || {};
    const finalSelYear = selectionYear && Number(selectionYear);

    if (finalSelYear > 2023) this.handleCombinedEvalFormPost2023();
    else this.handleCombinedEvaluationForm();
  };

  handleEvalFormVals = (value, finalOutComeObj, type, arrVal) => {
    if (value === 'moderate') {
      finalOutComeObj[type].moderate.push(arrVal);
    } else if (value === 'weak') {
      finalOutComeObj[type].weak.push(arrVal);
    } else if (value === 'veryWeak') {
      finalOutComeObj[type].veryWeak.push(arrVal);
    } else if (value === 'strong') {
      finalOutComeObj[type].strong.push(arrVal);
    } else if (value === 'veryStrong') {
      finalOutComeObj[type].veryStrong.push(arrVal);
    } else if (value === 'none') {
      finalOutComeObj[type].none.push(arrVal);
    }
    return finalOutComeObj;
  };

  handleEditForm = (evaluationId) => {
    this.setState({
      isEditForm: true,
      evaluationId,
    });
  };

  handleGetEvalAPi = () => {
    const { appId } = this.props;
    this.props
      .getCandidateEvaluationDetails(appId)
      .then((res) => {
        if (res && !res.error) {
          const { evaluationList } = this.props;
          this.setState(
            {
              evaluationList,
            },
            () => {
              this.handleCombinedEvalForm();
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleRadioBtnChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCommisionRadioBtnChange = (name, value) => {
    const updateState = {
      veryUnsuitable: false,
      unsuitable: false,
      referMinistryAgencies: false,
      awardPsc: false,
      considerPsc: false,
      reservePsc: false,
      considerShortlistPs: false,
      reserveShortlistPs: false,
      shortlistPresidentScholarship: false,
      referMinistryAgenciesRecByPSC: false,
      referMinistryAgenciesForEmp: false,
      courtesyCandidate: false,
      recByPscToMinOrAgencies: false,
      recByPscToMinOrAgenciesForScholarship: false,
      recByPscToMinOrAgenciesForEmp: false,
      unsuitableForPscScholarship: false,
      notToReferToAny: false,
    };

    if (name === 'veryUnsuitable') updateState.veryUnsuitable = true;
    else if (name === 'unsuitable') updateState.unsuitable = true;
    else if (name === 'referMinistryAgencies')
      updateState.referMinistryAgencies = true;
    else if (name === 'referMinistryAgenciesRecByPSC')
      updateState.referMinistryAgenciesRecByPSC = true;
    else if (name === 'referMinistryAgenciesForEmp')
      updateState.referMinistryAgenciesForEmp = true;
    else if (name === 'courtesyCandidate') updateState.courtesyCandidate = true;
    else if (name === 'awardPsc') updateState.awardPsc = true;
    else if (name === 'considerPsc') {
      updateState.considerPsc = true;
      updateState.awardPsc = true;
    } else if (name === 'reservePsc') {
      updateState.reservePsc = true;
      updateState.awardPsc = true;
    } else if (name === 'considerShortlistPs') {
      updateState.considerShortlistPs = true;
      updateState.considerPsc = true;
      updateState.awardPsc = true;
    } else if (name === 'reserveShortlistPs') {
      updateState.reserveShortlistPs = true;
      updateState.awardPsc = true;
      updateState.reservePsc = true;
    } else if (name === 'shortlistPresidentScholarship') {
      updateState.shortlistPresidentScholarship = true;
      updateState.awardPsc = true;
    } else if (name === 'recByPscToMinOrAgencies')
      updateState.recByPscToMinOrAgencies = true;
    else if (name === 'recByPscToMinOrAgenciesForScholarship') {
      updateState.recByPscToMinOrAgenciesForScholarship = true;
      updateState.recByPscToMinOrAgencies = true;
    } else if (name === 'recByPscToMinOrAgenciesForEmp') {
      updateState.recByPscToMinOrAgenciesForEmp = true;
      updateState.recByPscToMinOrAgencies = true;
    } else if (name === 'unsuitableForPscScholarship')
      updateState.unsuitableForPscScholarship = true;
    else if (name === 'notToReferToAny') updateState.notToReferToAny = true;

    this.setState({
      ...updateState,
    });
  };

  handlesubmitEvalFormPost2023 = () => {
    const {
      commitment,
      inspireConfidenceAndTrust,
      driveAndDetermination,
      criticalThinker,
      inquisitive,
      collaborator,
      awardPsc,
      shortlistPresidentScholarship,
      reservePsc,
      recByPscToMinOrAgencies,
      recByPscToMinOrAgenciesForScholarship,
      recByPscToMinOrAgenciesForEmp,
      unsuitableForPscScholarship,
      notToReferToAny,
      unsuitableTrackUnderFriendsOfPsc,
      evaluationId,
      comments,
    } = this.state;
    let isValidEvalTraits = false;
    let isValidCommisionVals = false;
    let isValidAwardPscSch = false;
    if (
      !inspireConfidenceAndTrust ||
      !driveAndDetermination ||
      !criticalThinker ||
      !inquisitive ||
      !commitment ||
      !collaborator
    ) {
      isValidEvalTraits = true;
      this.handleRedirect &&
        this.handleRedirect.current &&
        this.handleRedirect.current.focus &&
        this.handleRedirect.current.focus();
    } else {
      isValidEvalTraits = false;
    }

    isValidCommisionVals =
      !awardPsc &&
      !recByPscToMinOrAgencies &&
      !unsuitableForPscScholarship &&
      !notToReferToAny;

    this.setState(
      {
        isValidEvalTraits,
        isValidCommisionVals,
        isValidAwardPscSch,
      },
      () => {
        const {
          isValidEvalTraits: stateValidTraits,
          isValidComments: stateValidComments,
          isValidCommisionVals: stateValidCommVals,
          isValidAwardPscSch: stateValidAwardPsc,
        } = this.state;
        if (
          !stateValidTraits &&
          !stateValidComments &&
          !stateValidCommVals &&
          !stateValidAwardPsc
        ) {
          const { appId } = this.props || {};
          const data = {
            appId,
            commitment,
            inspireConfidenceAndTrust,
            driveAndDetermination,
            criticalThinker,
            inquisitive,
            collaborator,
            awardPsc,
            shortlistPresidentScholarship,
            reservePsc,
            recByPscToMinOrAgencies,
            recByPscToMinOrAgenciesForScholarship,
            recByPscToMinOrAgenciesForEmp,
            unsuitableForPscScholarship,
            notToReferToAny,
            unsuitableTrackUnderFriendsOfPsc,
            comments,
          };
          if (!evaluationId) {
            this.props
              .createCandidateEvalDetails(data)
              .then((res) => {
                this.handleApiRes(res);
              })
              .catch((e) => {
                console.log('Error', e);
                this.setState({
                  errorNotif: true,
                });
              });
          } else {
            this.props
              .upsertCandidateEvaluationDetails(data, evaluationId)
              .then((res) => {
                this.handleApiRes(res);
              })
              .catch((e) => {
                console.log('Error', e);
                this.setState({
                  errorNotif: true,
                });
              });
          }
        }
      }
    );
  };

  handleSubmitEvalForm = () => {
    const {
      commitment,
      knowledge,
      innovativeness,
      eqEmpathy,
      communicationSkills,
      integrity,
      personalImpact,
      performanceUnderPressure,
      selfBelief,
      selfMonitoring,
      comments,
      veryUnsuitable,
      unsuitable,
      referMinistryAgencies,
      awardPsc,
      considerPsc,
      reservePsc,
      evaluationId,
      considerShortlistPs,
      reserveShortlistPs,
      shortlistPresidentScholarship,
      referMinistryAgenciesRecByPSC,
      referMinistryAgenciesForEmp,
      courtesyCandidate,
      selectionYear,
    } = this.state;
    let isValidEvalTraits = false;
    let isValidCommisionVals = false;
    let isValidAwardPscSch = false;
    if (
      !commitment ||
      !knowledge ||
      !innovativeness ||
      !eqEmpathy ||
      !communicationSkills ||
      !integrity ||
      !personalImpact ||
      !performanceUnderPressure ||
      !selfBelief ||
      !selfMonitoring
    ) {
      isValidEvalTraits = true;
      this.handleRedirect &&
        this.handleRedirect.current &&
        this.handleRedirect.current.focus &&
        this.handleRedirect.current.focus();
    } else {
      isValidEvalTraits = false;
    }

    let isInputValidBySelYear = false;
    if (selectionYear > 2022) {
      isInputValidBySelYear =
        !awardPsc &&
        !referMinistryAgenciesRecByPSC &&
        !referMinistryAgenciesForEmp &&
        !referMinistryAgencies &&
        !unsuitable &&
        !courtesyCandidate;
    } else {
      isInputValidBySelYear =
        !veryUnsuitable && !unsuitable && !referMinistryAgencies && !awardPsc;
    }

    isValidCommisionVals = !!isInputValidBySelYear;

    // if (awardPsc === true && !considerPsc && !reservePsc) {
    //   isValidAwardPscSch = true;
    // } else {
    //   isValidAwardPscSch = false;
    // }
    this.setState(
      {
        isValidEvalTraits,
        // isValidComments: comments ? false : true,
        isValidCommisionVals,
        isValidAwardPscSch,
      },
      () => {
        const {
          isValidEvalTraits: stateValidTraits,
          isValidComments: stateValidComments,
          isValidCommisionVals: stateValidCommVals,
          isValidAwardPscSch: stateValidAwardPsc,
        } = this.state;
        if (
          !stateValidTraits &&
          !stateValidComments &&
          !stateValidCommVals &&
          !stateValidAwardPsc
        ) {
          const { appId } = this.props || {};
          const data = {
            appId,
            commitment,
            knowledge,
            innovativeness,
            eqEmpathy,
            communicationSkills,
            integrity,
            personalImpact,
            performanceUnderPressure,
            selfBelief,
            selfMonitoring,
            comments,
            veryUnsuitable,
            unsuitable,
            referMinistryAgencies,
            awardPsc,
            considerPsc,
            reservePsc,
            considerShortlistPs,
            reserveShortlistPs,
            shortlistPresidentScholarship,
            referMinistryAgenciesRecByPSC,
            referMinistryAgenciesForEmp,
            courtesyCandidate,
          };
          if (!evaluationId) {
            this.props
              .createCandidateEvalDetails(data)
              .then((res) => {
                this.handleApiRes(res);
              })
              .catch((e) => {
                console.log('Error', e);
                this.setState({
                  errorNotif: true,
                });
              });
          } else {
            this.props
              .upsertCandidateEvaluationDetails(data, evaluationId)
              .then((res) => {
                this.handleApiRes(res);
              })
              .catch((e) => {
                console.log('Error', e);
                this.setState({
                  errorNotif: true,
                });
              });
          }
        }
      }
    );
  };

  handleApiRes = (res) => {
    if (res && !res.error) {
      this.setState({
        successNotif: true,
        notifMsg: 'Your changes have been updated successfully',
        errorNotif: false,
        isEditForm: false,
      });
      this.handleGetEvalAPi();
    } else {
      const { error } = res || {};
      const { message } = error || {};
      this.setState(
        {
          errorNotif: true,
          isEditForm: false,
          errMsg:
            message ||
            'An Error has ocurred while processing your request, Please try again later',
        },
        () => {
          this.handleRedirect &&
            this.handleRedirect.current &&
            this.handleRedirect.current.focus &&
            this.handleRedirect.current.focus();
        }
      );
    }
  };

  handleAccrClick = (userId) => {
    this.setState({
      openAccr: userId,
    });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleFinalOutcomeSectionChange = (value, name) => {
    const { applicationInfo } = this.props;
    const { selectionYear } = applicationInfo || {};
    this.setState(
      {
        [name]: value,
        psStatus0thLevel: '',
        psStatus2ndLevel:
          name === 'psStatus1stLevel' &&
          value === 'President’s Scholarship (reserve)' &&
          selectionYear > 2023
            ? `Shortlisted President's Scholarship (reserve)`
            : name === 'psStatus2ndLevel'
            ? value
            : '',
      },
      () => {
        const { psStatus0thLevel, psStatus1stLevel, psStatus2ndLevel } =
          this.state;
        this.setState({
          finalOutcomeAwardPsc:
            psStatus1stLevel === "President's Scholarship (identified)" ||
            psStatus1stLevel === 'President’s Scholarship (reserve)' ||
            psStatus1stLevel === 'President’s Scholarship (shortlist)'
              ? 'awardPsc'
              : '',
          psStatus2ndLevel:
            psStatus1stLevel === 'President’s Scholarship (reserve)' ||
            psStatus1stLevel === "President's Scholarship (identified)"
              ? psStatus2ndLevel
              : '',
          finalOutcomeUnsuitablePscReferToMinistry:
            psStatus1stLevel === 'Scholarship' ||
            psStatus1stLevel ===
              '(For PSC Mid-term and PSC Masters applicants only) Employment'
              ? 'unsuitablePscReferToMinistry'
              : '',
        });
      }
    );
  };

  handleChangeAwardSection = (value, name) => {
    const updateState = {
      [name]: value,
      psStatus1stLevel: '',
    };
    if (name === 'finalOutcomeAwardPsc')
      updateState.finalOutcomeUnsuitablePscReferToMinistry = '';
    else if (name === 'finalOutcomeUnsuitablePscReferToMinistry')
      updateState.finalOutcomeAwardPsc = '';
    this.setState({ ...updateState });
  };

  handleSubmitFinalOutcome = () => {
    const {
      psStatus1stLevel,
      pscComments,
      finalOutcomeAwardPsc,
      psStatus2ndLevel,
      unsuitableTrackUnderFriendsOfPscFinalOutcome,
      finalOutcomeUnsuitablePscReferToMinistry,
    } = this.state;

    const { appId } = this.props;
    this.setState(
      {
        ispsStatus1stLevelvalid:
          psStatus1stLevel ||
          finalOutcomeAwardPsc ||
          finalOutcomeUnsuitablePscReferToMinistry
            ? false
            : true,
      },
      () => {
        const { ispsStatus1stLevelvalid } = this.state;
        if (!ispsStatus1stLevelvalid) {
          const data = {
            psStatus0thLevel:
              !psStatus1stLevel &&
              finalOutcomeUnsuitablePscReferToMinistry ===
                'unsuitablePscReferToMinistry'
                ? `Recommended by PSC to Ministries/Agencies for:`
                : null,
            psStatus1stLevel,
            appId,
            psApplicantCategory: 'Applicants offered PSCS',
            pscComments,
            psStatus2ndLevel:
              psStatus1stLevel === 'President’s Scholarship (shortlist)'
                ? 'Shortlisted for President’s Scholarship'
                : psStatus2ndLevel,
            unsuitableTrackUnderFriendsOfPsc:
              unsuitableTrackUnderFriendsOfPscFinalOutcome,
          };
          this.props
            .upsertCandidateDetailsById(data)
            .then((res) => {
              if (res && !res.error) {
                if (
                  (psStatus2ndLevel &&
                    psStatus2ndLevel.toString() &&
                    psStatus2ndLevel.toString().length > 0) ||
                  psStatus1stLevel === 'Shortlisted for President’s Scholarship'
                ) {
                  this.props.handleGetCandidateInfoAPI();
                  this.handleGetApplicantInfoDetails();
                } else {
                  this.props.handleUnAssignPresidentsScholarship();
                }
                this.handleRedirect &&
                  this.handleRedirect.current &&
                  this.handleRedirect.current.focus &&
                  this.handleRedirect.current.focus();
                this.setState(
                  {
                    successNotif: true,
                    notifMsg: 'Your details have been updated successfully',
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        successNotif: false,
                        notifMsg: '',
                      });
                    }, 5000);
                  }
                );
              } else {
                this.handleRedirect &&
                  this.handleRedirect.current &&
                  this.handleRedirect.current.focus &&
                  this.handleRedirect.current.focus();
                this.setState({
                  errorNotif: true,
                });
              }
            })
            .catch((e) => console.log('Error', e));
        }
      }
    );
  };

  handleExportCandidateEvaluationForm = () => {
    const { applicationInfo } = this.props || {};
    const { evaluationList } = this.state;
    const {
      combinedEvalForm,
      usersList,
      veryUnsuitableCount,
      unsuitableCount,
      referMinistryAgenciesCount,
      awardPscCount,
      considerPscCount,
      reservePscCount,
      veryUnsuitableArr,
      unsuitableArr,
      referMinistryAgenciesArr,
      awardPscArr,
      considerPscArr,
      reservePscArr,
      pscComments,
      psStatus0thLevel,
      psStatus1stLevel,
      psStatus2ndLevel,
      finalOutcomeAwardPsc,
      shortlistPresidentScholarshipCount,
      shortlistPresidentScholarshipArr,
      reserveShortlistPsCount,
      reserveShortlistPsArr,
      considerShortlistPsCount,
      considerShortlistPsArr,
      referMinistryAgenciesRecByPSCArr,
      referMinistryAgenciesRecByPSCCount,
      referMinistryAgenciesForEmpArr,
      referMinistryAgenciesForEmpCount,
      courtesyCandArr,
      courtesyCandCount,
      recByPscToMinOrAgenciesCount,
      recByPscToMinOrAgenciesForScholarshipCount,
      recByPscToMinOrAgenciesForEmpCount,
      unsuitableForPscScholarshipCount,
      notToReferToAnyCount,
      unsuitableTrackUnderFriendsOfPscYesCount,
      unsuitableTrackUnderFriendsOfPscNoCount,
      recByPscToMinOrAgenciesArr,
      recByPscToMinOrAgenciesForScholarshipArr,
      recByPscToMinOrAgenciesForEmpArr,
      unsuitableForPscScholarshipArr,
      notToReferToAnyArr,
      unsuitableTrackUnderFriendsOfPscYesArr,
      unsuitableTrackUnderFriendsOfPscNoArr,
      finalOutcomeUnsuitablePscReferToMinistry,
    } = this.state;
    const { profile, selectionYear } = applicationInfo || {};
    const { nric, fullName } = profile || {};
    this.props
      .downloadHighLevelReports('candidate-evaluation', {
        selectionYear,
        evaluationList,
        nric,
        fullName,
        combinedEvalForm,
        usersList,
        veryUnsuitableCount,
        unsuitableCount,
        referMinistryAgenciesCount,
        awardPscCount,
        considerPscCount,
        reservePscCount,
        veryUnsuitableArr,
        unsuitableArr,
        referMinistryAgenciesArr,
        awardPscArr,
        considerPscArr,
        reservePscArr,
        pscComments,
        psStatus1stLevel,
        finalOutcomeAwardPsc,
        shortlistPresidentScholarshipCount,
        shortlistPresidentScholarshipArr,
        reserveShortlistPsCount,
        reserveShortlistPsArr,
        considerShortlistPsCount,
        considerShortlistPsArr,
        psStatus2ndLevel,
        evalStaticData: getEvalStaticDataBySelYear(
          selectionYear && Number(selectionYear)
        ),
        referMinistryAgenciesRecByPSCArr,
        referMinistryAgenciesRecByPSCCount,
        referMinistryAgenciesForEmpArr,
        referMinistryAgenciesForEmpCount,
        courtesyCandArr,
        courtesyCandCount,
        recByPscToMinOrAgenciesCount,
        recByPscToMinOrAgenciesForScholarshipCount,
        recByPscToMinOrAgenciesForEmpCount,
        unsuitableForPscScholarshipCount,
        notToReferToAnyCount,
        recByPscToMinOrAgenciesArr,
        recByPscToMinOrAgenciesForScholarshipArr,
        recByPscToMinOrAgenciesForEmpArr,
        unsuitableForPscScholarshipArr,
        notToReferToAnyArr,
        finalOutcomeUnsuitablePscReferToMinistry,
        unsuitableTrackUnderFriendsOfPscYesCount,
        unsuitableTrackUnderFriendsOfPscNoCount,
        unsuitableTrackUnderFriendsOfPscYesArr,
        unsuitableTrackUnderFriendsOfPscNoArr,
      })
      .then((pdf) => {
        if (pdf && !pdf.error) {
          const pdfDoc = URL.createObjectURL(pdf);
          const a = document.createElement('a');
          a.href = pdfDoc;
          a.download = `Candidate-Evaluation-${moment().format('MM/DD/YYYY')}`;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(pdfDoc);
          }, 1000);
        }
      })
      .catch((e) => {
        console.log('Error', e);
      });
  };

  onToggleClick = (val) => {
    this.setState({
      unsuitableTrackUnderFriendsOfPscFinalOutcome: val,
    });
  };

  handleGetAiInsights = () => {
    const { appId } = this.props;
    if (appId) this.props.getPersonalSummary(appId);
  };

  render() {
    const { user, personalSummary, match } = this.props;
    const { params } = match || {};
    const { jobId } = params || {};
    const { evaluationList } = this.state;
    const { firstname, lastname, userId, roleId } = user || {};
    const {
      isValidEvalTraits,
      successNotif,
      errorNotif,
      notifMsg,
      isEditForm,
      openAccr,
      combinedEvalForm,
      usersList,
      veryUnsuitableCount,
      unsuitableCount,
      referMinistryAgenciesCount,
      awardPscCount,
      considerPscCount,
      reservePscCount,
      veryUnsuitableArr,
      unsuitableArr,
      referMinistryAgenciesArr,
      referMinistryAgenciesRecByPSCArr,
      referMinistryAgenciesRecByPSCCount,
      referMinistryAgenciesForEmpArr,
      referMinistryAgenciesForEmpCount,
      courtesyCandArr,
      courtesyCandCount,
      awardPscArr,
      shortlistPresidentScholarshipCount,
      shortlistPresidentScholarshipArr,
      considerPscArr,
      reservePscArr,
      psStatus1stLevel,
      finalOutcomeAwardPsc,
      ispsStatus1stLevelvalid,
      pscComments,
      errMsg,
      // shortlistPresidentScholarshipCount,
      // shortlistPresidentScholarshipArr,
      // reserveShortlistPsCount,
      // reserveShortlistPsArr,
      // considerShortlistPsCount,
      // considerShortlistPsArr,
      psStatus2ndLevel,
      unsuitableTrackUnderFriendsOfPsc,
      recByPscToMinOrAgenciesCount,
      recByPscToMinOrAgenciesForScholarshipCount,
      recByPscToMinOrAgenciesForEmpCount,
      unsuitableForPscScholarshipCount,
      notToReferToAnyCount,
      unsuitableTrackUnderFriendsOfPscYesCount,
      unsuitableTrackUnderFriendsOfPscNoCount,
      recByPscToMinOrAgenciesArr,
      recByPscToMinOrAgenciesForScholarshipArr,
      recByPscToMinOrAgenciesForEmpArr,
      unsuitableForPscScholarshipArr,
      notToReferToAnyArr,
      unsuitableTrackUnderFriendsOfPscYesArr,
      unsuitableTrackUnderFriendsOfPscNoArr,
      finalOutcomeUnsuitablePscReferToMinistry,
      unsuitableTrackUnderFriendsOfPscFinalOutcome,
    } = this.state;

    const selectionYear = this.state.selectionYear ?? jobId;

    const { interview_guide } = personalSummary || {};

    let psStatusArr = [];
    if (selectionYear > 2023) {
      psStatusArr.push(
        'Recommended by PSC to Ministries/Agencies for:',
        'Unsuitable for a PSC Scholarship',
        'Not to refer to any Ministry/Agency for scholarship (e.g. interview performance is extremely weak, courtesy candidate, attitude problem)'
      );
    } else {
      if (selectionYear > 2022) {
        psStatusArr.push(
          'Refer to Ministry/ Agencies (Recommended by PSC)',
          'Refer to Ministry/ Agencies for Employment',
          'Courtesy Candidate'
        );
      } else {
        psStatusArr.push('Very Unsuitable - Courtesy Candidate');
      }

      psStatusArr.push(
        'Unsuitable',
        'Refer to Ministry/ Agencies',
        "President's Scholarship (identified)",
        'President’s Scholarship (reserve)'
      );
    }

    const finalOutcomeOfSecretariat =
      finalOutcomeAwardPsc || finalOutcomeUnsuitablePscReferToMinistry;

    return (
      <div>
        {selectionYear == 2024 && (
          <div className="">
            <div className="d-flex justify-content-between">
              <div class="mt-2 h6 font-weight-bold">
                <div className="d-flex">
                  <div>(BETA) AI Interview Guide</div>
                  <div className="ml-2">
                    <BsStars
                      size={17}
                      style={{ color: 'rgb(255 191 0)', marginRight: '2px' }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Accordion className="" align="start">
              <AccordionItem
                className="gba-accor-content mt-2"
                title={
                  <div className="bx--row align-items-center">
                    <div className="h6 font-weight-bold mb-1 bx--col-lg-12 bx--col-md-12">
                      Interview Guide
                    </div>
                  </div>
                }>
                <div style={{ lineHeight: 1.3 }}>
                  {!_.isEmpty(interview_guide) ? (
                    <>
                      {fixedOrder
                        ?.filter((key) => interview_guide?.[key])
                        ?.map((heading, idx) => (
                          <div key={heading} className="mb-4">
                            <h3 className="font-weight-bold mb-2">{heading}</h3>
                            <div className="mb-2 d-flex">
                              <div className="bx--type-epsilon">Summary</div> :{' '}
                              {interview_guide[heading]?.Summary}
                            </div>
                            <div className="bx--type-epsilon mt-2">
                              Questions :
                            </div>
                            <ul className="list-disc">
                              {Object.entries(
                                interview_guide[heading].Questions
                              ).map(([key, question], idx) => (
                                <li
                                  key={key}
                                  className="mb-1 d-flex align-items-center">
                                  <div className="bx--type-epsilon">{`Q${
                                    idx + 1
                                  }`}</div>{' '}
                                  : {question}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                    </>
                  ) : (
                    <div>
                      <NoContentBox
                        hideTile
                        message="Currently, there are no insights available."
                      />
                    </div>
                  )}
                </div>
              </AccordionItem>
            </Accordion>
            <hr />
          </div>
        )}

        <div>
          <div className="d-flex justify-content-between">
            <div class="mt-2 h6 font-weight-bold">
              Candidate Evaluation Form
            </div>
          </div>
          {evaluationList &&
            Array.isArray(evaluationList) &&
            evaluationList.length > 0 &&
            (roleId === 6 || roleId === 2) && (
              <div className="text-right mt-2 mb-2">
                <Button onClick={this.handleExportCandidateEvaluationForm}>
                  Export
                </Button>
              </div>
            )}

          <input
            style={{ height: 0, opacity: 0 }}
            type="text"
            ref={this.handleRedirect}
          />
          {isValidEvalTraits === true && (
            <InlineNotification
              lowContrast
              title="Please select valid rating for each trait"
              iconDescription="describes the close button"
              kind="error"
              onCloseButtonClick={() => {
                this.setState({ isValidEvalTraits: false });
              }}
            />
          )}
          <SuccErrNotification
            successNotif={successNotif}
            errorNotif={errorNotif}
            notifMsg={notifMsg}
            errMsg={errMsg}
            handleCloseNotifBtn={this.handleCloseNotifBtn}
          />
          {((evaluationList &&
            Array.isArray(evaluationList) &&
            evaluationList.length <= 0) ||
            !evaluationList) &&
          roleId === 7 &&
          !psStatusArr.includes(psStatus1stLevel) &&
          !finalOutcomeOfSecretariat ? (
            <Accordion>
              <AccordionItem
                open
                className="content-padding"
                title={
                  <h5 className="font-weight-bold text-black text-capitalize">
                    {firstname || lastname
                      ? `${firstname} ${lastname}`
                      : 'Not Available'}
                  </h5>
                }>
                <EvaluationForm
                  handleRadioBtnChange={this.handleRadioBtnChange}
                  state={this.state}
                  handleChange={this.handleChange}
                  handleCommisionRadioBtnChange={
                    this.handleCommisionRadioBtnChange
                  }
                  isFormSubmitted={false}
                  isEditForm={isEditForm}
                  userId={userId}
                  selectionYear={selectionYear}
                />
                <div className="mt-2 text-center">
                  <Button
                    kind="primary"
                    size="small"
                    onClick={() =>
                      selectionYear > 2023
                        ? this.handlesubmitEvalFormPost2023()
                        : this.handleSubmitEvalForm()
                    }>
                    Submit
                  </Button>
                </div>
              </AccordionItem>
            </Accordion>
          ) : evaluationList &&
            Array.isArray(evaluationList) &&
            evaluationList.length > 0 ? (
            <div>
              <Accordion>
                {evaluationList.map((res) => {
                  const {
                    userinfo,
                    isFormSubmitted,
                    evaluationId,
                    userId: propUserId,
                  } = res || {};
                  const { firstname: propFirstName, lastname: propLastName } =
                    userinfo || {};
                  return (
                    <AccordionItem
                      className="content-padding"
                      open={openAccr == propUserId}
                      onClick={() => this.handleAccrClick(propUserId)}
                      title={
                        <h5 className="font-weight-bold text-black text-capitalize">
                          {propFirstName || propLastName
                            ? `${propFirstName} ${propLastName}`
                            : 'Not Available'}
                        </h5>
                      }>
                      <div>
                        {userId == propUserId &&
                          roleId === 7 &&
                          !psStatusArr.includes(psStatus1stLevel) &&
                          !finalOutcomeOfSecretariat && (
                            <div class="text-right mb-3">
                              <Button
                                kind="tertiary"
                                onClick={() => {
                                  this.handleEditForm(evaluationId);
                                }}>
                                Edit
                              </Button>
                            </div>
                          )}
                      </div>
                      <EvaluationForm
                        handleRadioBtnChange={this.handleRadioBtnChange}
                        state={this.state}
                        handleChange={this.handleChange}
                        handleCommisionRadioBtnChange={
                          this.handleCommisionRadioBtnChange
                        }
                        userId={propUserId}
                        evaluationId={evaluationId}
                        response={res}
                        isFormSubmitted={isFormSubmitted}
                        isEditForm={isEditForm}
                        selectionYear={selectionYear}
                      />
                      {userId == propUserId && isEditForm === true && (
                        <div class="text-center mt-3">
                          <Button
                            kind="primary"
                            onClick={() =>
                              selectionYear > 2023
                                ? this.handlesubmitEvalFormPost2023()
                                : this.handleSubmitEvalForm()
                            }>
                            Submit
                          </Button>
                        </div>
                      )}
                    </AccordionItem>
                  );
                })}
              </Accordion>
              <div className="h6 f-w-b mt-2 mb-2">Legend</div>
              <div className="bx--row align-items-center mt-2">
                <div className="bx--row align-items-center bx--col-lg-8 bx--col-md-12">
                  {usersList &&
                    Array.isArray(usersList) &&
                    usersList.length > 0 &&
                    usersList.map((vals, idx) => (
                      <div className="bx--col-lg-3 bx--col-md-3 mt-2 mb-2">
                        <i
                          className={`fa fa-circle trait-color-${idx + 1}`}
                          aria-hidden="true"
                        />{' '}
                        <span className="font-weight-bold text-capitalize">
                          {vals}
                        </span>
                      </div>
                    ))}
                </div>
                <div className="d-flex align-items-center bx--col-lg-4 justify-content-end pr-xl-0 pr-4 bx--col-md-12">
                  <div className="h6">Consolidated Evaluation Form</div>
                  <div className="ml-2">
                    <Button kind="tertiary" onClick={this.handleGetEvalAPi}>
                      Refresh
                    </Button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="mt-2">
                <h5 className="mb-2">Combined Evaluation Form</h5>
                <div>
                  <CombinedEvaluationForm
                    combinedEvalForm={combinedEvalForm}
                    evalStaticData={getEvalStaticDataBySelYear(selectionYear)}
                    selectionYear={selectionYear}
                  />
                </div>
                <div>
                  <h5 className="mb-2 mt-2">Combined Overall Rating</h5>
                  <div className="font-weight-bold mb-2">Overall Rating</div>
                  <div className="mt-2 mb-2">
                    <div className="mt-2">
                      <div className="d-flex justify-content-left">
                        <span style={{ fontWeight: 'bold' }} className="pr-2">
                          {awardPscCount}
                        </span>{' '}
                        {selectionYear > 2023 ? null : 'To '}Offer PSC
                        Scholarship&nbsp;
                        {awardPscArr &&
                          Array.isArray(awardPscArr) &&
                          awardPscArr.length > 0 &&
                          awardPscArr.map((res) => (
                            <span className="pr-2">
                              <i
                                className={`fa fa-circle trait-color-${res}`}
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                      </div>
                      {selectionYear > 2023 ? (
                        <div className="d-flex justify-content-left mt-2 ml-5">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {shortlistPresidentScholarshipCount}
                          </span>{' '}
                          Shortlisted for President’s Scholarship&nbsp;
                          {reactMap(shortlistPresidentScholarshipArr, (res) => (
                            <span className="pr-2">
                              <i
                                className={`fa fa-circle trait-color-${res}`}
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                        </div>
                      ) : (
                        <div className="d-flex justify-content-left mt-2 ml-5">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {considerPscCount}
                          </span>{' '}
                          To be considered for President's Scholarship&nbsp;
                          {considerPscArr &&
                            Array.isArray(considerPscArr) &&
                            considerPscArr.length > 0 &&
                            considerPscArr.map((res) => (
                              <span className="pr-2">
                                <i
                                  className={`fa fa-circle trait-color-${res}`}
                                  aria-hidden="true"
                                />
                              </span>
                            ))}
                        </div>
                      )}
                      <div className="d-flex justify-content-left mt-2 ml-5">
                        <span style={{ fontWeight: 'bold' }} className="pr-2">
                          {reservePscCount}
                        </span>{' '}
                        Reserve Candidate for President's Scholarship&nbsp;
                        {reservePscArr &&
                          Array.isArray(reservePscArr) &&
                          reservePscArr.length > 0 &&
                          reservePscArr.map((res) => (
                            <span className="pr-2">
                              <i
                                className={`fa fa-circle trait-color-${res}`}
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                      </div>
                    </div>
                    {selectionYear > 2023 ? (
                      <>
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {recByPscToMinOrAgenciesCount}
                          </span>{' '}
                          Recommended by PSC to Ministries/Agencies for: &nbsp;
                          {reactMap(recByPscToMinOrAgenciesArr, (res) => (
                            <span className="pr-2">
                              <i
                                className={`fa fa-circle trait-color-${res}`}
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                        </div>
                        <div className="d-flex justify-content-left mt-2 ml-5">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {recByPscToMinOrAgenciesForScholarshipCount}
                          </span>{' '}
                          Scholarship &nbsp;
                          {reactMap(
                            recByPscToMinOrAgenciesForScholarshipArr,
                            (res) => (
                              <span className="pr-2">
                                <i
                                  className={`fa fa-circle trait-color-${res}`}
                                  aria-hidden="true"
                                />
                              </span>
                            )
                          )}
                        </div>
                        <div className="d-flex justify-content-left mt-2 ml-5">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {recByPscToMinOrAgenciesForEmpCount}
                          </span>{' '}
                          (For PSC Mid-term and PSC Masters applicants only)
                          Employment &nbsp;
                          {reactMap(recByPscToMinOrAgenciesForEmpArr, (res) => (
                            <span className="pr-2">
                              <i
                                className={`fa fa-circle trait-color-${res}`}
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {unsuitableForPscScholarshipCount}
                          </span>{' '}
                          Unsuitable for a PSC Scholarship &nbsp;
                          {reactMap(unsuitableForPscScholarshipArr, (res) => (
                            <span className="pr-2">
                              <i
                                className={`fa fa-circle trait-color-${res}`}
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {notToReferToAnyCount}
                          </span>{' '}
                          Not to refer to any Ministry/Agency for scholarship
                          (e.g. interview performance is extremely weak,
                          courtesy candidate, attitude problem) &nbsp;
                          {reactMap(notToReferToAnyArr, (res) => (
                            <span className="pr-2">
                              <i
                                className={`fa fa-circle trait-color-${res}`}
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                        </div>
                        <div className="font-weight-bold mt-3 mb-2">
                          (For Candidates who are not Offered) Track under
                          “Friends of PSC” Watch List for future scholarship
                          consideration
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {unsuitableTrackUnderFriendsOfPscYesCount}
                          </span>{' '}
                          Yes &nbsp;
                          {reactMap(
                            unsuitableTrackUnderFriendsOfPscYesArr,
                            (res) => (
                              <span className="pr-2">
                                <i
                                  className={`fa fa-circle trait-color-${res}`}
                                  aria-hidden="true"
                                />
                              </span>
                            )
                          )}
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {unsuitableTrackUnderFriendsOfPscNoCount}
                          </span>{' '}
                          No &nbsp;
                          {reactMap(
                            unsuitableTrackUnderFriendsOfPscNoArr,
                            (res) => (
                              <span className="pr-2">
                                <i
                                  className={`fa fa-circle trait-color-${res}`}
                                  aria-hidden="true"
                                />
                              </span>
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {selectionYear > 2022 && (
                          <>
                            <div className="d-flex justify-content-left mt-2">
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="pr-2">
                                {referMinistryAgenciesRecByPSCCount}
                              </span>{' '}
                              Refer to Ministry/ Agencies (Recommended by PSC)
                              &nbsp;
                              {referMinistryAgenciesRecByPSCArr &&
                                Array.isArray(
                                  referMinistryAgenciesRecByPSCArr
                                ) &&
                                referMinistryAgenciesRecByPSCArr.length > 0 &&
                                referMinistryAgenciesRecByPSCArr.map((res) => (
                                  <span className="pr-2">
                                    <i
                                      className={`fa fa-circle trait-color-${res}`}
                                      aria-hidden="true"
                                    />
                                  </span>
                                ))}
                            </div>
                            <div className="d-flex justify-content-left mt-2">
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="pr-2">
                                {referMinistryAgenciesForEmpCount}
                              </span>{' '}
                              Refer to Ministry/ Agencies for Employment &nbsp;
                              {referMinistryAgenciesForEmpArr &&
                                Array.isArray(referMinistryAgenciesForEmpArr) &&
                                referMinistryAgenciesForEmpArr.length > 0 &&
                                referMinistryAgenciesForEmpArr.map((res) => (
                                  <span className="pr-2">
                                    <i
                                      className={`fa fa-circle trait-color-${res}`}
                                      aria-hidden="true"
                                    />
                                  </span>
                                ))}
                            </div>
                          </>
                        )}
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {referMinistryAgenciesCount}
                          </span>{' '}
                          Refer to Ministry/ Agencies &nbsp;
                          {referMinistryAgenciesArr &&
                            Array.isArray(referMinistryAgenciesArr) &&
                            referMinistryAgenciesArr.length > 0 &&
                            referMinistryAgenciesArr.map((res) => (
                              <span className="pr-2">
                                <i
                                  className={`fa fa-circle trait-color-${res}`}
                                  aria-hidden="true"
                                />
                              </span>
                            ))}
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            {unsuitableCount}
                          </span>{' '}
                          Unsuitable &nbsp;
                          {unsuitableArr &&
                            Array.isArray(unsuitableArr) &&
                            unsuitableArr.length > 0 &&
                            unsuitableArr.map((res) => (
                              <span className="pr-2">
                                <i
                                  className={`fa fa-circle trait-color-${res}`}
                                  aria-hidden="true"
                                />
                              </span>
                            ))}
                        </div>
                        {selectionYear > 2022 ? (
                          <div className="d-flex justify-content-left mt-2">
                            <span
                              style={{ fontWeight: 'bold' }}
                              className="pr-2">
                              {courtesyCandCount}
                            </span>{' '}
                            Courtesy Candidate&nbsp;
                            {courtesyCandArr &&
                              Array.isArray(courtesyCandArr) &&
                              courtesyCandArr.length > 0 &&
                              courtesyCandArr.map((res) => (
                                <span className="pr-2">
                                  <i
                                    className={`fa fa-circle trait-color-${res}`}
                                    aria-hidden="true"
                                  />
                                </span>
                              ))}
                          </div>
                        ) : (
                          <div className="d-flex justify-content-left mt-2">
                            <span
                              style={{ fontWeight: 'bold' }}
                              className="pr-2">
                              {veryUnsuitableCount}
                            </span>{' '}
                            Very Unsuitable - Courtesy Candidate&nbsp;
                            {veryUnsuitableArr &&
                              Array.isArray(veryUnsuitableArr) &&
                              veryUnsuitableArr.length > 0 &&
                              veryUnsuitableArr.map((res) => (
                                <span className="pr-2">
                                  <i
                                    className={`fa fa-circle trait-color-${res}`}
                                    aria-hidden="true"
                                  />
                                </span>
                              ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <h5 className="mb-2 mt-2">Combined Overall Comments</h5>
                  {evaluationList &&
                    Array.isArray(evaluationList) &&
                    evaluationList.length > 0 &&
                    evaluationList.map((data, idx) => {
                      const { comments, userinfo } = data || {};
                      const { firstname, lastname } = userinfo || {};
                      return (
                        <div className="text-left bx--row align-items-center mt-2 mb-2">
                          <div className="text-capitalize f-w-b bx--col-lg-2 bx--col-md-3">
                            <i
                              className={`fa fa-circle trait-color-${idx + 1}`}
                              aria-hidden="true"
                            />{' '}
                            {firstname} {lastname}:
                          </div>
                          <div
                            style={{ lineHeight: 1.5 }}
                            className="bx--col-lg-9 bx--col-md-5">
                            {comments || '-'}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <hr />
                {ispsStatus1stLevelvalid === true && (
                  <div className="small invalid-color">
                    <InlineNotification
                      lowContrast
                      title="Please select valid options"
                      iconDescription="describes the close button"
                      kind="error"
                      onCloseButtonClick={() => {
                        this.setState({ ispsStatus1stLevelvalid: false });
                      }}
                    />
                  </div>
                )}
                {roleId !== 8 && roleId !== 9 && roleId !== 10 && (
                  <Fragment>
                    <h6 className="mt-2 mb-2">Final Outcome Section</h6>
                    <div
                      className={`mt-2 mb-2 ${
                        roleId !== 6 && roleId !== 2 && 'csc-radio-btn-disabled'
                      }`}>
                      <div className="mt-2">
                        <div className="d-flex justify-content-left">
                          <RadioButton
                            name="finalOutcomeAwardPsc"
                            id="awardPsc"
                            key="awardPsc"
                            value="awardPsc"
                            disabled={roleId !== 6 && roleId !== 2}
                            checked={finalOutcomeAwardPsc === 'awardPsc'}
                            onChange={(v, n) => {
                              this.handleChangeAwardSection(v, n);
                            }}
                            labelText={`${
                              selectionYear > 2023 ? '' : 'To '
                            }Offer PSC Scholarship`}
                          />
                        </div>
                        {finalOutcomeAwardPsc === 'awardPsc' && (
                          <Fragment>
                            {selectionYear > 2023 ? (
                              <>
                                <div className="d-flex justify-content-left mt-2 ml-5">
                                  <RadioButton
                                    name="psStatus1stLevel"
                                    id="psStatus1stLevel-shortlist"
                                    key="psStatus1stLevel-shortlist"
                                    value="President’s Scholarship (shortlist)"
                                    disabled={roleId !== 6 && roleId !== 2}
                                    checked={
                                      psStatus1stLevel ===
                                      'President’s Scholarship (shortlist)'
                                    }
                                    onChange={(v, n) => {
                                      this.handleFinalOutcomeSectionChange(
                                        v,
                                        n
                                      );
                                    }}
                                    labelText="Shortlisted for President’s Scholarship"
                                  />
                                </div>
                                <div className="d-flex justify-content-left mt-2 ml-5">
                                  <RadioButton
                                    name="psStatus1stLevel"
                                    id="psStatus1stLevel-reservePSC"
                                    key="psStatus1stLevel-reservePSC"
                                    value="President’s Scholarship (reserve)"
                                    disabled={roleId !== 6 && roleId !== 2}
                                    checked={
                                      psStatus1stLevel ===
                                      'President’s Scholarship (reserve)'
                                    }
                                    onChange={(v, n) => {
                                      this.handleFinalOutcomeSectionChange(
                                        v,
                                        n
                                      );
                                    }}
                                    labelText="Reserve Candidate for President's Scholarship"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="d-flex justify-content-left mt-2 ml-5">
                                  <RadioButton
                                    name="psStatus1stLevel"
                                    id="psStatus1stLevel-considerPsc"
                                    key="psStatus1stLevel-considerPsc"
                                    value="President's Scholarship (identified)"
                                    disabled={roleId !== 6 && roleId !== 2}
                                    checked={
                                      psStatus1stLevel ===
                                      "President's Scholarship (identified)"
                                    }
                                    onChange={(v, n) => {
                                      this.handleFinalOutcomeSectionChange(
                                        v,
                                        n
                                      );
                                    }}
                                    labelText="To be considered for President's Scholarship"
                                  />
                                </div>
                                {psStatus1stLevel ===
                                  "President's Scholarship (identified)" && (
                                  <div className="d-flex justify-content-left mt-2 ml-5 pl-5">
                                    <RadioButton
                                      name="psStatus2ndLevel"
                                      id="psStatus2ndLevel-considerPsc"
                                      key="psStatus2ndLevel-considerPsc"
                                      value="Shortlisted President's Scholarship (identified)"
                                      disabled={roleId !== 6 && roleId !== 2}
                                      checked={
                                        psStatus2ndLevel ===
                                        "Shortlisted President's Scholarship (identified)"
                                      }
                                      onChange={(v, n) => {
                                        this.handleFinalOutcomeSectionChange(
                                          v,
                                          n
                                        );
                                      }}
                                      labelText="Shortlisted for President’s Scholarship"
                                    />
                                  </div>
                                )}
                                <div className="d-flex justify-content-left mt-2 ml-5">
                                  <RadioButton
                                    name="psStatus1stLevel"
                                    id="psStatus1stLevel-reservePSC"
                                    key="psStatus1stLevel-reservePSC"
                                    value="President’s Scholarship (reserve)"
                                    disabled={roleId !== 6 && roleId !== 2}
                                    checked={
                                      psStatus1stLevel ===
                                      'President’s Scholarship (reserve)'
                                    }
                                    onChange={(v, n) => {
                                      this.handleFinalOutcomeSectionChange(
                                        v,
                                        n
                                      );
                                    }}
                                    labelText="Reserve Candidate for President's Scholarship"
                                  />
                                </div>
                                {psStatus1stLevel ===
                                  'President’s Scholarship (reserve)' && (
                                  <div className="d-flex justify-content-left mt-2 ml-5 pl-5">
                                    <RadioButton
                                      name="psStatus2ndLevel"
                                      id="psStatus2ndLevel-reservePsc"
                                      key="psStatus2ndLevel-reservePsc"
                                      value="Shortlisted President's Scholarship (reserve)"
                                      disabled={roleId !== 6 && roleId !== 2}
                                      checked={
                                        psStatus2ndLevel ===
                                        "Shortlisted President's Scholarship (reserve)"
                                      }
                                      onChange={(v, n) => {
                                        this.handleFinalOutcomeSectionChange(
                                          v,
                                          n
                                        );
                                      }}
                                      labelText="Shortlisted for President’s Scholarship"
                                    />
                                  </div>
                                )}
                                <div className="d-flex justify-content-left mt-2 ml-5">
                                  <RadioButton
                                    name="psStatus1stLevel"
                                    id="psStatus1stLevel-shortlist"
                                    key="psStatus1stLevel-shortlist"
                                    value="President’s Scholarship (shortlist)"
                                    disabled={roleId !== 6 && roleId !== 2}
                                    checked={
                                      psStatus1stLevel ===
                                      'President’s Scholarship (shortlist)'
                                    }
                                    onChange={(v, n) => {
                                      this.handleFinalOutcomeSectionChange(
                                        v,
                                        n
                                      );
                                    }}
                                    labelText="Shortlisted for President’s Scholarship"
                                  />
                                </div>
                              </>
                            )}
                          </Fragment>
                        )}
                      </div>

                      {selectionYear > 2023 ? (
                        <>
                          <div className="d-flex justify-content-left mt-2">
                            <RadioButton
                              name="finalOutcomeUnsuitablePscReferToMinistry"
                              id="unsuitablePscReferToMinistry"
                              key="unsuitablePscReferToMinistry"
                              value="unsuitablePscReferToMinistry"
                              checked={
                                finalOutcomeUnsuitablePscReferToMinistry ===
                                'unsuitablePscReferToMinistry'
                              }
                              disabled={roleId !== 6 && roleId !== 2}
                              labelText="Recommended by PSC to Ministries/Agencies for:"
                              onChange={(v, n) => {
                                this.handleChangeAwardSection(v, n);
                              }}
                            />
                          </div>
                          {finalOutcomeUnsuitablePscReferToMinistry ===
                            'unsuitablePscReferToMinistry' && (
                            <>
                              <div className="d-flex justify-content-left mt-2 ml-5">
                                <RadioButton
                                  name="psStatus1stLevel"
                                  id="psStatus1stLevel-recByPscToMinOrAgenciesForScholarship"
                                  key="psStatus1stLevel-recByPscToMinOrAgenciesForScholarship"
                                  value="Scholarship"
                                  checked={psStatus1stLevel === 'Scholarship'}
                                  disabled={roleId !== 6 && roleId !== 2}
                                  labelText="Scholarship"
                                  onChange={(v, n) => {
                                    this.handleFinalOutcomeSectionChange(v, n);
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-left mt-2 ml-5">
                                <RadioButton
                                  name="psStatus1stLevel"
                                  id="psStatus1stLevel-recByPscToMinOrAgenciesForEmp"
                                  key="psStatus1stLevel-recByPscToMinOrAgenciesForEmp"
                                  value="(For PSC Mid-term and PSC Masters applicants only) Employment"
                                  checked={
                                    psStatus1stLevel ===
                                    '(For PSC Mid-term and PSC Masters applicants only) Employment'
                                  }
                                  disabled={roleId !== 6 && roleId !== 2}
                                  labelText="(For PSC Mid-term and PSC Masters applicants only) Employment"
                                  onChange={(v, n) => {
                                    this.handleFinalOutcomeSectionChange(v, n);
                                  }}
                                />
                              </div>
                            </>
                          )}
                          <div className="d-flex justify-content-left mt-2">
                            <RadioButton
                              name="psStatus1stLevel"
                              id="psStatus1stLevel-unsuitableForPscScholarship"
                              key="psStatus1stLevel-unsuitableForPscScholarship"
                              value="Unsuitable for a PSC Scholarship"
                              checked={
                                psStatus1stLevel ===
                                'Unsuitable for a PSC Scholarship'
                              }
                              disabled={roleId !== 6 && roleId !== 2}
                              labelText="Unsuitable for a PSC Scholarship"
                              onChange={(v, n) => {
                                this.handleFinalOutcomeSectionChange(v, n);
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-left mt-2">
                            <RadioButton
                              name="psStatus1stLevel"
                              id="psStatus1stLevel-notToReferToAny"
                              key="psStatus1stLevel-notToReferToAny"
                              value="Not to refer to any Ministry/Agency for scholarship (e.g. interview performance is extremely weak, courtesy candidate, attitude problem)"
                              checked={
                                psStatus1stLevel ===
                                'Not to refer to any Ministry/Agency for scholarship (e.g. interview performance is extremely weak, courtesy candidate, attitude problem)'
                              }
                              disabled={roleId !== 6 && roleId !== 2}
                              labelText="Not to refer to any Ministry/Agency for scholarship (e.g. interview performance is extremely weak, courtesy candidate, attitude problem)"
                              onChange={(v, n) => {
                                this.handleFinalOutcomeSectionChange(v, n);
                              }}
                            />
                          </div>
                          <div className="mt-4 mb-2">
                            <div className="font-weight-bold">
                              (For Candidates who are not Offered) Track under
                              “Friends of PSC” Watch List for future scholarship
                              consideration
                            </div>
                            <div className="d-flex justify-content-left mt-2">
                              <RadioButton
                                labelText="Yes"
                                value="unsuitableTrackUnderFriendsOfPscFinalOutcome-true"
                                id="unsuitableTrackUnderFriendsOfPscFinalOutcome-true"
                                key="unsuitableTrackUnderFriendsOfPscFinalOutcome-true"
                                name="unsuitableTrackUnderFriendsOfPscFinalOutcome-true"
                                disabled={roleId !== 6 && roleId !== 2}
                                onChange={(e) => this.onToggleClick(true)}
                                checked={
                                  unsuitableTrackUnderFriendsOfPscFinalOutcome ===
                                  true
                                }
                              />
                            </div>
                            <div className="d-flex justify-content-left mt-2">
                              <RadioButton
                                labelText="No"
                                value="unsuitableTrackUnderFriendsOfPscFinalOutcome-false"
                                id="unsuitableTrackUnderFriendsOfPscFinalOutcome-false"
                                key="unsuitableTrackUnderFriendsOfPscFinalOutcome-false"
                                name="unsuitableTrackUnderFriendsOfPscFinalOutcome-false"
                                disabled={roleId !== 6 && roleId !== 2}
                                onChange={(e) => this.onToggleClick(false)}
                                checked={
                                  unsuitableTrackUnderFriendsOfPscFinalOutcome ===
                                  false
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {selectionYear > 2022 && (
                            <>
                              <div className="d-flex justify-content-left mt-2">
                                <RadioButton
                                  name="psStatus1stLevel"
                                  id="psStatus1stLevel-referToMinistryRecByPSC"
                                  key="psStatus1stLevel-referToMinistryRecByPSC"
                                  value="Refer to Ministry/ Agencies (Recommended by PSC)"
                                  checked={
                                    psStatus1stLevel ===
                                    'Refer to Ministry/ Agencies (Recommended by PSC)'
                                  }
                                  disabled={roleId !== 6 && roleId !== 2}
                                  labelText="Refer to Ministry/ Agencies (Recommended by PSC)"
                                  onChange={(v, n) => {
                                    this.handleFinalOutcomeSectionChange(v, n);
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-left mt-2">
                                <RadioButton
                                  name="psStatus1stLevel"
                                  id="psStatus1stLevel-referToMinistryForEmp"
                                  key="psStatus1stLevel-referToMinistryForEmp"
                                  value="Refer to Ministry/ Agencies for Employment"
                                  disabled={roleId !== 6 && roleId !== 2}
                                  checked={
                                    psStatus1stLevel ===
                                    'Refer to Ministry/ Agencies for Employment'
                                  }
                                  labelText="Refer to Ministry/ Agencies for Employment"
                                  onChange={(v, n) => {
                                    this.handleFinalOutcomeSectionChange(v, n);
                                  }}
                                />
                              </div>
                            </>
                          )}

                          <div className="d-flex justify-content-left mt-2">
                            <RadioButton
                              name="psStatus1stLevel"
                              disabled={roleId !== 6 && roleId !== 2}
                              id="psStatus1stLevel-referMinistryAgencies"
                              key="psStatus1stLevel-referMinistryAgencies"
                              value="Refer to Ministry/ Agencies"
                              checked={
                                psStatus1stLevel ===
                                'Refer to Ministry/ Agencies'
                              }
                              onChange={(v, n) => {
                                this.handleFinalOutcomeSectionChange(v, n);
                              }}
                              labelText="Refer to Ministry/ Agencies"
                            />
                          </div>

                          <div className="d-flex justify-content-left mt-2">
                            <RadioButton
                              name="psStatus1stLevel"
                              id="psStatus1stLevel-unsuitable"
                              key="psStatus1stLevel-unsuitable"
                              value="Unsuitable"
                              disabled={roleId !== 6 && roleId !== 2}
                              checked={psStatus1stLevel === 'Unsuitable'}
                              labelText="Unsuitable"
                              onChange={(v, n) => {
                                this.handleFinalOutcomeSectionChange(v, n);
                              }}
                            />
                          </div>

                          {selectionYear > 2022 ? (
                            <div className="d-flex justify-content-left mt-2">
                              <RadioButton
                                name="psStatus1stLevel"
                                id="psStatus1stLevel-veryUnsuitable"
                                key="psStatus1stLevel-veryUnsuitable"
                                value="Courtesy Candidate"
                                checked={
                                  psStatus1stLevel === 'Courtesy Candidate'
                                }
                                disabled={roleId !== 6 && roleId !== 2}
                                labelText="Courtesy Candidate"
                                onChange={(v, n) => {
                                  this.handleFinalOutcomeSectionChange(v, n);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="d-flex justify-content-left mt-2">
                              <RadioButton
                                name="psStatus1stLevel"
                                id="psStatus1stLevel-veryUnsuitable"
                                key="psStatus1stLevel-veryUnsuitable"
                                value="Very Unsuitable - Courtesy Candidate"
                                checked={
                                  psStatus1stLevel ===
                                  'Very Unsuitable - Courtesy Candidate'
                                }
                                disabled={roleId !== 6 && roleId !== 2}
                                labelText="Very Unsuitable - Courtesy Candidate"
                                onChange={(v, n) => {
                                  this.handleFinalOutcomeSectionChange(v, n);
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {roleId !== 7 && (
                        <div className="mt-3">
                          <TextArea
                            name="pscComments"
                            id="pscComments"
                            disabled={roleId !== 6 && roleId !== 2}
                            value={pscComments}
                            onChange={this.handleChange}
                            labelText="Comments"
                            placeholder="Please enter the comments"
                          />
                        </div>
                      )}
                      {(roleId === 6 || roleId === 2) && (
                        <div className="mt-3">
                          <Button
                            size="small"
                            onClick={this.handleSubmitFinalOutcome}>
                            Save
                          </Button>
                        </div>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          ) : (
            <div>
              <NoContentBox
                hideTile
                showButtons
                buttonName="Refresh"
                handleOnClickButtons={this.handleGetEvalAPi}
                message="There are no ratings available for your current selection"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  evaluationList: state.CandidateInfo.evaluationList,
  user: state.x0paDuser.user,
  personalSummary: state.CandidateInfo.personalSummary,
});

const mapDispatchToProps = {
  createCandidateEvalDetails,
  upsertCandidateEvaluationDetails,
  getCandidateEvaluationDetails,
  upsertCandidateDetailsById,
  downloadHighLevelReports,
  getPersonalSummary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateEvaluationForm);
