import React from 'react';

const ColoredRectangle = ({ text, color }) => {
  const finalColor = color?.toString()?.toUpperCase();
  return (
    <div
      className="text-center bold mt-1 mb-2"
      style={{
        width: 'min-content',
        fontWeight: 'bold',
        backgroundColor: finalColor,
        borderRadius: '5px',
        padding: '1rem',
        // border: 'groove',
      }}>
      <span>
        {finalColor === '#7BF39C'
          ? 'Good'
          : finalColor === '#EFB132'
          ? 'Fair'
          : finalColor === '#fa9196'
          ? 'Poor'
          : 'NA*'}
      </span>
    </div>
  );
};

export default ColoredRectangle;
