import React, { Fragment } from 'react';
import { Header, HeaderName } from 'carbon-components-react';
import './Header.css';
import SGDSMasthead from './SGDSMasthead';

const PublicHeader = (props) => {
  const { push } = props || {};
  return (
    <div className="sticky-top">
      <SGDSMasthead />
      <Header aria-label="CSC">
        <HeaderName
          onClick={() => {
            push && push('/');
          }}
          href="javascript:void(0)"
          prefix="">
          <h2 className="text-light font-weight-bold">ARS</h2>
        </HeaderName>
      </Header>
    </div>
  );
};

export default PublicHeader;
