import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  userLogin,
  fetchUserLoginState,
  verifyEmailData,
} from '../../actions/UserLogin';
import { resetPanelMemPassword } from '../../actions/AdminSection';
import classNames from 'classnames';
import PublicHeader from '../../headers/PublicHeader';
import CscImage from '../../assets/images/x0pa-brown.png';
import ServiceWorkerWrapper from '../../NotificationUpdate.js';
import { InlineNotification, Loading } from 'carbon-components-react';
import { passwordStrength } from 'check-password-strength';
import moment from 'moment';
import {
  defaultPassOptions,
  validatePassword,
} from '../../containers/common/common';
import SGDSMastfooter from '../../footers/SGDSMastfooter.js';
class CscLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailVerifed: false,
      showChangePassword: false,
      submittingRepassword: false,
    };
  }

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleGetOptData = () => {
    const { username, resendOTP } = this.state;
    this.props
      .verifyEmailData({ email: username, password: this.state.password })
      .then((res) => {
        if (res && !res.error) {
          const { tempPasswordDetails } = res || {};
          const { tempPasswordEnabled } = tempPasswordDetails || {};
          const hasTempPasswordExpired =
            this.isTempPassExpired(tempPasswordDetails);
          if (tempPasswordEnabled && hasTempPasswordExpired) {
            this.setState({
              showErrNotif: false,
              // isEmailVerifed: true,
              isLogging: false,
              sessionId: res && res.sessionId,
              hasTempPasswordExpired,
            });
          } else {
            if (resendOTP !== true) {
              this.setState({
                showErrNotif: false,
                isEmailVerifed: true,
                isLogging: false,
                sessionId: res && res.sessionId,
                hasTempPasswordExpired: false,
                tempPasswordDetails,
              });
            } else {
              this.setState({
                resendOtpSuccess: true,
                sessionId: res && res.sessionId,
                hasTempPasswordExpired: false,
                tempPasswordDetails,
              });
            }
          }
        } else {
          this.setState({
            showErrNotif: true,
            isLogging: false,
            errorMsg: res && res.error,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          showErrNotif: true,
          isLogging: false,
        });
      });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const { isEmailVerifed, tempPasswordDetails } = this.state;
    const { tempPasswordEnabled } = tempPasswordDetails || {};

    if (!isEmailVerifed) {
      this.setState(
        {
          isLogging: true,
        },
        () => {
          this.handleGetOptData();
        }
      );
    } else {
      if (!tempPasswordEnabled) {
        window.sessionStorage.removeItem('loggedout');
      }

      this.props.setLogging(true);
      this.setState({ isLogging: true });
      if (
        !this.state.username ||
        !this.state.password ||
        !this.state.otpValue
      ) {
        this.handleErrorState(this.state.captcha);
        return;
      }
      this.props
        .userLogin(
          this.state.username,
          this.state.password,
          this.state.otpValue,
          this.state.sessionId
        )
        .then((response) => {
          if (response && response.error === null) {
            this.setState({ isLogging: false });
            if (tempPasswordEnabled) {
              this.props.setLogging(false);
              this.setState({ showChangePassword: true });
            } else {
              this.props.setLogging(false);
              const { user: { userId } = {} } = this.props;
              this.props
                .fetchUserLoginState(userId)
                .then((data) => {
                  if (data && !data.error) {
                    const { user, history } = this.props;
                    const { push } = history || {};
                    const { forcedPassChange } = user || {};
                    if (forcedPassChange === true) {
                      if (push) {
                        push('/reset-password');
                      }
                    }
                  }
                })
                .catch((e) => console.log('Error', e));
            }
          } else {
            this.handleErrorState(true, response);
            throw 'Invalid Username or Password or OTP';
          }
        })
        .catch(() => {
          this.handleErrorState(true);
        });
    }
  };

  handleErrorState = (givenCaptcha, response) => {
    this.props.setLogging(false);
    this.setState({
      isLogging: false,
      error: true,
      captcha: null,
      noCaptchaProvided: !givenCaptcha,
      errorMsg: response && response.error,
    });
  };

  onCloseButtonClick = () => {
    this.setState({
      error: false,
      resendOtpSuccess: false,
    });
  };

  handleOnChangeNewPassword = (name, value) => {
    let passwordErrMsg;
    if (name === 'password') {
      let passwordComplexity = passwordStrength(value, defaultPassOptions);
      passwordErrMsg = validatePassword(passwordComplexity);
    }

    this.setState({
      [name]: value,
      passwordErrMsg,
      confirmPasswordErrMsg: null,
    });
  };

  changeAndValidate = (name, value) => {
    const { newpassword } = this.state;
    let passwordErrMsg;
    let confirmPasswordErrMsg;
    if (name === 'confirmpassword') {
      let passwordComplexity = passwordStrength(
        newpassword,
        defaultPassOptions
      );
      passwordErrMsg = validatePassword(passwordComplexity);
      if (value && value.trim().length > 0) {
        confirmPasswordErrMsg = null;
      }
    }

    this.setState({
      [name]: value,
      passwordErrMsg,
      confirmPasswordErrMsg,
    });
  };

  updatePassword = (e) => {
    e.preventDefault();
    const { newpassword, confirmpassword, tempPasswordDetails } = this.state;
    const { token } = tempPasswordDetails || {};

    let passwordErrMsg;
    let confirmPasswordErrMsg;
    let passwordComplexity = passwordStrength(newpassword, defaultPassOptions);
    passwordErrMsg = validatePassword(passwordComplexity);
    confirmPasswordErrMsg =
      newpassword !== confirmpassword ? 'Must match your new password.' : null;
    this.setState({
      passwordErrMsg,
      confirmPasswordErrMsg,
    });

    if (passwordErrMsg || confirmPasswordErrMsg) {
      return;
    }

    const data = {
      newPassword: newpassword,
      token,
      source: 'internal-reset-password-confirmation',
    };

    this.setState({ submittingRepassword: true }, () => {
      this.props
        .resetPanelMemPassword(data)
        .then((res) => {
          if (res && res.error) {
            this.setState({
              showErrNotif: true,
              errorMsg:
                res?.error?.message ||
                'There was an error in processing. Please try again later.',
            });
          } else {
            alert('Password reset was successful. Redirecting to login page.');
            window.sessionStorage.setItem('loggedout', true);
            window.location.href = '/auth/logout';
          }
          this.setState({
            submittingRepassword: false,
          });
        })
        .catch((err) =>
          this.setState({
            showErrNotif: true,
            errorMsg:
              'There was an error in processing. Please try again later.',
            submittingRepassword: false,
          })
        );
    });
  };

  isTempPassExpired = (tempPasswordDetails) => {
    const { tempPasswordSetOn, allowedTimeToResetPassword = 24 } =
      tempPasswordDetails || {};

    const currentTime = new Date().getTime();
    const noOfHrsPassed =
      (currentTime -
        ((tempPasswordSetOn && new Date(tempPasswordSetOn).getTime()) || 0)) /
      (1000 * 60 * 60);

    console.log('noOfHrsPassed -->', noOfHrsPassed);
    if (noOfHrsPassed > allowedTimeToResetPassword) {
      return true;
    }
    return false;
  };

  render() {
    const { history } = this.props;
    const { push } = history || {};
    const formClass = classNames({
      'form-signin': true,
      'text-center': true,
      'p-2': true,
      card: false,
      error: this.state.error,
      'w-100': true,
    });
    const {
      isEmailVerifed,
      showErrNotif,
      isLogging,
      resendOtpSuccess,
      showChangePassword,
      passwordErrMsg,
      confirmPasswordErrMsg,
      hasTempPasswordExpired,
      submittingRepassword,
    } = this.state;

    return (
      <div>
        <PublicHeader push={push} />
        <div className="bx--row css-web-height" style={{ marginTop: '-4rem' }}>
          <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 left-bg-color d-flex flex-column justify-content-center align-items-center">
            <div>
              <img style={{ width: 200 }} src={CscImage} alt="CSC Image" />
            </div>
          </div>
          <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 d-flex flex-column justify-content-center align-items-center">
            <form
              id="loginForm"
              className={formClass}
              onSubmit={this.handleOnSubmit}>
              {this.state.error && (
                <InlineNotification
                  lowContrast
                  className="mt-1 mb-1"
                  title=""
                  iconDescription="describes the close button"
                  kind="error"
                  subtitle={
                    this.state.noCaptchaProvided
                      ? `Please click on I'm not a robot checkbox`
                      : this.state.errorMsg
                      ? this.state.errorMsg
                      : 'Invalid OTP, Please enter a valid OTP to proceed.'
                  }
                  onCloseButtonClick={this.onCloseButtonClick}
                />
              )}

              <h2 className="mb-2">Welcome</h2>

              {hasTempPasswordExpired && (
                <div>
                  <InlineNotification
                    lowContrast
                    className="mt-1 mb-1"
                    title=""
                    iconDescription="describes the close button"
                    kind="error"
                    subtitle="The temporary password has been expired. Please contact System Admin/Secratariat to get a new temporary password"
                  />
                </div>
              )}

              {showErrNotif === true && (
                <InlineNotification
                  lowContrast
                  className="mt-1 mb-1"
                  title={
                    this.state.errorMsg
                      ? this.state.errorMsg
                      : 'Invalid Email or Password, Please enter a valid email or password to proceed.'
                  }
                  iconDescription="describes the close button"
                  kind="error"
                  subtitle=""
                  hideCloseButton
                />
              )}

              {!isEmailVerifed && (
                <Fragment>
                  <div className="d-flex justify-content-center">
                    <div className="bx--form-i1tem input-wid">
                      <input
                        type="email"
                        name="user"
                        required
                        placeholder="Email"
                        disabled={isEmailVerifed}
                        autoComplete="off"
                        className="bx--text-input mb-2"
                        onChange={(e) =>
                          this.handleOnChange('username', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-2 mb-2">
                    <div className="bx--form-it1em input-wid">
                      <input
                        type="password"
                        name="pwd"
                        required
                        placeholder="Password"
                        autoComplete="off"
                        className="bx--text-input"
                        onChange={(e) =>
                          this.handleOnChange('password', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <button
                    className="bx--btn bx--btn--primary btn-block mb-2 css-btn-center"
                    type="submit">
                    Get OTP
                  </button>
                  <div
                    onClick={() => {
                      push && push('/forgot-password');
                    }}
                    className="h6 mt-2 xpa-link">
                    Forgot Password
                  </div>
                </Fragment>
              )}

              {isEmailVerifed && !showChangePassword && (
                <Fragment>
                  {resendOtpSuccess === true && (
                    <InlineNotification
                      lowContrast
                      className="mt-1 mb-1"
                      title=""
                      iconDescription="describes the close button"
                      kind="success"
                      subtitle="OTP has been sent successfully"
                      onCloseButtonClick={this.onCloseButtonClick}
                    />
                  )}
                  <div className="d-flex justify-content-center mt-2 mb-2">
                    <div className="bx--form-it1em input-wid">
                      <input
                        type="otpValue"
                        name="otpValue"
                        required
                        placeholder="OTP"
                        autoComplete="current-password"
                        className="bx--text-input"
                        onChange={(e) =>
                          this.handleOnChange('otpValue', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="">
                      <button
                        className="bx--btn bx--btn--primary"
                        type="submit">
                        Sign In
                      </button>
                    </div>
                    <div className="ml-2">
                      <button
                        onClick={() => {
                          this.setState(
                            {
                              resendOTP: true,
                            },
                            () => {
                              this.handleGetOptData();
                            }
                          );
                        }}
                        className="bx--btn bx--btn--tertiary"
                        type="button">
                        Re-Send OTP
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}
            </form>

            {isEmailVerifed && showChangePassword && (
              <Fragment>
                <form
                  id="changePasswordForm"
                  className=""
                  onSubmit={(e) => {
                    this.updatePassword(e);
                  }}>
                  <div className="bx--type-delta pb-2 ml-3">
                    Change Password
                  </div>
                  <div className="pb-2 ml-3" style={{ maxWidth: '400px' }}>
                    {this.state.username && (
                      <div className="">
                        <input
                          type="email"
                          name="user"
                          value={this.state.username || ''}
                          placeholder="Email"
                          disabled={true}
                          autoComplete="off"
                          className="bx--text-input mb-2"
                        />
                      </div>
                    )}
                    <div>
                      <input
                        type="password"
                        name="newpassword"
                        required
                        autofocus
                        size="52"
                        placeholder="New password (atleast 12 characters)"
                        className="bx--text-input mb-2"
                        onChange={(e) =>
                          this.handleOnChangeNewPassword(
                            'newpassword',
                            e.target.value
                          )
                        }
                        autoComplete="off"
                      />
                    </div>
                    {passwordErrMsg && (
                      <div className="mb-3 text-small text-danger">
                        {passwordErrMsg}
                      </div>
                    )}
                    <div>
                      <input
                        type="password"
                        name="confirmpassword"
                        required
                        size="52"
                        placeholder="Confirm password"
                        className="bx--text-input mb-2 "
                        onChange={(e) =>
                          this.changeAndValidate(
                            'confirmpassword',
                            e.target.value
                          )
                        }
                        autoComplete="off"
                      />
                    </div>
                    {confirmPasswordErrMsg && (
                      <div className="mb-2 text-small text-danger">
                        {confirmPasswordErrMsg}
                      </div>
                    )}
                    <div>
                      <button
                        className="bx--btn bx--btn--primary "
                        disabled={passwordErrMsg || confirmPasswordErrMsg}
                        type="submit">
                        {submittingRepassword ? 'Submitting...' : 'Submit'}
                      </button>
                    </div>
                    <div className="mt-3">
                      <a className="" href="/">
                        &larr; Go back to login page
                      </a>
                    </div>
                  </div>
                </form>
              </Fragment>
            )}
          </div>
        </div>
        {isLogging === true && (
          <Loading className="d-inline-block" withOverlay />
        )}
        <ServiceWorkerWrapper />
        <SGDSMastfooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  userLogin,
  fetchUserLoginState,
  verifyEmailData,
  resetPanelMemPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(CscLogin);
