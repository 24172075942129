import React, { Component } from 'react';
import { connect } from 'react-redux';
//Api Actions
import {
  getDasboardStatistics,
  getSecondRounders,
  getPBITokens,
} from '../../actions/Dashboard';
import { upsertCandidateDetailsById } from '../../actions/CandidateInfo';
//Common Components
import BackRow from '../../components/common/BackRow';
import GenericTabs from '../../components/common/GenericTabs';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
//Functional common components
import CurrentBatchInfo from '../../components/private/CurrentBatchInfo';
import DashboardAnalytics from '../../components/private/DashboardAnalytics';
import DashboardAnalyticsPowerBI from '../../components/private/DashboardAnalyticsPowerBI';
import SecondRounders from '../../components/private/SecondRounders';
import NivoPieChart from '../../components/common/NivoPieChart';
import SuccErrNotification from '../../components/private/SuccErrNotification';
//Carbon components
import { Tile, Accordion, AccordionItem, Modal } from 'carbon-components-react';
import moment from 'moment';
import { capitalCase } from 'capital-case';
import { lab } from 'd3';
import _ from 'lodash';

class Dashboard extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'dashboard', name: 'Dashboard', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    const { user } = this.props;
    const { currentYear } = user || {};
    this.setState(
      {
        showLoader: true,
      },
      () => {
        this.props.getSecondRounders({ year: currentYear });
        this.props.getDasboardStatistics(currentYear);
        this.props.getPBITokens().then((res) => {
          let embedToken = '';
          let embedParams = (res && res.embedParams) || null;
          let reportsMap = {};

          if (embedParams) {
            embedToken =
              (res &&
                res.embedParams &&
                res.embedParams.embedToken &&
                res.embedParams.embedToken.token) ||
              '';

            if (
              embedParams.reportsDetail &&
              Array.isArray(embedParams.reportsDetail) &&
              embedParams.reportsDetail.length > 0
            ) {
              for (let i = 0; i < embedParams.reportsDetail.length; i++) {
                let reportId = embedParams.reportsDetail[i].reportId || i;
                reportsMap[reportId] = embedParams.reportsDetail[i];
              }
            }
          }
          this.setState({
            reportsMap,
            embedToken,
            showLoader: false,
          });
        });
        this.handleRedirect &&
          this.handleRedirect.current &&
          this.handleRedirect.current.focus &&
          this.handleRedirect.current.focus();
      }
    );
  }

  handleTabClick = (tabNumber, currSelYear) => {
    const finalYear = currSelYear;
    this.setState(
      {
        selectedTab: tabNumber,
        showLoader: true,
        finalYear
      },
      () => {
        this.props
          .getDasboardStatistics(finalYear)
          .then(() => {
            this.setState({
              showLoader: false,
            });
          })
          .catch((e) => console.log('Error', e));
        this.props.getSecondRounders({ year: finalYear });
      }
    );
  };

  handleGetCurrLabel = (key, type) => {
    let label = '';
    if (key === 'Psc Scholarship') {
      label = 'PSC Scholarship';
    } else if (key === 'Medicine Dentistry') {
      label = 'Medicine/Dentistry';
    } else if (key === 'Science Mathematics') {
      label = 'Science/Mathematics';
    } else if (key === 'Psc Master S Scholarship') {
      label = "PSC Master's Scholarship";
    } else if (key === 'Humanities Social Sciences') {
      label = 'Humanities & Social Sciences';
    } else if (key === 'Engineering Ict') {
      if (type === 'scholarship') {
        label = 'Engineering';
      } else {
        label = 'Engineering & ICT';
      }
    } else if (key === 'Economics Ppe') {
      label = 'Economics/PPE';
    } else if (key === 'Finance Business Accountancy') {
      if (type === 'scholarship') {
        label = 'Public Finance';
      } else {
        label = 'Finance/Business/Accountancy';
      }
    } else if (key === '1 2 Room Hdb') {
      label = '1-2 room HDB';
    } else if (key === '3 Room Hdb') {
      label = '3 - room HDB';
    } else if (key === '4 Room Hdb') {
      label = '4 - room HDB';
    } else if (key === '5rm Hdb Exec Flat') {
      label = '5-Room HDB/Executive Flat';
    } else if (key === 'Landed Properties Others Housing') {
      label = 'Landed Properties & Others Housing';
    } else if (key === 'Integrated Programme (IP) and Specialised Schools') {
      label = 'Other IP/Specialised Schools';
    } else if (key === 'Hci Ri') {
      label = 'HCI/RI';
    } else if (key === 'Singapore Police Forces') {
      label = 'Singapore Police Force';
    } else if (key === 'Other Ip Specialised Schools') {
      label = 'Other IP/Specialised Schools';
    } else if (key === 'Non Ip Schools') {
      label = 'Non-IP Schools';
    } else if (key === 'United States Of America') {
      label = 'United States of America';
    } else {
      label = capitalCase(key);
    }
    return label;
  };

  handleGetAllKeysData = (data, keys, emptyValsObj, type) => {
    let finalData = new Map();
    let dataKeys = [];
    if (data && Array.isArray(data) && data.length > 0) {
      dataKeys = data.map((res) => res && res.label);
      data.forEach((res) => {
        const { label } = res || {};
        finalData.set(label, res);
      });
    }
    let lowerCaseKeys = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      lowerCaseKeys = keys.map(
        (key) => key && key.toString() && key.toString().toLowerCase()
      );
    }
    let remainingKeys = [];
    if (
      lowerCaseKeys &&
      Array.isArray(lowerCaseKeys) &&
      lowerCaseKeys.length > 0
    ) {
      remainingKeys = lowerCaseKeys.filter((key) => {
        if (key && dataKeys.includes(key)) {
          return false;
        }
        return true;
      });
    }
    if (
      remainingKeys &&
      Array.isArray(remainingKeys) &&
      remainingKeys.length > 0
    ) {
      remainingKeys.forEach((key) => {
        const finalObj = {
          ...emptyValsObj,
          label: key,
          key: capitalCase(key),
        };
        finalData.set(key, finalObj);
      });
    }
    let finalOrderArr = [];
    keys.forEach((key) => {
      finalOrderArr.push(finalData.get(key && key.toString().toLowerCase()));
    });
    const finalGraphData = [];
    if (
      finalOrderArr &&
      Array.isArray(finalOrderArr) &&
      finalOrderArr.length > 0
    ) {
      finalOrderArr.forEach((res) => {
        const { key } = res || {};
        const obj = {
          ...res,
          key: this.handleGetCurrLabel(capitalCase(key), type),
        };
        finalGraphData.push(obj);
      });
    }
    return finalGraphData;
  };

  handleGetBarLabels = (label) => {
    let finalScheme = '';
    if (label) {
      if (label.includes('medicine')) {
        finalScheme = 'medicine / dentistry';
      } else if (label.includes('mathematics')) {
        finalScheme = 'science / mathematics';
      } else if (label.includes('humanities')) {
        finalScheme = 'humanities & social sciences';
      } else if (label.includes('engineering')) {
        finalScheme = 'engineering & ict';
      } else if (label.includes('economics')) {
        finalScheme = 'economics / ppe';
      } else if (label.includes('finance')) {
        finalScheme = 'finance / business / accountancy';
      } else if (label === 'fa') {
        finalScheme = 'financial assistance';
      } else if (label === 'non fa') {
        finalScheme = 'non-financial assistance';
      } else if (label === '1-2rm hdb') {
        finalScheme = '1-2 room HDB';
      } else if (label === '3rm hdb') {
        finalScheme = '3 - room HDB';
      } else if (label === '4rm hdb') {
        finalScheme = '4 - room HDB';
      } else if (label === '5rm hdb') {
        finalScheme = '5 - room HDB';
      } else if (label === 'landed property & other housing') {
        finalScheme = 'Landed Properties & Others Housing';
      } else {
        finalScheme = label;
      }
    }
    return finalScheme;
  };

  handleGetScholarshipSchemeData = (schemeStatistics) => {
    const keys = schemeStatistics && Object.keys(schemeStatistics);
    const scholarshipScheme = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((scheme) => {
        const subSchemes = scheme && schemeStatistics[scheme];
        const subSchemeKeys = subSchemes && Object.keys(subSchemes);
        if (
          subSchemeKeys &&
          Array.isArray(subSchemeKeys) &&
          subSchemeKeys.length > 0
        ) {
          subSchemeKeys.forEach((subSchemeKey) => {
            if (subSchemeKey) {
              const { target, accepted, pending, declined, modelPopup } =
                subSchemes[subSchemeKey] || {};
              const withdrew =
                subSchemes && subSchemes[subSchemeKey]['withdrew acceptance'];
              const finalDeclined =
                (declined && Number(declined)) +
                  (withdrew && Number(withdrew)) || 0;
              const offered =
                (accepted && Number(accepted)) +
                  (pending && Number(pending)) +
                  (declined && Number(declined)) +
                  (withdrew && Number(withdrew)) || 0;
              const obj = {
                key: capitalCase(
                  this.handleGetBarLabels(subSchemeKey.toString().toLowerCase())
                ),
                label: this.handleGetBarLabels(
                  subSchemeKey.toString().toLowerCase()
                ),
                target: target || 0,
                offered: offered || 0,
                accepted: accepted || 0,
                pending: pending || 0,
                'declined / withdrew acceptance': finalDeclined || 0,
                modelPopup,
              };
              scholarshipScheme.push(obj);
            }
          });
        }
      });
    }
    const labels = [
      'PSC Scholarship',
      'Singapore Armed Forces',
      'Singapore Police Force',
      'Engineering & ICT',
      'Foreign Service',
      'Legal Service',
      'Medicine / Dentistry',
      'Finance / Business / Accountancy',
      'Teaching Service',
      'PSC Master’s Scholarship',
    ];
    const emptyValsObj = {
      target: 0,
      offered: 0,
      accepted: 0,
      pending: 0,
      'declined / withdrew acceptance': 0,
    };
    const finalScholarshipSchemes = this.handleGetAllKeysData(
      scholarshipScheme,
      labels,
      emptyValsObj,
      'scholarship'
    );
    return finalScholarshipSchemes;
  };

  handleGetTargetOfferedByCountry = (countryStatistics, isOtherCountry) => {
    const keys = countryStatistics && Object.keys(countryStatistics);
    if (!isOtherCountry) {
      const finalData = [];
      if (keys && Array.isArray(keys) && keys.length > 0) {
        keys.forEach((key) => {
          if (key) {
            const {
              target = 0,
              accepted,
              pending,
              declined,
            } = countryStatistics[key] || {};
            const withdrew =
              (countryStatistics &&
                countryStatistics[key]['withdrew acceptance']) ||
              0;
            const offered =
              (accepted && Number(accepted)) +
                (pending && Number(pending)) +
                (declined && Number(declined)) +
                (withdrew && Number(withdrew)) || 0;
            finalData.push({
              key: capitalCase(key),
              label: key,
              target: target || 0,
              offered: offered || 0,
            });
          }
        });
      }
      const labels = [
        'United Kingdom',
        'United States Of America',
        'Singapore',
        'China',
        'france',
        'germany',
        'japan',
        'others',
        'pending',
      ];
      const emptyValsObj = {
        target: 0,
        offered: 0,
      };
      const finalCountryArr = this.handleGetAllKeysData(
        finalData,
        labels,
        emptyValsObj
      );
      return finalCountryArr;
    }
    const otherCountryDataArr = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        if (key && key !== 'others') {
          const { accepted, pending, declined } = countryStatistics[key] || {};
          const withdrew =
            (countryStatistics &&
              countryStatistics[key]['withdrew acceptance']) ||
            0;
          const offered =
            (accepted && Number(accepted)) +
              (pending && Number(pending)) +
              (declined && Number(declined)) +
              (withdrew && Number(withdrew)) || 0;
          otherCountryDataArr.push({
            key: key && capitalCase(key),
            offered: offered || 0,
          });
        }
      });
    }
    return otherCountryDataArr;
  };

  handleGetCoursesByGender = (courseGenderStatistics) => {
    const coursesByGenderData =
      courseGenderStatistics && Object.keys(courseGenderStatistics);
    const coursesByGenderDataArr = [];
    if (
      coursesByGenderData &&
      Array.isArray(coursesByGenderData) &&
      coursesByGenderData.length > 0
    ) {
      coursesByGenderData.forEach((key) => {
        if (key) {
          const {
            male = 0,
            female = 0,
            total = 0,
            accepted = 0,
            pending = 0,
            declined = 0,
          } = courseGenderStatistics[key] || {};
          const withdrew =
            (courseGenderStatistics &&
              courseGenderStatistics[key]['withdrew acceptance']) ||
            0;
          const offered =
            (accepted && Number(accepted)) +
              (pending && Number(pending)) +
              (declined && Number(declined)) +
              (withdrew && Number(withdrew)) || 0;
          if (
            key === 'others' || key === 'pending'
              ? offered > 0 || male > 0 || female > 0
              : true
          ) {
            coursesByGenderDataArr.push({
              key: capitalCase(
                this.handleGetBarLabels(key.toString().toLowerCase())
              ),
              label: this.handleGetBarLabels(key.toString().toLowerCase()),
              male,
              female,
              total,
              offered,
            });
          }
        }
      });
    }
    const labels = [
      'Humanities & Social Sciences',
      'Science / Mathematics',
      'Engineering & ICT',
      'Economics / PPE',
      'Law',
      'Finance / Business / Accountancy',
      'Liberal Arts',
      'Medicine / Dentistry',
    ];
    if (
      coursesByGenderDataArr &&
      Array.isArray(coursesByGenderDataArr) &&
      coursesByGenderDataArr.length > 0
    ) {
      coursesByGenderDataArr.forEach((course) => {
        const { label } = course || {};
        if (label === 'others') {
          labels.push('others');
        } else if (label === 'pending') {
          labels.push('pending');
        }
      });
    }
    const emptyValsObj = {
      total: 0,
      female: 0,
      male: 0,
      offered: 0,
    };

    const finalCoursesByGenderData = this.handleGetAllKeysData(
      coursesByGenderDataArr,
      labels,
      emptyValsObj
    );
    return finalCoursesByGenderData;
  };

  handleGetScholarshipByGender = (genderStatistics) => {
    let keys = genderStatistics && Object.keys(genderStatistics);
    const scholarshipByGenderData = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        const { interviewed, gender, accepted, pending, declined } =
          (genderStatistics && genderStatistics[key]) || {};
        const withdrew =
          (genderStatistics &&
            genderStatistics[key] &&
            genderStatistics[key]['withdrew acceptance']) ||
          0;
        const offered =
          (accepted && Number(accepted)) +
            (pending && Number(pending)) +
            (declined && Number(declined)) +
            (withdrew && Number(withdrew)) || 0;
        scholarshipByGenderData.push({
          interviewed: (interviewed && Number(interviewed)) || 0,
          offered: (offered && Number(offered)) || 0,
          key: gender && capitalCase(gender),
        });
      });
    }
    return scholarshipByGenderData;
  };

  handleGetEthinicData = (ethinicStatistics, isOtherEthinicGrp) => {
    const keys = ethinicStatistics && Object.keys(ethinicStatistics);
    if (!isOtherEthinicGrp) {
      const ethnicityArr = [];
      if (keys && Array.isArray(keys) && keys.length > 0) {
        keys.forEach((key) => {
          if (key) {
            const {
              interviewed = 0,
              accepted = 0,
              pending = 0,
              declined = 0,
            } = ethinicStatistics[key] || {};
            const withdrew =
              (ethinicStatistics &&
                ethinicStatistics[key] &&
                ethinicStatistics[key]['withdrew acceptance']) ||
              0;
            const offered =
              (accepted && Number(accepted)) +
                (pending && Number(pending)) +
                (declined && Number(declined)) +
                (withdrew && Number(withdrew)) || 0;
            ethnicityArr.push({
              key: capitalCase(
                this.handleGetBarLabels(key.toString().toLowerCase())
              ),
              label: this.handleGetBarLabels(key.toString().toLowerCase()),
              interviewed,
              offered,
            });
          }
        });
      }
      const labels = ['Chinese', 'Malay', 'Indian', 'Others'];
      const emptyValsObj = {
        interviewed: 0,
        offered: 0,
      };
      const finalEthnicityData = this.handleGetAllKeysData(
        ethnicityArr,
        labels,
        emptyValsObj
      );
      return finalEthnicityData;
    }
    const otherEthnicityArr = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        if (key) {
          const {
            interviewed = 0,
            accepted = 0,
            pending = 0,
            declined = 0,
          } = ethinicStatistics[key] || {};
          const withdrew =
            (ethinicStatistics &&
              ethinicStatistics[key] &&
              ethinicStatistics[key]['withdrew acceptance']) ||
            0;
          const offered =
            (accepted && Number(accepted)) +
              (pending && Number(pending)) +
              (declined && Number(declined)) +
              (withdrew && Number(withdrew)) || 0;
          otherEthnicityArr.push({
            key: capitalCase(key),
            interviewed,
            offered,
          });
        }
      });
    }
    return otherEthnicityArr;
  };

  handleGetSchoolLabels = (school) => {
    let label = '';
    if (school === 'Integrated Programme (IP) and Specialised Schools') {
      label = 'Other IP/Specialised Schools';
    } else {
      label = school;
    }
    return label;
  };

  handleGetSchoolStatisticsData = (schoolStatistics) => {
    const keys = schoolStatistics && Object.keys(schoolStatistics);
    let offeredBySchoolData = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((school) => {
        if (school) {
          const subSchools = school && schoolStatistics[school];
          const subSchoolKeys = subSchools && Object.keys(subSchools);
          let finalOfferedValue = 0;
          if (
            subSchoolKeys &&
            Array.isArray(subSchoolKeys) &&
            subSchoolKeys.length > 0
          ) {
            subSchoolKeys.forEach((subSchool) => {
              if (subSchool) {
                const { accepted, pending, declined } =
                  subSchools[subSchool] || {};
                const withdrew =
                  subSchools &&
                  subSchools[subSchool] &&
                  subSchools[subSchool]['withdrew acceptance'];
                const offered =
                  (accepted && Number(accepted)) +
                    (pending && Number(pending)) +
                    (declined && Number(declined)) +
                    (withdrew && Number(withdrew)) || 0;
                finalOfferedValue += offered;
              }
            });
          }
          const finalData = this.handleGetSchoolLabels(school);
          offeredBySchoolData.push({
            key: finalData,
            label:
              finalData &&
              finalData.toString() &&
              finalData.toString().toLowerCase(),
            offered: finalOfferedValue || 0,
          });
        }
      });
    }
    const labels = [
      'HCI/RI',
      'Other IP/Specialised Schools',
      'Non-IP Schools',
      'Other Institutions',
      'Polytechnics',
    ];
    const emptyValsObj = {
      offered: 0,
    };
    const finalSchoolDataArr = this.handleGetAllKeysData(
      offeredBySchoolData,
      labels,
      emptyValsObj
    );
    return finalSchoolDataArr;
  };

  handleGetDwellingTypeData = (dwellingStatistics) => {
    const keys = dwellingStatistics && Object.keys(dwellingStatistics);
    const dwellingStatisticsArr = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        const { dwelling_type, interview, offered } =
          (dwellingStatistics && dwellingStatistics[key]) || {};
        if (key) {
          const currKey = this.handleGetBarLabels(
            dwelling_type &&
              dwelling_type.toString() &&
              dwelling_type.toString().toLowerCase()
          );
          dwellingStatisticsArr.push({
            interviewed: (interview && Number(interview)) || 0,
            offered: (offered && Number(offered)) || 0,
            key: currKey,
            label:
              currKey && currKey.toString() && currKey.toString().toLowerCase(),
          });
        }
      });
    }
    const labels = [
      '1-2 room HDB',
      '3 - room HDB',
      '4 - room HDB',
      '5rm hdb & exec flat',
      'Condominium',
      'Landed Properties & Others Housing',
    ];
    const emptyValsObj = {
      interviewed: 0,
      offered: 0,
    };
    const finalDwellingStartisticsArr = this.handleGetAllKeysData(
      dwellingStatisticsArr,
      labels,
      emptyValsObj
    );
    return finalDwellingStartisticsArr;
  };

  handleGetFaIncomeData = (faIncomeStatistics) => {
    const keys = ['Non FA', 'FA'];
    const faIncomeStatisticsArr = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        faIncomeStatisticsArr.push({
          'interviewed (but not offered)':
            (faIncomeStatistics &&
              faIncomeStatistics[key] &&
              faIncomeStatistics[key].interviewed &&
              Number(faIncomeStatistics[key].interviewed)) ||
            0,
          offered:
            (faIncomeStatistics &&
              faIncomeStatistics[key] &&
              faIncomeStatistics[key].offered &&
              Number(faIncomeStatistics[key].offered)) ||
            0,
          key: key
            ? capitalCase(this.handleGetBarLabels(key.toString().toLowerCase()))
            : '',
        });
      });
    }
    return faIncomeStatisticsArr;
  };

  handleGetHouseholdIncomeData = (incomeStatistics) => {
    const keys = incomeStatistics && Object.keys(incomeStatistics);
    const incomeStatisticsArr = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((income) => {
        if (incomeStatistics) {
          incomeStatisticsArr.push({
            key: income,
            offered:
              (incomeStatistics[income].offered &&
                Number(incomeStatistics[income].offered)) ||
              0,
            interviewed:
              (incomeStatistics[income].interviewed &&
                Number(incomeStatistics[income].interviewed)) ||
              0,
          });
        }
      });
    }
    return incomeStatisticsArr;
  };

  handleGetYoyStatisticsData = (yoyStatistics) => {
    const yoyStatisticsArr = [];
    if (
      yoyStatistics &&
      Array.isArray(yoyStatistics) &&
      yoyStatistics.length > 0
    ) {
      yoyStatistics.forEach((data) => {
        const { year, accepted, interviewed, offered } = data || {};
        yoyStatisticsArr.push({
          key: year,
          accepted: (accepted && Number(accepted)) || 0,
          interviewed: (interviewed && Number(interviewed)) || 0,
          offered: (offered && Number(offered)) || 0,
        });
      });
    }
    return yoyStatisticsArr;
  };

  handleGetConfirmedIdentifiedCandidates = (data) => {
    let finalData = [];
    if (data && Array.isArray(data) && data.length > 0) {
      finalData = data.map((cand) => {
        const {
          fullName,
          genderRel,
          scheme,
          courseCatRel,
          countryRel,
          companyRel,
        } = cand || {};
        const { genderName } = genderRel || {};
        const { displayName: course } = courseCatRel || {};
        const { displayName: country } = countryRel || {};
        const { displayName: school } = companyRel || {};
        return {
          fullName,
          genderName,
          scheme,
          course,
          country,
          school,
        };
      });
    }
    return finalData;
  };

  handleGetRecommendedByAgencyCands = (data) => {
    let finalData = [];
    if (data && Array.isArray(data) && data.length > 0) {
      finalData = data.map((cand) => {
        const {
          application,
          CoursePreference,
          CountryPreference,
          schoolDetails,
        } = cand || {};
        //School Response
        const { company: schComp } =
          (schoolDetails &&
            Array.isArray(schoolDetails) &&
            schoolDetails.length > 0 &&
            schoolDetails[0]) ||
          {};
        const { displayName: school } = schComp || {};
        //Country Response
        const { country: countryObj } =
          (CountryPreference &&
            Array.isArray(CountryPreference) &&
            CountryPreference.length > 0 &&
            CountryPreference[0]) ||
          {};
        const { displayName: country } = countryObj || {};
        //Course Response
        const { subject: courseSubj } =
          (CoursePreference &&
            Array.isArray(CoursePreference) &&
            CoursePreference.length > 0 &&
            CoursePreference[0]) ||
          {};
        const { displayName: course } = courseSubj || {};
        //Application Response
        const {
          profile,
          awardedScholarshipScheme: scheme,
          appId,
          selectionYear,
          shortlisted,
        } = (application &&
          Array.isArray(application) &&
          application.length > 0 &&
          application[0]) ||
        {};
        const { fullName, gender } = profile || {};
        const { genderName } = gender || {};
        return {
          fullName,
          genderName,
          scheme,
          course,
          country,
          school,
          appId,
          selectionYear,
          shortlisted,
        };
      });
    }
    return finalData;
  };

  handleBarOnClick = (data, obj) => {
    const { key } = data || {};
    const { id } = obj || {};
    const { modelPopup } = data || {};
    const { accepted, declined, pending } = modelPopup || {};
    const { undergraduates: declinedUnderGrads, masters: declindedMasters } =
      declined || {};
    const declinedMidterms = (declined && declined['mid-term']) || 0;
    const { undergraduates: pendingUndergrads, masters: pendingMasters } =
      pending || {};
    const pendingMidterms = (pending && pending['mid-term']) || 0;
    const withdrewData = modelPopup && modelPopup['withdrew acceptance'];
    const { undergraduates: withdrewUndergrads, masters: withdrewMasters } =
      withdrewData || 0;
    const withdrewDataMidterms =
      (withdrewData && withdrewData['mid-term']) || 0;
    const { undergraduates: acceptedUndergrads, masters: acceptedMasters } =
      accepted || {};
    const acceptedMidterms = (accepted && accepted['mid-term']) || 0;
    const currSelectedBar = (modelPopup && modelPopup[id]) || '';
    const { masters, undergraduates } = currSelectedBar || {};
    let keys = [];
    if (id === 'offered') {
      const offeredUndergrads =
        pendingUndergrads +
          withdrewUndergrads +
          declinedUnderGrads +
          acceptedUndergrads || 0;

      const offeredMasters =
        pendingMasters + withdrewMasters + acceptedMasters + declindedMasters ||
        0;
      const offeredMidterms =
        declinedMidterms +
          pendingMidterms +
          withdrewDataMidterms +
          acceptedMidterms || 0;
      keys = [
        {
          value: offeredMidterms,
          label: 'Mid Term',
        },
        {
          value: offeredUndergrads,
          label: 'Undergraduates',
        },
        {
          value: offeredMasters,
          label: 'Masters',
        },
      ];
    } else {
      keys = [
        {
          value: currSelectedBar['mid-term'] || 0,
          label: 'Mid Term',
        },
        {
          value: undergraduates || 0,
          label: 'Undergraduates',
        },
        {
          value: masters || 0,
          label: 'Masters',
        },
      ];
    }
    let pieChartData = [];
    keys.forEach((r) => {
      const { value, label } = r || {};
      pieChartData.push({
        id: label,
        label,
        value: value,
      });
    });
    this.setState({
      isOpenCatModal: true,
      currBar: id,
      pieChartData: id === 'target' ? [] : pieChartData,
      scholarshipLabel: key,
    });
  };

  handleToggleShortlistValue = (e, appId) => {
    const shortlisted = e.target.value;
    const data = {
      appId,
      shortlisted,
    };
    this.setState(
      {
        showLoader: true,
      },
      () => {
        this.props
          .upsertCandidateDetailsById(data)
          .then((res) => {
            this.handleApiRes(res);
          })
          .catch((e) => console.log('Error', e));
      }
    );
  };

  handleApiRes = (res) => {
    const { user } = this.props;
    const { currentYear } = user || {};
    if (res && !res.error) {
      this.setState({
        successNotif: true,
        notifMsg: 'Your changes have been updated successfully',
        errorNotif: false,
        isEditForm: false,
      });
      this.props
        .getSecondRounders({ year: currentYear })
        .then((sres) => {
          if (sres && !sres.error) {
            this.setState({
              showLoader: false,
            });
          }
        })
        .catch((e) => console.log('Error', e));
    } else {
      this.setState({
        errorNotif: true,
        isEditForm: false,
        showLoader: false,
      });
    }
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  render() {
    const {
      history,
      batchStatistics,
      loading,
      reservedCandidates,
      confirmedCandidates,
      recommendedByAgency,
      potentialCandidates,
      user,
      pastCandidates,
    } = this.props;
    const { currentYear, roleId } = user || {};
    const { push } = history || {};
    const {
      selectedTab,
      pieChartData,
      currBar,
      isOpenCatModal,
      scholarshipLabel,
      showLoader,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      reportsMap,
      embedToken,
    } = this.state || {};
    const { statistics } = batchStatistics || {};
    const {
      schemeStatistics,
      countryStatistics,
      courseGenderStatistics,
      genderStatistics,
      ethinicStatistics,
      schoolStatistics,
      dwellingStatistics,
      faIncomeStatistics,
      incomeStatistics,
      yoyStatistics,
      ytdStatistics,
      countryStatisticsOthers,
      ethinicStatisticsOthers,
    } = statistics || {};
    const scholarshipScheme =
      this.handleGetScholarshipSchemeData(schemeStatistics);
    const targetOfferedByCountry = this.handleGetTargetOfferedByCountry(
      countryStatistics,
      false
    );
    const otherCountryData = this.handleGetTargetOfferedByCountry(
      countryStatisticsOthers,
      true
    );
    const coursesByGenderData = this.handleGetCoursesByGender(
      courseGenderStatistics
    );
    const scholarshipByGenderData =
      this.handleGetScholarshipByGender(genderStatistics);
    const ethnicityData = this.handleGetEthinicData(ethinicStatistics, false);
    const otherEthinicData = this.handleGetEthinicData(
      ethinicStatisticsOthers,
      true
    );
    const schoolStatisticsData =
      this.handleGetSchoolStatisticsData(schoolStatistics);
    const dwellingTypeData = this.handleGetDwellingTypeData(dwellingStatistics);
    const faIncomeStatisticsData =
      this.handleGetFaIncomeData(faIncomeStatistics);
    const houseHoldIncomeData =
      this.handleGetHouseholdIncomeData(incomeStatistics);
    const yoyStatisticsData = this.handleGetYoyStatisticsData(yoyStatistics);
    const ytdStatisticsData = this.handleGetYoyStatisticsData(ytdStatistics);
    const analyticsInfo = {
      scholarshipScheme,
      candidateCountryData: targetOfferedByCountry,
      candCountryData: otherCountryData,
      candidateCourseData: coursesByGenderData,
      genderData: scholarshipByGenderData,
      ethnicityData,
      ethnicityOtherData: otherEthinicData,
      offeredBySchool: schoolStatisticsData,
      dwellingType: dwellingTypeData,
      householfFA: faIncomeStatisticsData,
      houseHoldIncome: houseHoldIncomeData,
      yoyComparision: yoyStatisticsData,
      ytdComparision: ytdStatisticsData,
    };
    const finalConfirmedCandidates =
      this.handleGetRecommendedByAgencyCands(confirmedCandidates);
    const finalReservedCandidates =
      this.handleGetRecommendedByAgencyCands(reservedCandidates);
    const finalRecommendedCandidates =
      this.handleGetRecommendedByAgencyCands(recommendedByAgency);
    const finalPotentialCandidates =
      this.handleGetRecommendedByAgencyCands(potentialCandidates);
    const finalPastCandidates =
      this.handleGetRecommendedByAgencyCands(pastCandidates);

    const finalYear = this.state.finalYear ?? currentYear;
    let iFilter = [];
    iFilter.push({
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'Calendar',
        column: 'Year',
      },
      operator: 'In',
      values: [finalYear],
    });

    return (
      <div>
        <BackRow paths={this.paths} />
        <input
          style={{ height: 0, opacity: 0 }}
          type="text"
          ref={this.handleRedirect}
        />
        <GenericTabs
          selected={selectedTab}
          tabContent={_.range(currentYear, 2020, -1).map((year, idx) => {
            return {
              label: `Selection Year ${year}`,
              value: '',
              onClick: () => {
                this.handleTabClick(idx, year);
              },
            };
          })}
        />
        <div>
          <div className="h5 f-w-b bx--ty-gamma font-weight-light mb-3 mt-3">
            {selectedTab === 0
              ? 'Overall Exercise Statistics (Year-on-year comparison)'
              : 'Overall Exercise Statistics'}
          </div>
          <Tile>
            <CurrentBatchInfo
              batchStatistics={batchStatistics}
              selectedTab={selectedTab}
            />
          </Tile>
        </div>
        <div className="h5 f-w-b bx--ty-gamma font-weight-light mb-3 mt-3">
          {selectedTab === 0 ? 'Current' : null} Selection Year Statistics
        </div>
        {/* <DashboardAnalytics
          analyticsInfo={analyticsInfo}
          handleBarOnClick={this.handleBarOnClick}
          selectedTab={selectedTab}
        /> */}

        <DashboardAnalyticsPowerBI
          reportsMap={reportsMap}
          embedToken={embedToken}
          iFilter={iFilter}
        />

        <Accordion className="mt-2" align='start'>
          <AccordionItem
            className="content-padding"
            title={
              <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                Second Rounders
              </div>
            }>
            <SuccErrNotification
              successNotif={successNotif}
              errorNotif={errorNotif}
              notifMsg={notifMsg}
              errMsg={errMsg}
              handleCloseNotifBtn={this.handleCloseNotifBtn}
            />
            <h5 className="mb-2">Identified Candidates</h5>
            <SecondRounders
              data={finalConfirmedCandidates}
              push={push}
              selectedTab={selectedTab}
              roleId={roleId}
              handleToggleShortlistValue={this.handleToggleShortlistValue}
            />
            <h5 className="mt-2 mb-2">Reserved Candidates</h5>
            <SecondRounders
              data={finalReservedCandidates}
              push={push}
              selectedTab={selectedTab}
              roleId={roleId}
              handleToggleShortlistValue={this.handleToggleShortlistValue}
            />
            <h5 className="mt-2 mb-2">Recommended By Agency</h5>
            <SecondRounders
              data={finalRecommendedCandidates}
              push={push}
              selectedTab={selectedTab}
              roleId={roleId}
              handleToggleShortlistValue={this.handleToggleShortlistValue}
            />
            <h5 className="mt-2 mb-2">Potential Candidates</h5>
            <SecondRounders
              data={finalPotentialCandidates}
              push={push}
              selectedTab={selectedTab}
              roleId={roleId}
              handleToggleShortlistValue={this.handleToggleShortlistValue}
            />
            <h5 className="mt-2 mb-2">Last Year's Candidates</h5>
            <SecondRounders
              data={finalPastCandidates}
              push={push}
              selectedTab={selectedTab}
              roleId={roleId}
              handleToggleShortlistValue={this.handleToggleShortlistValue}
            />
          </AccordionItem>
        </Accordion>

        {isOpenCatModal && (
          <Modal
            open={isOpenCatModal}
            modalHeading={
              <div className="text-capitalize">
                {scholarshipLabel} - {currBar}
              </div>
            }
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            passiveModal
            iconDescription="Close the modal"
            onRequestClose={() => {
              this.setState({ isOpenCatModal: false, currBar: '' });
            }}
            onRequestSubmit={() => {}}>
            {pieChartData &&
            Array.isArray(pieChartData) &&
            pieChartData.length > 0 ? (
              <div style={{ height: 350 }}>
                <NivoPieChart
                  margin={{ top: 50, right: 10, bottom: 50, left: 10 }}
                  heading=""
                  data={pieChartData}
                  legendTranslateY={106}
                />
              </div>
            ) : (
              <div className="h6">
                There is no data for your current selection.
              </div>
            )}
          </Modal>
        )}

        <JDProcessOverlay show={showLoader} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  batchStatistics: state.Dashboard.batchStatistics,
  loading: state.Dashboard.loading,
  reservedCandidates: state.Dashboard.reservedCandidates,
  confirmedCandidates: state.Dashboard.confirmedCandidates,
  recommendedByAgency: state.Dashboard.recommendedByAgency,
  potentialCandidates: state.Dashboard.potentialCandidates,
  pastCandidates: state.Dashboard.pastCandidates,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getDasboardStatistics,
  getSecondRounders,
  upsertCandidateDetailsById,
  getPBITokens,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
