import React from 'react';
import { Route, Switch } from 'react-router-dom';
//Header Components
import PrivateHeader from '../headers/PrivateHeader';
import ScheduleMaintenanceHeader from '../headers/ScheduleMaintenanceHeader';
import SubItemsHeader from '../headers/SubItemsHeader';
//Main UI Components
import BatchInfo from '../containers/private/BatchInfo';
import CandidateList from '../containers/private/CandidateList';
import UsersList from '../containers/private/UsersList';
import ProfileInfo from '../containers/private/ProfileInfo';
import CandidateInfo from '../containers/private/CandidateInfo';
import HighLvlReports from '../containers/private/HighLvlReports';
import VIPanelMembers from '../containers/private/VIPanelMembers';
import VIAssignedCandidates from '../containers/private/VIAssignedCandidates';
import SearchCandidatesList from '../containers/private/SearchCandidatesList';
import AgendaPanelInterviews from '../containers/private/AgendaPanelInterviews';
import CalendarSelectionBoard from '../containers/private/CalendarSelectionBoard';
import CalendarSelectionBoardInfo from '../containers/private/CalendarSelectionBoardInfo';
import UserActivationMsg from '../components/private/UserActivationMsg';
import AuditLogs from '../containers/private/AuditLogs';
import ResetPanelPassword from '../containers/private/ResetPanelPassword';

//Route Components
import PrivateRoute from './PrivateRoute';
import Dashboard from '../containers/private/Dashboard';
import NavigationModal from '../containers/private/NavigationModal';
import AssessSettings from '../containers/private/Assessments/AssessSettings';
import AssessCandidateReports from '../containers/private/Assessments/AssessCandidateReports';
import AssessCandidateList from '../containers/private/Assessments/AssessCandidateList';
import AssessCandidateInfo from '../containers/private/Assessments/AssessCandidateInfo';
import FeedbackTemplates from '../containers/private/FeedbackTemplates';
import FeedbackTemplateDashboard from '../containers/private/FeedbackTemplateDashboard';
import CMSMeetings from '../containers/private/CMS/CMSMeetings';
import CMSReports from '../containers/private/CMS/CMSReports';
import CMSEmailTemplates from '../containers/private/CMS/CMSEmailTemplates';
import CMSSettings from '../containers/private/CMS/CMSSettings';
import CMSMeetingInfo from '../containers/private/CMS/CMSMeetingInfo';
import CMSAddEditMeeting from '../containers/private/CMS/CMSAddEditMeeting';
import NotFound from '../components/common/NotFound';
import {
  allowedRoutesFor3rdPartyRoles,
  allowedRoutesForNewCMSRoles,
} from './RestrictedRoutes';
import YearlyDataExport from '../containers/private/YearlyDataExport';
import CandidateReportEmails from '../containers/private/CandidateReportEmails';
import SGDSMastfooter from '../footers/SGDSMastfooter';

const MainApp = (props) => {
  const {
    showSubItems,
    label,
    description,
    handleShowSubMenu,
    subItems,
    isSideNavExpanded,
    history,
    onClickSideNavExpand,
    userId,
    roleId,
    user,
    showCountDown,
    difference,
    startTime,
    handleOpenNavigation,
    isOpenNavModal,
    match,
  } = props || {};
  const { isScheduledMaintainance } = user || {};

  const canCMSNewUsersAccessURL = allowedRoutesForNewCMSRoles.some(
    (allowedPath) => window?.location?.pathname?.includes(allowedPath)
  );

  const can3rdPartyUsersAccessURL = allowedRoutesFor3rdPartyRoles.some(
    (allowedPath) => window?.location?.pathname?.includes(allowedPath)
  );

  return (
    <div
      className={
        isScheduledMaintainance !== null &&
        isScheduledMaintainance !== undefined
          ? 'css-headre-top'
          : ''
      }>
      {isScheduledMaintainance !== null &&
        isScheduledMaintainance !== undefined && (
          <ScheduleMaintenanceHeader user={user} />
        )}
      <PrivateHeader
        handleShowSubMenu={handleShowSubMenu}
        showSubItems={showSubItems}
        propLabel={label}
        history={history}
        isSideNavExpanded={isSideNavExpanded}
        onClickSideNavExpand={onClickSideNavExpand}
        userId={userId}
        roleId={roleId}
        showCountDown={showCountDown}
        difference={difference}
        startTime={startTime}
        handleOpenNavigation={handleOpenNavigation}
      />
      {isOpenNavModal && (
        <NavigationModal
          history={history}
          isSideNavExpanded={true}
          roleId={roleId}
          handleOpenNavigation={handleOpenNavigation}
        />
      )}
      {showSubItems === true && (
        <SubItemsHeader
          subItems={subItems}
          label={label}
          description={description}
          handleShowSubMenu={handleShowSubMenu}
          roleId={roleId}
        />
      )}
      <div className="bx--grid mt-4 mb-5 css-main-body">
        <Switch>
          {(((roleId == 11 || roleId == 12 || roleId == 13) &&
            !canCMSNewUsersAccessURL) ||
            (roleId == 14 && !can3rdPartyUsersAccessURL)) && (
            <Route
              path={`${match?.url}*`}
              render={(props) => <NotFound {...props} restricted={true} />}
            />
          )}
          <PrivateRoute exact path="/app/csc" component={Dashboard} />
          <PrivateRoute
            exact
            path="/app/csc/batch/info"
            component={BatchInfo}
          />
          <PrivateRoute
            exact
            path="/app/csc/candidate/:appId/batch/:jobId/status/:status"
            component={CandidateInfo}
          />
          <PrivateRoute
            exact
            path="/app/csc/search/candidates/list"
            component={SearchCandidatesList}
          />
          <PrivateRoute
            exact
            path="/app/csc/calendar/interviews"
            component={CalendarSelectionBoard}
          />
          <PrivateRoute
            exact
            path="/app/csc/reactivate-user"
            component={UserActivationMsg}
          />
          <PrivateRoute
            exact
            path="/app/csc/calendar/selection/board/:date/candidate/:appId/type/:recordType"
            component={CalendarSelectionBoardInfo}
          />
          <PrivateRoute
            exact
            path="/app/csc/panel/interviews/list"
            component={AgendaPanelInterviews}
          />
          <PrivateRoute
            exact
            path="/app/csc/high/level/reports"
            component={HighLvlReports}
          />
          <PrivateRoute
            exact
            path="/app/csc/candidate/list/all"
            component={CandidateList}
          />
          <PrivateRoute
            exact
            path="/app/csc/assessments/candidates"
            component={AssessCandidateList}
          />
          <PrivateRoute
            exact
            path="/app/csc/assessments/candidate/:candId"
            component={AssessCandidateInfo}
          />
          <PrivateRoute
            exact
            path="/app/csc/assessments/reports"
            component={AssessCandidateReports}
          />
          <PrivateRoute
            exact
            path="/app/csc/cms/meetings"
            component={CMSMeetings}
          />
          <PrivateRoute
            exact
            path="/app/csc/cms/create/new/meeting"
            component={CMSAddEditMeeting}
          />
          <PrivateRoute
            exact
            path="/app/csc/cms/create/new/meeting/:meetingId"
            component={CMSAddEditMeeting}
          />
          <PrivateRoute
            exact
            path="/app/csc/cms/meeting/:meetingId"
            component={CMSMeetingInfo}
          />
          <PrivateRoute
            exact
            path="/app/csc/cms/reports"
            component={CMSReports}
          />
          <PrivateRoute
            exact
            path="/app/csc/cms/email-templates"
            component={CMSEmailTemplates}
          />
          <PrivateRoute
            exact
            path="/app/csc/cms/settings"
            component={CMSSettings}
          />
          <PrivateRoute
            exact
            path="/app/csc/admin/profile"
            component={ProfileInfo}
          />
          <PrivateRoute
            exact
            path="/app/csc/admin/vi/panel/settings"
            component={VIPanelMembers}
          />
          <PrivateRoute
            exact
            path="/app/csc/vi/assigned/candidates"
            component={VIAssignedCandidates}
          />
          <PrivateRoute
            exact
            path="/app/csc/candidate/reports/emails"
            component={CandidateReportEmails}
          />
          <PrivateRoute
            exact
            path="/app/csc/admin/users"
            component={UsersList}
          />
          <PrivateRoute
            exact
            path="/app/csc/admin/audit-logs"
            component={AuditLogs}
          />
          <PrivateRoute
            exact
            path="/app/csc/admin/reset-panel-password"
            component={ResetPanelPassword}
          />
          <PrivateRoute
            exact
            path="/app/csc/admin/feedback-templates"
            component={FeedbackTemplates}
          />
          <PrivateRoute
            exact
            path="/app/csc/admin/feedback/:scorecardId/groups"
            component={FeedbackTemplateDashboard}
          />
          <PrivateRoute
            exact
            path="/app/csc/assessments/settings"
            component={AssessSettings}
          />
          <PrivateRoute
            exact
            path="/app/csc/admin/yearly-data-export"
            component={YearlyDataExport}
          />
        </Switch>
      </div>
      <SGDSMastfooter />
    </div>
  );
};

export default MainApp;
