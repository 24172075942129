import React from 'react';
import './SGDSMastfooter.css';

export default function SGDSMastfooter() {
  const showMastFooter = window.location.hostname
    ?.toLowerCase()
    ?.includes('.gov.sg');
  return (
    showMastFooter && (
      <div>
        <footer class="sgds-footer">
          <div class="bottom-section">
            <div class="sgds-container is-fluid">
              <div class="row is-multiline">
                <div class="col is-12">
                  <ul>
                    <li>
                      <a
                        href="https://tech.gov.sg/report_vulnerability"
                        target="_blank"
                        rel="noreferrer">
                        Report Vulnerability
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.csc.gov.sg/privacy/"
                        target="_blank"
                        rel="noreferrer">
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.csc.gov.sg/terms-of-use/"
                        target="_blank"
                        rel="noreferrer">
                        Terms of Use
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  );
}
