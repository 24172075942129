import React from 'react';

//Functional Components
import NoContentBox from '../common/NoContentBox';
import NivoScatterGraph from '../common/NivoScatterGraph';

//Common Packages
import { capitalCase } from 'capital-case';
import PSMeanTScore from '../../assets/images/ps-t-scores.png';
import { PowerBIEmbed } from 'powerbi-client-react';
import PSMeanTScoresComingSoon from '../../assets/images/ps-t-scores-coming-soon.png';
import { getCurrEnvironment } from '../../containers/common/common';

const PSMeanScoresGraph = (props) => {
  const { psListData, selectionYear, reportsMap, embedToken, psTScoresImgSrc } =
    props || {};
  const { commonRecords, tscoreRecords: records } = psListData || {};
  let psychInterviewData = [];
  let assessmentCenterData = [];
  let peerAppraisalData = [];
  let averageData = [];
  const { list: tscoreRecords } = records || {};
  if (
    commonRecords &&
    Array.isArray(commonRecords) &&
    commonRecords.length > 0
  ) {
    commonRecords.forEach((res) => {
      const {
        peer_ls_peer,
        peer_io_peer,
        peer_cv_peer,
        peer_is_peer,
        peer_st_peer,
        psy_leadership_qualities,
        psy_intellectual_qualities,
        psy_character_and_values,
        psy_interpersonal_skills,
        psy_resilience,
        psac_leadership_qualities_rating,
        psac_intellectual_qualities_rating,
        psac_character_values_rating,
        psac_people_skills_rating,
        psac_personal_effectiveness_rating,
        psac_communication_skills_rating,
        fullName,
      } = res || {};
      let avgValue = 0;
      const peerTotal =
        (peer_ls_peer && Number(peer_ls_peer)) +
          (peer_io_peer && Number(peer_io_peer)) +
          (peer_cv_peer && Number(peer_cv_peer)) +
          (peer_is_peer && Number(peer_is_peer)) +
          (peer_st_peer && Number(peer_st_peer)) || 0;
      const peerMean = peerTotal > 0 ? peerTotal / 5 : null;
      const psyTotal =
        (psy_leadership_qualities && Number(psy_leadership_qualities)) +
          (psy_intellectual_qualities && Number(psy_intellectual_qualities)) +
          (psy_character_and_values && Number(psy_character_and_values)) +
          (psy_interpersonal_skills && Number(psy_interpersonal_skills)) +
          (psy_resilience && Number(psy_resilience)) || 0;
      const psyMean = psyTotal > 0 ? psyTotal / 5 : null;
      const psacTotal =
        (psac_leadership_qualities_rating &&
          Number(psac_leadership_qualities_rating)) +
          (psac_intellectual_qualities_rating &&
            Number(psac_intellectual_qualities_rating)) +
          (psac_character_values_rating &&
            Number(psac_character_values_rating)) +
          (psac_people_skills_rating && Number(psac_people_skills_rating)) +
          (psac_communication_skills_rating &&
            Number(psac_communication_skills_rating)) +
          (psac_personal_effectiveness_rating &&
            Number(psac_personal_effectiveness_rating)) || 0;
      const psacMean = psacTotal > 0 ? psacTotal / 6 : null;

      if (psacTotal > 0) {
        avgValue += 1;
      }
      if (psyTotal > 0) {
        avgValue += 1;
      }
      if (peerTotal > 0) {
        avgValue += 1;
      }
      const averageValue =
        (peerMean + psacMean + psyMean) / (avgValue > 0 ? avgValue : 1) || 0;
      assessmentCenterData.push({
        x: fullName ? capitalCase(fullName) : '',
        y: psacMean || -1,
        color: '#33ae81',
      });
      psychInterviewData.push({
        x: fullName ? capitalCase(fullName) : '',
        y: psyMean || -1,
        color: '#118DFF',
      });
      peerAppraisalData.push({
        x: fullName ? capitalCase(fullName) : '',
        y: peerMean || -1,
        color: '#f6b44a',
      });
      averageData.push({
        x: fullName ? capitalCase(fullName) : '',
        y: averageValue,
        color: '#DC5B57',
      });
    });
  }

  const finalTscorePIdata = [];
  const finalTscorePAdata = [];
  const finalTscorePSACdata = [];
  if (
    tscoreRecords &&
    Array.isArray(tscoreRecords) &&
    tscoreRecords.length > 0
  ) {
    tscoreRecords.forEach((res) => {
      const { full_name, tscore_pa, tscore_pi, tscore_psac } = res || {};
      finalTscorePIdata.push({
        x: full_name ? capitalCase(full_name) : '',
        y: tscore_pi || -1,
        color: '#33ae81',
      });
      finalTscorePSACdata.push({
        x: full_name ? capitalCase(full_name) : '',
        y: tscore_psac || -1,
        color: '#118DFF',
      });
      finalTscorePAdata.push({
        x: full_name ? capitalCase(full_name) : '',
        y: tscore_pa || -1,
        color: '#f6b44a',
      });
    });
  }

  const currEnvironment = getCurrEnvironment();
  let reportId;
  if (currEnvironment === 'production') {
    // production
    reportId = 'f7c2850a-7353-4bcd-ba98-0a3782d4e124';
  } else {
    // local & staging
    reportId = 'f95dac57-a3da-4b2a-b333-8254983e748d';
  }

  return (
    <div className="">
      {/* {commonRecords &&
      Array.isArray(commonRecords) &&
      commonRecords.length > 0 ? (
        <div class="scatter-plot-height mt-2">
          <NivoScatterGraph
            showLegend
            heading="Mean Scores for Psychological Interview, Assessment Centre and Peer Appraisal"
            data={[
              {
                id: 'Psych Interview (PI)',
                color: '#118DFF',
                data: psychInterviewData,
              },
              {
                id: 'Assessment Centre (AC)',
                color: '#33ae81',
                data: assessmentCenterData,
              },
              {
                id: 'Peer Appraisal (PA)',
                color: '#f6b44a',
                data: peerAppraisalData,
              },
              // {
              //   id: 'Average',
              //   color: '#DC5B57',
              //   data: averageData,
              // },
            ]}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -32,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 46,
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Mean Score',
              legendPosition: 'middle',
              legendOffset: -60,
            }}
            axisTop={null}
            axisRight={null}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 8 }}
            nodeSize={12}
            max={6}
            title="Mean Score"
            margin={{ top: 30, right: 60, bottom: 130, left: 80 }}
          />
        </div>
      ) : (
        <div>
          <h4 class="mt-2 mb-2">
            Mean Scores for Psychological Interview, Assessment Centre and Peer
            Appraisal
          </h4>
          <NoContentBox
            hideTile
            message="There are no details available for your current selection"
          />
        </div>
      )}
      <hr /> */}

      {/* <div class=" mt-2">
            <NivoScatterGraph
              showLegend
              heading="T Scores for Psychological Interview, Assessment Centre and Peer Appraisal"
              data={[
                {
                  id: 'Psych Interview (PI)',
                  color: '#118DFF',
                  data: finalTscorePIdata,
                },
                {
                  id: 'Assessment Centre (AC)',
                  color: '#33ae81',
                  data: finalTscorePSACdata,
                },
                {
                  id: 'Peer Appraisal (PA)',
                  color: '#f6b44a',
                  data: finalTscorePAdata,
                },
              ]}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -32,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 46,
              }}
              hideGraph
              title="T Score"
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Mean Score',
                legendPosition: 'middle',
                legendOffset: -60,
              }}
              axisTop={null}
              axisRight={null}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 0, max: 100 }}
              nodeSize={12}
              max={100}
              margin={{ top: 30, right: 60, bottom: 130, left: 80 }}
            />
            <div>
              <img
                width="87%"
                height="525"
                src={PSMeanTScore}
                alt="PS T Score"
              />
            </div>
          </div> */}
      <div className="">
        <h4 className="text-capitalize">
          T Scores for Psychological Interview, Assessment Centre and Peer
          Appraisal
        </h4>
        <img
          width="87%"
          height="625"
          src={psTScoresImgSrc || '#'}
          alt="PS T Score"
        />
        {/* <div className="t-scores-graph-container">
          <PowerBIEmbed
            embedConfig={{
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              id: reportId,
              embedUrl:
                (reportsMap &&
                  reportsMap[reportId] &&
                  reportsMap[reportId].embedUrl) ||
                '',
              accessToken: embedToken, // Keep as empty string, null or undefined
              tokenType: 1,
              settings: {
                panes: {
                  filters: {
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                },
              },
            }}
          />
        </div> */}
      </div>
      <hr />
    </div>
  );
};

export default PSMeanScoresGraph;
