import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';

//API
import { upsertUserInfoDetailsById } from '../../actions/AdminSection';
import { fetchUserLoginState } from '../../actions/UserLogin';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericTabs from '../../components/common/GenericTabs';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../components/private/SuccErrNotification';

import {
  Tile,
  Button,
  Toggle,
  TextInput,
  Modal,
} from 'carbon-components-react';

class ProfileInfo extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'profile', name: 'Profile', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      emailOtp: false,
      smsOtp: false,
      isOpenClearCacheWarningModal: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { emailOtp, smsOtp, phoneNumber } = user || {};
    this.setState({
      emailOtp: emailOtp === null ? false : emailOtp,
      smsOtp: smsOtp === null ? false : smsOtp,
      phoneNumber,
    });
  }

  handleTabsClick = (selectedTab) => {
    this.setState({
      selectedTab,
    });
  };

  handleToggleClick = (type) => {
    const { emailOtp, smsOtp } = this.state;
    if (type === 'byEmail') {
      if (smsOtp === false && emailOtp === true) {
        this.setState({
          errorNotif: true,
          errMsg: 'Atleast one option to receive OTP should be enabled.',
        });
      } else {
        this.setState(
          {
            emailOtp: !emailOtp,
          },
          () => {
            const data = {
              emailOtp: this.state.emailOtp,
            };
            this.handleUpsertSmsDetails(data);
          }
        );
      }
    } else {
      if (emailOtp === false && smsOtp === true) {
        this.setState({
          errorNotif: true,
          errMsg: 'Atleast one option to receive OTP should be enabled.',
        });
      } else {
        this.setState(
          {
            smsOtp: !smsOtp,
          },
          () => {
            const data = {
              smsOtp: this.state.smsOtp,
            };
            this.handleUpsertSmsDetails(data);
          }
        );
      }
    }
  };

  handleUpsertSmsDetails = (data) => {
    const { user } = this.props;
    const { userId } = user || {};
    this.props
      .upsertUserInfoDetailsById(data, userId)
      .then((res) => {
        if (res && !res.error) {
          this.props.fetchUserLoginState();
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been updated successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                  notifMsg: '',
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleUpsertPhoneNumber = () => {
    const { phoneNumber } = this.state;
    this.setState(
      {
        isValidPhNo: phoneNumber ? false : true,
      },
      () => {
        const { isValidPhNo } = this.state;
        if (!isValidPhNo) {
          const data = {
            phoneNumber,
            smsOtp: true,
          };
          this.handleUpsertSmsDetails(data);
          this.setState({
            smsOtp: true,
          });
        }
      }
    );
  };

  handleClearCache = () => {
    this.setState({ isOpenClearCacheWarningModal: false }, () => {
      window.sessionStorage.clear();
      window.sessionStorage.setItem('loggedout', true);
      localStorage.clear();
      window.location.href = `${window.location.origin}/auth/logout`;
    });
  };

  render() {
    const { user, history, loading } = this.props;
    const { firstname, lastname, email, phoneNumber: propPhNo } = user || {};
    const { push } = history || {};
    const {
      selectedTab,
      emailOtp,
      smsOtp,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      phoneNumber,
      isValidPhNo,
      isOpenClearCacheWarningModal,
    } = this.state;
    return (
      <div>
        <BackRow paths={this.paths} />
        <div className="d-flex justify-content-between">
          <h5 className="mb-2">My Profile</h5>
          <div className="">
            <Button
              size="small"
              onClick={() =>
                this.setState({ isOpenClearCacheWarningModal: true })
              }>
              Clear Cache
            </Button>
          </div>
          {isOpenClearCacheWarningModal && (
            <Modal
              open={isOpenClearCacheWarningModal}
              modalHeading="Clear Cache"
              modalLabel="Action"
              primaryButtonText="Submit"
              secondaryButtonText="Cancel"
              danger
              iconDescription="Close the modal"
              onRequestClose={() =>
                this.setState({ isOpenClearCacheWarningModal: false })
              }
              onRequestSubmit={() => this.handleClearCache()}>
              Are you sure you want to clear cache, any changes not saved will
              not be stored.
            </Modal>
          )}
        </div>
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
          errMsg={errMsg}
        />
        <Tile className="mt-2">
          <div className="bx--row">
            <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
              Name:
            </div>
            <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
              {firstname} {lastname}
            </div>
          </div>
          <div className="bx--row mt-2">
            <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
              Email:
            </div>
            <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
              {email}
            </div>
          </div>
        </Tile>
        <GenericTabs
          selected={selectedTab}
          tabContent={[
            {
              label: 'Security',
              value: '',
              onClick: () => {
                this.handleTabsClick(0);
              },
            },
            {
              label: 'OTP Settings',
              value: '',
              onClick: () => {
                this.handleTabsClick(1);
              },
            },
          ]}
        />
        {selectedTab === 0 && (
          <Tile className="mt-2">
            <h6>Security</h6>
            <hr />
            <div>To change your password, click on the button below:</div>
            <div className="mt-2">
              <Button
                onClick={() => {
                  push && push('/reset-password');
                }}>
                Change Password
              </Button>
            </div>
          </Tile>
        )}
        {selectedTab === 1 && (
          <Tile>
            <h6>OTP Settings</h6>
            <div className="mt-2 mb-2 f-w-b small">
              Please select one option from below, to receive an OTP
            </div>
            <hr />
            <div>
              <Toggle
                toggled={emailOtp}
                labelA="No"
                labelText="By Email"
                onToggle={() => this.handleToggleClick('byEmail')}
                labelB="Yes"
              />
            </div>
            {propPhNo && (
              <div className="mt-2">
                <Toggle
                  toggled={smsOtp}
                  onToggle={() => this.handleToggleClick('bySms')}
                  labelA="No"
                  labelText="By SMS"
                  labelB="Yes"
                />
              </div>
            )}
            <div className="bx--label mt-2">
              Please enter phone number to receive OTP by SMS
            </div>
            <div className="d-flex align-items-center mt-2">
              <div className="font-weight-bold">+65</div>
              <div className="ml-2 w-25">
                <TextInput
                  labelText="Please enter phone number to receive OTP by SMS"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.handleChange}
                  invalid={isValidPhNo}
                  hideLabel
                  invalidText="Please enter valid phone number"
                  type="number"
                  placeholder="Enter the Phone Number."
                />
              </div>
            </div>
            <div className="mt-2">
              <Button size="small" onClick={this.handleUpsertPhoneNumber}>
                Save
              </Button>
            </div>
          </Tile>
        )}
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
  loading: state.AdminSection.loading,
});

const mapDispatchToProps = {
  upsertUserInfoDetailsById,
  fetchUserLoginState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
