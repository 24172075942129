import React, { useEffect, useState } from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
// import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { LastLocationProvider } from 'react-router-last-location';
import Routes from './routes';

import configureStore, { history } from './store';

//toastify init
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { getCurrEnvironment } from './containers/common/common';
import './App.css';
const { store, persistor } = configureStore();

const App = (props) => {
  const [redirecting, setRedirecting] = useState(false);
  const currEnv = getCurrEnvironment();
  let oldDomain;
  let newDomain;
  if (currEnv === 'production') {
    oldDomain = 'ars.x0pa.ai';
    newDomain = 'https://ars.csc.gov.sg';
  } else if (currEnv === 'staging') {
    oldDomain = 'cscstag.x0pa.ai';
    newDomain = 'https://cscstag.csc.gov.sg';
  }

  useEffect(() => {
    if (window.location.hostname === oldDomain) {
      setRedirecting(true);
      setTimeout(() => {
        const currentPath = window.location.pathname + window.location.search;
        window.location.replace(`${newDomain}${currentPath}`);
      }, 10000);
    }
  }, []);

  if (redirecting) {
    return (
      <div className="redirect-message-container">
        <div className="redirect-box">
          <h1>We’re Moving!</h1>
          <p>
            We are migrating to a new URL and will be redirected shortly (
            <a href={newDomain} target="_blank" rel="noopener noreferrer">
              {newDomain}
            </a>
            )
          </p>
          <p>You may continue using this URL or bookmark the new URL.</p>
          <div className="redirect-spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <LastLocationProvider>
            <Routes {...props} />
          </LastLocationProvider>
        </ConnectedRouter>
      </PersistGate>
      <ToastContainer />
    </Provider>
  );
};

export default App;
