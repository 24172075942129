import React, { Component } from 'react';
import NavItems from './NavItemsList';
import { Link } from 'react-router-dom';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  HeaderMenuButton,
} from 'carbon-components-react';
import { connect } from 'react-redux';
import { fetchUserLoginState } from '../actions/UserLogin';
import { getCurrEnvironment } from '../containers/common/common';
import SideNavContent from './SideNavContent';
import { ArrowUp16, ArrowDown16 } from '@carbon/icons-react';
import PubSub from 'pubsub-js';
import moment from 'moment';
import Timer from './Timer';
import _ from 'lodash';
import './Header.css';
import { allowedRoutesFor3rdPartyRoles } from '../routes/RestrictedRoutes';
import SGDSMasthead from './SGDSMasthead';

// Timer is hidden based on these completed strings
const completedStrings = ['completed', 'STOP'];

const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

// get the correct attribute name
const getHiddenPropertyName = (prefix) => {
  return prefix ? prefix + 'Hidden' : 'hidden';
};

// get the correct event name
const getVisibilityEvent = (prefix) => {
  return (prefix ? prefix : '') + 'visibilitychange';
};

// get current browser vendor prefix
const getBrowserPrefix = () => {
  for (var i = 0; i < browserPrefixes.length; i++) {
    if (getHiddenPropertyName(browserPrefixes[i]) in document) {
      // return vendor prefix
      return browserPrefixes[i];
    }
  }
  // no vendor prefix needed
  return null;
};

const showTimerByRoleIds = (roleId) => {
  return roleId != 12 && roleId != 13 && roleId != 14;
};

class PrivateHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCountDown: false,
    };
    this.browserPrefix = getBrowserPrefix();
  }

  connect = () => {
    const currEnvironment = getCurrEnvironment();
    const webSocketURL = `wss://${
      currEnvironment === 'production' ? 'csclb' : 'cscsb'
    }.x0pa.ai/sc1/socket`;

    let ws = new WebSocket(webSocketURL);
    console.log('RECONNECTED------->>>>>>>>>>');
    ws.onopen = () => {
      // subscribe to some channels
      ws.send(
        JSON.stringify({
          //.... some message the I must send when I connect ....
          message: 'Client trying to connect',
        })
      );
    };
    ws.onmessage = (e) => {
      console.log('Message:', e.data);
      const currHost = window.location.host;
      let finalEnv = '';
      if (currHost.includes('localhost')) {
        finalEnv = 'local';
      } else if (currHost.includes('cscstag')) {
        finalEnv = 'staging';
      } else {
        finalEnv = 'live';
      }

      let parsedData;
      try {
        parsedData = e && e.data && JSON.parse(e.data);
      } catch (e) {
        console.error('Error::', e);
      }

      const { eventTriggered, logOutUserId } = parsedData || {};
      if (eventTriggered === 'STOP') {
        PubSub.publish('HIDE_COUNT_DOWN');
        PubSub.publish('RERENDER_INTERVEIWS_LIST');
        localStorage.removeItem(`timer-data-${finalEnv}`);
      } else if (eventTriggered === 'START' || eventTriggered === 'RESTART') {
        PubSub.publish('SHOW_COUNT_DOWN');
        PubSub.publish('RERENDER_INTERVEIWS_LIST');
        localStorage.setItem(`timer-data-${finalEnv}`, e.data);
      }
      const { user } = this.props;
      const { userId } = user || {};
      if (userId == logOutUserId) {
        window.location.href = '/auth/logout';
        window.sessionStorage.setItem('loggedout', true);
      }
    };
    ws.onclose = (e) => {
      console.log(
        'Socket is closed. Reconnect will be attempted in 1 second.',
        e.reason
      );
      setTimeout(() => {
        this.connect();
      }, 1000);
    };
    ws.onerror = (err) => {
      console.error(
        'Socket encountered error: ',
        err.message,
        'Closing socket'
      );
      ws.close();
    };
  };

  subscriptions = [
    PubSub.subscribe('SHOW_COUNT_DOWN', () => {
      this.handleCountDown();
    }),
    PubSub.subscribe('HIDE_COUNT_DOWN', () => {
      this.handleHideCountDown();
    }),
  ];

  handleGetProfileInfo = () => {
    const { user } = this.props;
    const { userId, companyId } = user || {};
    this.props.fetchUserLoginState(userId, companyId).then((res) => {
      if (res && !res.error) {
        const { meta } = res || {};
        const { timerObj } = meta || {};
        // this.connect();
        this.handleCountDown(timerObj);
      }
    });
  };

  handleVisibilityChange = () => {
    if (document[getHiddenPropertyName(this.browserPrefix)]) {
      // the page is hidden
    } else {
      // the page is visible
      this.handleGetProfileInfo();
    }
  };

  componentDidMount() {
    if (showTimerByRoleIds(this.props.roleId)) {
      document.addEventListener(
        getVisibilityEvent(this.browserPrefix),
        this.handleVisibilityChange
      );
      this.connect();
      this.handleCountDown();

      // triggering profInfoCall every 15sec to get latest Timer data
      this.profileInfoInterval = setInterval(
        () => this.handleGetProfileInfo(),
        15000
      );
    }
  }

  componentWillUnmount() {
    if (showTimerByRoleIds(this.props.roleId)) {
      document.removeEventListener(
        getVisibilityEvent(this.browserPrefix),
        this.handleVisibilityChange
      );
      clearInterval(this.profileInfoInterval);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (showTimerByRoleIds(this.props.roleId)) {
      const { timerObj: prevTimerObj } = prevProps?.user || {};
      const { timerObj: currTimerObj } = this.props?.user || {};
      if (!_.isEqual(prevTimerObj, currTimerObj)) {
        this.handleCountDown(currTimerObj);
      }
    }
  };

  getLatestProfInfoObj = (pscObj, psObj) => {
    let {
      profInfoFullName,
      pscStartTime,
      pscEndTime,
      calenderInterviewAction,
    } = pscObj || {};
    let { psStartTime, psEndTime, psCalenderInterviewAction } = psObj || {};
    pscStartTime = pscStartTime && Number(pscStartTime);
    psStartTime = psStartTime && Number(psStartTime);

    if (pscStartTime > psStartTime) {
      return {
        profInfoFullName,
        profInfoStartTime: pscStartTime,
        profInfoEndTime: pscEndTime,
        profInfoEvent: calenderInterviewAction,
      };
    } else if (pscStartTime < psStartTime) {
      return {
        profInfoFullName,
        profInfoStartTime: psStartTime,
        profInfoEndTime: psEndTime,
        profInfoEvent: psCalenderInterviewAction,
      };
    } else if (pscStartTime && psStartTime && pscStartTime === psStartTime) {
      return {
        profInfoFullName,
        profInfoStartTime: pscStartTime,
        profInfoEndTime: pscEndTime,
        profInfoEvent:
          _.indexOf(completedStrings, calenderInterviewAction) >= 0 ||
          _.indexOf(completedStrings, psCalenderInterviewAction) >= 0
            ? 'completed'
            : 'ongoing',
      };
    }
  };

  getLatestTimerInfoObj = (latestProfInfoObj, lsObj) => {
    let {
      profInfoFullName,
      profInfoStartTime,
      profInfoEndTime,
      profInfoEvent,
    } = latestProfInfoObj || {};
    let { fullName, startTime, endTime, eventTriggered } = lsObj || {};
    profInfoStartTime = profInfoStartTime && Number(profInfoStartTime);
    startTime = startTime && Number(startTime);

    if (profInfoStartTime > startTime) {
      return {
        finalFullName: profInfoFullName,
        finalStartTime: profInfoStartTime,
        finalEndTime: profInfoEndTime,
        finalEvent: profInfoEvent,
      };
    } else if (profInfoStartTime < startTime) {
      return {
        finalFullName: fullName,
        finalStartTime: startTime,
        finalEndTime: endTime,
        finalEvent: eventTriggered,
      };
    } else if (
      profInfoStartTime &&
      startTime &&
      profInfoStartTime === startTime
    ) {
      return {
        finalFullName: profInfoFullName,
        finalStartTime: profInfoStartTime,
        finalEndTime: profInfoEndTime,
        finalEvent:
          _.indexOf(completedStrings, profInfoEvent) >= 0 ||
          _.indexOf(completedStrings, eventTriggered) >= 0
            ? 'completed'
            : 'ongoing',
      };
    }
  };

  handleCountDown = (currTimerObj) => {
    const { user } = this.props || {};
    const currHost = window.location.host;
    let finalEnv = '';
    if (currHost.includes('localhost')) {
      finalEnv = 'local';
    } else if (currHost.includes('cscstag')) {
      finalEnv = 'staging';
    } else {
      finalEnv = 'live';
    }
    const timerData = localStorage.getItem(`timer-data-${finalEnv}`);
    const parsedData = timerData && JSON.parse(timerData);

    const { startTime, eventTriggered, endTime, fullName } = parsedData || {};
    const { timerObj } = user || {};
    const {
      fullName: profInfoFullName,
      pscStartTime,
      pscEndTime,
      psStartTime,
      psEndTime,
      calenderInterviewAction,
      psCalenderInterviewAction,
    } = currTimerObj || timerObj || {};

    const pscObj = {
      profInfoFullName,
      pscStartTime,
      pscEndTime,
      calenderInterviewAction,
    };
    const psObj = { psStartTime, psEndTime, psCalenderInterviewAction };
    const lsObj = { fullName, startTime, endTime, eventTriggered };

    let finalTimerInfoObj;

    if (!_.isEmpty(timerObj) && !_.isEmpty(parsedData)) {
      const latestProfInfoObj = this.getLatestProfInfoObj(pscObj, psObj);
      finalTimerInfoObj = this.getLatestTimerInfoObj(latestProfInfoObj, lsObj);
    } else if (!_.isEmpty(timerObj) && _.isEmpty(parsedData)) {
      const latestProfInfoObj = this.getLatestProfInfoObj(pscObj, psObj);
      const {
        profInfoFullName,
        profInfoStartTime,
        profInfoEndTime,
        profInfoEvent,
      } = latestProfInfoObj || {};
      finalTimerInfoObj = {
        finalFullName: profInfoFullName,
        finalStartTime: profInfoStartTime,
        finalEndTime: profInfoEndTime,
        finalEvent: profInfoEvent,
      };
    } else if (_.isEmpty(timerObj) && !_.isEmpty(parsedData)) {
      finalTimerInfoObj = {
        finalFullName: fullName,
        finalStartTime: startTime,
        finalEndTime: endTime,
        finalEvent: eventTriggered,
      };
    }

    const { finalFullName, finalStartTime, finalEndTime, finalEvent } =
      finalTimerInfoObj || {};

    let showCountDown = false;
    if (
      finalEvent === 'ongoing' ||
      finalEvent === 'START' ||
      finalEvent === 'RESTART'
    ) {
      showCountDown = true;
    }

    this.setState({
      showCountDown,
      finalName: finalFullName,
      finalStartTime: finalStartTime && Number(finalStartTime),
      finalEndTime: finalEndTime && Number(finalEndTime),
    });
  };

  handleShowCountDown = () => {
    this.handleCountDown();
  };

  handleHideCountDown = () => {
    this.setState({
      showCountDown: false,
    });
  };

  handleLogoOnClick = (push, rPath) => {
    if (window.innerWidth < 1056) {
      this.props.onClickSideNavExpand();
    } else {
      push && push(rPath);
    }
  };

  render() {
    const {
      handleShowSubMenu,
      showSubItems,
      propLabel,
      history,
      isSideNavExpanded,
      onClickSideNavExpand,
      roleId,
      user,
      handleOpenNavigation,
    } = this.props || {};
    const {
      showCountDown,
      finalName,
      finalStartTime = 0,
      finalEndTime = 0,
    } = this.state;
    const { isVideoPresentationVisible } = user || {};

    const difference = moment(finalEndTime).diff(
      moment(finalStartTime),
      'milliseconds'
    );

    const { push } = history || {};
    let rPath = '/';
    if (roleId == 8 || roleId == 9 || roleId == 10) {
      rPath = '/app/csc/calendar/interviews';
    } else if (roleId === 7) {
      rPath = '/app/csc/candidate/list/all';
    } else if (roleId == 11 || roleId == 12 || roleId == 13) {
      rPath = '/app/csc/cms/meetings';
    } else if (roleId == 14) {
      rPath = allowedRoutesFor3rdPartyRoles?.[0];
    } else {
      rPath = '/app/csc';
    }
    const finalNavItems = NavItems();

    const navItemsForCurrUser =
      (finalNavItems &&
        Array.isArray(finalNavItems) &&
        finalNavItems.filter((itm) => {
          const { accessRoles } = itm || {};
          if (accessRoles.includes(roleId && Number(roleId))) {
            return true;
          }
          return false;
        })) ||
      [];
    const showOpenMenuHamBurger = navItemsForCurrUser?.length > 7;

    return (
      <div className="sticky-top">
        <SGDSMasthead />
        <Header aria-label="CSC">
          {/* <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={false}
            tabIndex="0"
          /> */}
          <HeaderGlobalAction
            className={`bx--header__menu-toggle__hidden ${
              showOpenMenuHamBurger ? 'bx--header__menu-toggle' : ''
            }`}
            aria-label="Open Menu"
            onClick={() => handleOpenNavigation(true)}>
            Open Menu
          </HeaderGlobalAction>
          <HeaderName
            className="main-header-b"
            onClick={() => this.handleLogoOnClick(push, rPath)}
            href="javascript:void(0)"
            prefix="">
            <h2
              className="text-light font-weight-bold"
              style={{ cursor: 'pointer' }}>
              ARS
            </h2>
          </HeaderName>
          <SkipToContent />
          {isSideNavExpanded === true && (
            <div className="d-md-block d-sm-block d-xs-block d-xl-none hide-side-nav">
              <SideNavContent
                history={history}
                isSideNavExpanded={isSideNavExpanded}
                roleId={roleId}
                onClickSideNavExpand={onClickSideNavExpand}
              />
            </div>
          )}

          {finalNavItems &&
            Array.isArray(finalNavItems) &&
            finalNavItems.length > 0 &&
            finalNavItems.map((items, idx) => {
              const {
                label,
                path,
                subItems,
                desc,
                selectedPaths,
                accessRoles,
              } = items || {};
              return (
                <HeaderNavigation
                  aria-label="CSC"
                  className={`ml-0 pl-0 ${
                    showOpenMenuHamBurger ? 'hideNavBar' : ''
                  }`}>
                  {subItems && Array.isArray(subItems) && subItems.length <= 0
                    ? ((accessRoles &&
                      Array.isArray(accessRoles) &&
                      accessRoles.length > 0
                        ? accessRoles.includes(roleId && Number(roleId))
                        : true) ||
                        (label === 'Video Presentation' &&
                          isVideoPresentationVisible)) && (
                        <HeaderMenuItem
                          key={`${label}-${idx + 1}`}
                          element={Link}
                          onClick={() =>
                            handleShowSubMenu(subItems, label, '', false)
                          }
                          to={path}>
                          <div
                            className={
                              selectedPaths &&
                              Array.isArray(selectedPaths) &&
                              selectedPaths.length > 0 &&
                              selectedPaths.includes(window.location.pathname)
                                ? 'selected-link'
                                : ''
                            }>
                            {label}
                          </div>
                        </HeaderMenuItem>
                      )
                    : subItems &&
                      Array.isArray(subItems) &&
                      subItems.length > 0 &&
                      ((accessRoles &&
                      Array.isArray(accessRoles) &&
                      accessRoles.length > 0
                        ? accessRoles.includes(roleId && Number(roleId))
                        : true) ||
                        (label === 'Video Presentation' &&
                          isVideoPresentationVisible)) && (
                        <HeaderMenuItem
                          onClick={() =>
                            handleShowSubMenu(subItems, label, desc, true)
                          }
                          key={`${label}-${idx + 1}`}>
                          <b
                            className={
                              selectedPaths &&
                              Array.isArray(selectedPaths) &&
                              selectedPaths.length > 0 &&
                              selectedPaths.includes(window.location.pathname)
                                ? 'selected-link'
                                : ''
                            }>
                            {label}
                          </b>
                          &nbsp;&nbsp;
                          {propLabel === label && showSubItems === true ? (
                            <ArrowUp16 className="arrow-fill-color pt-1" />
                          ) : (
                            <ArrowDown16 className="arrow-fill-color pt-1" />
                          )}
                        </HeaderMenuItem>
                      )}
                </HeaderNavigation>
              );
            })}
          <HeaderGlobalBar>
            {showTimerByRoleIds(roleId) && showCountDown && (
              <div>
                <Timer
                  finalName={finalName}
                  latestEndTime={finalStartTime + difference}
                />
              </div>
            )}
            <HeaderGlobalAction
              className="logout-height"
              aria-label="Logout"
              onClick={() => {
                window.sessionStorage.clear();
                window.sessionStorage.setItem('loggedout', true);
                localStorage.removeItem('application-ids');
                window.location.href = `${window.location.origin}/auth/logout`;
              }}
              href="/auth/logout">
              Logout
            </HeaderGlobalAction>
          </HeaderGlobalBar>
        </Header>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  fetchUserLoginState,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateHeader);
