import React from 'react';

const ColoredCirclePercentage = ({ text, color, score }) => {
  const finalColor = color?.toString()?.toUpperCase();
  return (
    <div className="w-25 mb-3 d-flex flex-column align-items-center">
      <div
        className="text-center bold mt-1 mr-1"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: finalColor,
          borderRadius: '50%',
          width: '90px',
          height: '90px',
          border: `2px solid ${finalColor}`,
          fontWeight: 'bold',
        }}>
        <span>
          {finalColor === '#7BF39C'
            ? 'Good'
            : finalColor === '#EFB132'
            ? 'Fair'
            : finalColor === '#fa9196'
            ? 'Poor'
            : 'NA*'}
        </span>
      </div>
      <span style={{ fontWeight: 'bold' }} className="mt-2">
        {text}
      </span>
    </div>
  );
};

export default ColoredCirclePercentage;
